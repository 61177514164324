import React from "react";
import { Button, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Agent = () => {
    const {t} = useTranslation()
  return (
    <Row
      style={{
        background: "white",
        textAlign: "center",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <h1>{t("dashboard.sessionsAgent.title")}</h1>
      <div className="d-flex gap-2 align-items-center">
        <Button variant="danger" id="verify-btn">
          {t("dashboard.sessionsAgent.exportExcel")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.sessionsAgent.importExcel")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.sessionsAgent.update")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.sessionsAgent.delete")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.sessionsAgent.showHide")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.sessionsAgent.closeSessions")}
        </Button>
      </div>
      <Table
        className="table-drag"
        style={{ background: "white", textAlign: "center" }}
      >
        <thead style={{ background: "white" }}>
          <tr>
            <th style={{ width: "100px" }}>{t("dashboard.sessionsAgent.table.name")}</th>
            <th style={{ width: "100px" }}>
              {t("dashboard.sessionsAgent.table.startTime")}
            </th>
            <th style={{ width: "100px" }}>
              {t("dashboard.sessionsAgent.table.endTime")}
            </th>
            <th style={{ width: "100px" }}>{t("dashboard.sessionsAgent.table.ip")}</th>
            <th style={{ width: "100px" }}>
              {t("dashboard.sessionsAgent.table.closeSessions")}
            </th>
          </tr>
        </thead>
      </Table>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <h3>{t("dashboard.sessionsAgent.noData")}</h3>
      </div>
    </Row>
  );
};

export default Agent;
