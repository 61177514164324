import React, { useEffect, useState } from "react";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
//semantic Ui
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
//components
import Notification from "../../../Components/Notification/Notification";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { useTranslation } from "react-i18next";

const FreezedShipments = () => {
  const docLang = document.querySelector("html").lang

  const [freezedShipments, setFreezedShipments] = useState([]);
  const [AgentInfomodalShow, setAgentInfoModalShow] = React.useState(false);
  const [OrderDetailsModalShow, setOrderDetailsModalShow] =
    React.useState(false);
  //=================================Freezed Shipement=================================
  const [FreezedShipmentsModalShow, setFreezedShipmentsModalShow] =
    useState(false);

  const handleFreezedShipmentsModalClose = () =>
    setFreezedShipmentsModalShow(false);
  const handleFreezedShipmentsModalShow = () =>
    setFreezedShipmentsModalShow(true);

  //==================================Delete Freezed Shipemt=================================
  const [DeletesFreezeShipmentShow, setDeletesFreezeShipmentShow] =
    useState(false);

  const handleDeletesFreezeShipmentClose = () =>
    setDeletesFreezeShipmentShow(false);
  const handleDeletesFreezeShipmentShow = () =>
    setDeletesFreezeShipmentShow(true);
  //========================================Multiple Delete Modal States=================================================
  const [
    MultipleDeleteFreezedShipmentsModalShow,
    setMultipleDeleteFreezedShipmentsModalShow,
  ] = useState(false);

  const handleMultipleDeleteFreezedShipmentsModalClose = () =>
    setMultipleDeleteFreezedShipmentsModalShow(false);
  const handleMultipleDeleteFreezedShipmentsModalShow = () =>
    setMultipleDeleteFreezedShipmentsModalShow(true);

  //===========================================================================================================

  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });
  const [freezeShipment, setFreezeShipment] = useState({
    freezed: false,
  });
  const [shipmentId, setShipmentId] = useState("");
  const handleFreezeShipment = (e, shipmentId) => {
    setShipmentId(shipmentId);
    setFreezeShipment({
      freezed: e.target.value,
    });
  };
  //============================get Suggestion========================

  useEffect(() => {
    const getFreezedShipments = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/?freezed=true",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setFreezedShipments([]);
        console.log(body);
      } else {
        setFreezedShipments(body);
      }
    };
    getFreezedShipments();
  }, []);
  //========================================freeze Shipment ==============================
  const PostFreezeShipment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      freezed: freezeShipment.freezed,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/freezed/${shipmentId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("dashboard.shipment.Shipment Status Not Changed");
      console.log(body);
    } else {
      alert("dashboard.shipment.Shipment Status Has Been Changed");
      window.location.reload();
    }
  };
  //=========================================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("dashboard.shipment.a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //===========================Agent Info Modal=========================
  function AgentInfoModal(props) {
    const AgentInfo = props.shipment.agent;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Agent Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Agent Info</h4>

          <Table>
            <TableHeader>
              <TableHeaderCell>ID</TableHeaderCell>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Mobile Phone</TableHeaderCell>
              <TableHeaderCell>Phone</TableHeaderCell>
            </TableHeader>
            <TableBody>
              <TableRow key={AgentInfo._id}>
                <TableCell>1</TableCell>
                <TableCell>{AgentInfo.name}</TableCell>
                <TableCell>{AgentInfo.mobilePhone}</TableCell>
                <TableCell>{AgentInfo.phone}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  //==============================Order Details Modal ======================
  function OrderDetailsModal(props) {
    const OrderDetail = props.shipment.order;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <TableHeader>
              <TableHeaderCell>ID</TableHeaderCell>
              <TableHeaderCell>Created At</TableHeaderCell>
              <TableHeaderCell>Items</TableHeaderCell>
              <TableHeaderCell>Order Price</TableHeaderCell>
              <TableHeaderCell>Order Volume</TableHeaderCell>
              <TableHeaderCell>Order Weight</TableHeaderCell>
            </TableHeader>
            <TableBody>
              <TableRow key={OrderDetail._id}>
                <TableCell>1</TableCell>
                <TableCell>{OrderDetail.createdAt}</TableCell>
                <TableCell>
                  {OrderDetail.items.map((item, index) => {
                    return (
                      <ul key={index}>
                        <li>{`Item Name: ${item.product.subCat[docLang]}`}</li>
                        <li>{`Item Price: ${item.price}`}</li>
                        <li>{`Item Count: ${item.count}`}</li>
                        <li>{`Item Volume: ${item.volume}`}</li>
                        <li>{`Item Weight: ${item.weight}`}</li>
                      </ul>
                    );
                  })}
                </TableCell>
                <TableCell>{OrderDetail.orderPrice}</TableCell>
                <TableCell>{OrderDetail.orderVolume}</TableCell>
                <TableCell>{OrderDetail.orderWeight}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  //============================Delete Shippment===============================

  const DeleteShippment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/admin/${shipmentId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("dashboard.shipment.Shippment Not Deleted !!!");
      console.log(response);
    } else {
      alert("dashboard.shipment.Shippment Deleted !!");
      window.location.reload();
    }
  };
  //============================Delete Mutliple Product=====================

  const deleteShipments = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(contactID)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, contactID],
        });
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultipleShipments = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/shipment/admin/collection",
      requestOptions
    );
    if (!response.ok) {
      alert("dashboard.shipment.There Is An Error ");
      console.log(response);
    } else {
      alert("dashboard.shipment.Shipments Have Been Deleted !!");
      window.location.reload();
    }
  };
  const { t } = useTranslation();
  const columns = [
    { name: t("dashboard.shipment.ID"), selector: (row, index) => <span>{index}</span> },
    {
      name: t("dashboard.shipment.Status"),
      selector: (row) => (
        <span>
          {" "}
          {row.status} /{" "}
          {row.freezed === true ? t("dashboard.shipment.Freezed") : t("dashboard..Not Freezed")}{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.Date"),
      selector: (row) => <span>{row.order.createdAt.slice(0, 10)}</span>,
    },
    {
      name: t("dashboard.shipment.Container Details"),
      selector: (row) => (
        <span>
          {" "}
          <ul>
            {" "}
            <li>{`${t("dashboard.shipment.Type")} : ${
              row.containerInfo.container.containerType
            }`}</li>{" "}
            <li>{`${t("dashboard.shipment.Fridge")} : ${
              row.containerInfo.container.isFridge
                ? t("dashboard.shipment.Fridge")
                : t("dashboard.shipment.Not Fridge")
            }`}</li>{" "}
            <li>{`${t("dashboard.shipment.Max Vol")} : ${
              row.containerInfo.container.maxVolume
            }`}</li>{" "}
            <li>{`${t("dashboard.shipment.Max Weight")} : ${
              row.containerInfo.container.maxWeight
            }`}</li>{" "}
            <li>{`${t("dashboard.shipment.Max Weight State")} : ${
              row.containerInfo.maxWeightInState
            }`}</li>{" "}
          </ul>{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.Agent Info"),
      selector: (row) => (
        <span>
          {" "}
          <Button variant="warning" onClick={() => setAgentInfoModalShow(true)}>
            {" "}
            {t("dashboard.shipment.See Agent Info")}{" "}
          </Button>{" "}
          <AgentInfoModal
            shipment={row}
            show={AgentInfomodalShow}
            onHide={() => setAgentInfoModalShow(false)}
          />{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.Export Country"),
      selector: (row) => (
        <span>
          {" "}
          {`${t("dashboard.shipment.Country Code")} : ${row.exportCountry.countryCode}`}
          <br /> {`${t("dashboard.shipment.Country Name")} : ${
            row.exportCountry.countryName[docLang]
          }`}{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.Import Country"),
      selector: (row) => (
        <span>
          {" "}
          {`${t("dashboard.shipment.Country Code")} : ${row.importCountry.countryCode}`}
          <br /> {`${t("dashboard.shipment.Country Name")} : ${
            row.importCountry.countryName[docLang]
          }`}{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.Import State"),
      selector: (row) => (
        <span>{`${t("dashboard.shipment.State Name")} : ${row.state.stateName[docLang]}`}</span>
      ),
    },
    {
      name: t("dashboard.shipment.Note Per Products"),
      selector: (row) => (
        <div
          style={{ fontSize: "12px", display: "flex", flexDirection: "column" }}
        >
          {" "}
          {row?.order?.items?.map((d) => (
            <>
              {d.note ? (
                <div>
                  <span>{d.product.subCat[docLang]}</span> : <span>{d.note}</span>
                </div>
              ) : (
                ""
              )}
            </>
          ))}{" "}
        </div>
      ),
      width: "200px",
    },
    {
      name: t("dashboard.shipment.Order Details"),
      selector: (row) => (
        <span>
          {" "}
          <Button
            variant="warning"
            onClick={() => setOrderDetailsModalShow(true)}
          >
            {" "}
            {t("dashboard.shipment.Order Details")}{" "}
          </Button>{" "}
          <OrderDetailsModal
            shipment={row}
            show={OrderDetailsModalShow}
            onHide={() => setOrderDetailsModalShow(false)}
          />{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.Shipment Suggestion Files"),
      selector: (row) => (
        <span>
          {" "}
          {row.adminFiles.map((file, index) => (
            <Button
              key={index}
              variant="outline-warning"
              onClick={() => getFile(file)}
              style={{ width: "150px", overflow: "hidden" }}
            >
              {" "}
              {file.slice(16)}{" "}
            </Button>
          ))}{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.Freeze Shipment"),
      selector: (row) => (
        <Form.Select
          name="freeze-shipment"
          value={freezeShipment}
          onChange={(e) => handleFreezeShipment(e, row._id)}
        >
          {" "}
          <option>{t("dashboard.shipment.Freeze Shipment")}</option>{" "}
          <option value="true">{t("dashboard.shipment.Freeze")}</option>{" "}
          <option value="false">{t("dashboard.shipment.DeFreeze")}</option>{" "}
        </Form.Select>
      ),
    },
    {
      name: t("dashboard.shipment.Freezing Details"),
      selector: (row) => (
        <span>
          {" "}
          <Button variant="dark" onClick={handleFreezedShipmentsModalShow}>
            {" "}
            {t("dashboard.shipment.Freeze Shipment")}{" "}
          </Button>{" "}
          <Modal
            show={FreezedShipmentsModalShow}
            onHide={handleFreezedShipmentsModalClose}
            backdrop="static"
            keyboard={false}
          >
            {" "}
            <Modal.Header closeButton>
              {" "}
              <Modal.Title>{t("dashboard.shipment.Freezing Details")}</Modal.Title>{" "}
            </Modal.Header>{" "}
            <Modal.Body>
              {" "}
              <Table>
                {" "}
                <TableHeader>
                  {" "}
                  <TableHeaderCell>{t("dashboard.shipment.ID")}</TableHeaderCell>{" "}
                  <TableHeaderCell>{t("dashboard.shipment.Email")}</TableHeaderCell>{" "}
                  <TableHeaderCell>{t("dashboard.shipment.Name")}</TableHeaderCell>{" "}
                </TableHeader>{" "}
                <TableBody>
                  {" "}
                  {freezedShipments.map((shipment, index) => (
                    <TableRow key={index}>
                      {" "}
                      <TableCell>{index + 1}</TableCell>{" "}
                      <TableCell>{shipment.freezedByAdmin.email}</TableCell>{" "}
                      <TableCell>{shipment.freezedByAdmin.name}</TableCell>{" "}
                    </TableRow>
                  ))}{" "}
                </TableBody>{" "}
              </Table>{" "}
            </Modal.Body>{" "}
            <Modal.Footer>
              {" "}
              <Button
                variant="secondary"
                onClick={handleFreezedShipmentsModalClose}
              >
                {" "}
                {t("dashboard.shipment.Close")}{" "}
              </Button>{" "}
            </Modal.Footer>{" "}
          </Modal>{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.Delete Shipment"),
      selector: (row) => (
        <span>
          {" "}
          <Button
            variant="danger"
            onClick={() => {
              handleDeletesFreezeShipmentShow();
              setShipmentId(row._id);
            }}
          >
            {" "}
            {t("dashboard.shipment.Delete")}{" "}
          </Button>{" "}
          <Modal
            show={DeletesFreezeShipmentShow}
            onHide={handleDeletesFreezeShipmentClose}
            backdrop="static"
            keyboard={false}
          >
            {" "}
            <Modal.Header closeButton>
              {" "}
              <Modal.Title>
                {t("dashboard.shipment.Are You Sure You Want To Delete This Freezed Shipment?")}
              </Modal.Title>{" "}
            </Modal.Header>{" "}
            <Modal.Footer>
              {" "}
              <Button variant="dark" onClick={handleDeletesFreezeShipmentClose}>
                {" "}
                {t("dashboard.shipment.Close")}{" "}
              </Button>{" "}
              <Button variant="danger" onClick={() => DeleteShippment()}>
                {" "}
                {t("dashboard.shipment.Delete Shipment")}{" "}
              </Button>{" "}
            </Modal.Footer>{" "}
          </Modal>{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.Multiple Delete"),
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedShipments"
          id="deletedShipments"
          onChange={(e) => deleteShipments(e, row._id)}
        />
      ),
    },
  ];
  return (
    <section className="freeze-shipment">
    <Notification />
    <Row>
      <div className="shippments">
        <div className="crud-title">
          <h1>{t('dashboard.shipment.freezeShipments.title')}</h1>
        </div>
        <div className="details-table">
          {deletedProducts.ids.length > 0 ? (
            <div>
              <Button
                id="multipleDelete"
                variant="danger"
                onClick={handleMultipleDeleteFreezedShipmentsModalShow}
              >
                {t('dashboard.shipment.freezeShipments.multipleDeleteButton')}
              </Button>
              <Modal
                show={MultipleDeleteFreezedShipmentsModalShow}
                onHide={handleMultipleDeleteFreezedShipmentsModalClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {t('dashboard.shipment.freezeShipments.deleteConfirmation')}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button
                    variant="dark"
                    onClick={handleMultipleDeleteFreezedShipmentsModalClose}
                  >
                    {t('dashboard.shipment.freezeShipments.closeButton')}
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => deleteMultipleShipments()}
                  >
                    {t('dashboard.shipment.freezeShipments.multipleDeleteButton')}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          ) : null}
          {freezeShipment.freezed ? (
            <Button
              id="freezedShipment"
              variant="dark"
              onClick={() => PostFreezeShipment()}
            >
              {t('dashboard.shipment.freezeShipments.changeStatusButton')}
            </Button>
          ) : null}
        </div>
      </div>
    </Row>
    <hr />
    <Row>
      <DataTable
        fixedHeaderScrollHeight="100px"
        pagination
        responsive
        subHeaderWrap
        columns={columns}
        data={freezedShipments}
        customStyles={customStyles}
        highlightOnHover
      />
    </Row>
  </section>
  
  );
};

export default FreezedShipments;
