import React, { useEffect } from "react";
import "./main-layout.scss";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import TopNav from "../components/topnav/TopNav";
import Cookies from "js-cookie";
import { languages } from "../../languages";
import { useTranslation } from "react-i18next";

const MainLayout = () => {
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = currentLanguage.dir;
  }, [currentLanguage, t]);
   
  return (
    <>
      <Sidebar />
      <div className="main" style={{
        paddingLeft : currentLanguage.dir === "ltr" ? "250px": "0",
        paddingRight : currentLanguage.dir === "rtl" ? "250px": "0",

      }}>
        <div className="main__content">
          <TopNav />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default MainLayout;
