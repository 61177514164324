import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WebsiteVisitors = () => {
  const [visitors, setVisitours] = useState();
  useEffect(() => {
    const getVisitour = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );
      var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
      };

      const response = await fetch(
        "https://www.api.setalkel.co/visitors",
        requestOptions
      );

      const body = await response.json();
      if (!response.ok) {
        console.log(body);
      } else {
        // console.log(body);
        setVisitours(body);
      }
      // console.log(body);
    };
    getVisitour();
  }, []);
  const {t} = useTranslation()
  return (
    <div className="visitour">
      {t("dashboard.CountWebsiteVisitor")} : {visitors?.countWebsiteVisitor}
    </div>
  );
};

export default WebsiteVisitors;
