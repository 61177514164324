import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Form } from "react-bootstrap";
import toast from 'react-hot-toast';
import ApiInstances from '../../Api/axiso';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const TableRow = ({ item, moveRow }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'row',
        item: { id: item._id },
    });

    const [, dropRef] = useDrop({
        accept: 'row',
        hover: (draggedItem) => {
            if (draggedItem.id !== item._id) {
                moveRow(draggedItem.id, item._id);
            }
        },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <tr ref={(node) => dragRef(dropRef(node))} style={{ opacity }}>
            <td style={{ width: "70px" }}>
                <img
                    src={`https://www.api.setalkel.co${item.img}`}
                    style={{ cursor: 'pointer', height: "70px", width: "70px" }}
                    alt={item.en}
                />
            </td>
            <td>{item.subCat.en}</td>
            <td>{item.peiceQty.usualWeight}</td>
            <td>{item.countryOrigin ? item.countryOrigin : "No Country Origin"}</td>
            <td>{item.materialProperty ? item.materialProperty : "No Material Property"}</td>

        </tr>
    );
};

const CardItem = ({ item, moveRow }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'row',
        item: { id: item._id },
    });

    const [, dropRef] = useDrop({
        accept: 'row',
        hover: (draggedItem) => {
            if (draggedItem.id !== item._id) {
                moveRow(draggedItem.id, item._id);
            }
        },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <div ref={(node) => dragRef(dropRef(node))} className="card-item" style={{ opacity }}>
            <img src={`https://www.api.setalkel.co${item.img}`} alt={item.cat.en} style={{ width: "100px", height: "100px" }} />
            <div>{item.subCat.en}</div>
            <div>{item.peiceQty.usualWeight ? `${item.peiceQty.usualWeight}` : "No Price Available"}</div>
        </div>
    );
};

const OurNewDrag = () => {
    const [OurNew, setOurNew] = useState([]);
    const [viewType, setViewType] = useState("grid");

    const fetchCategories = async () => {
        try {
            const response = await fetch("https://www.api.setalkel.co/product/?showHidden=false");
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }
            const body = await response.json();
            setOurNew(
                body.data.filter((it) => it.hidden === false && it.is_new === true)
            );

        } catch (error) {
            console.error('Error fetching categories:', error);
            setOurNew([]);
        }
    };
    // console.log(OurNew)
    const moveRow = (draggedId, hoverId) => {
        const draggedIndex = OurNew.findIndex((item) => item._id === draggedId);
        const hoverIndex = OurNew.findIndex((item) => item._id === hoverId);

        if (draggedIndex !== -1 && hoverIndex !== -1) {
            const newCategories = [...OurNew];
            const [draggedItem] = newCategories.splice(draggedIndex, 1);
            newCategories.splice(hoverIndex, 0, draggedItem);
            // console.log(newCategories)
            setOurNew(newCategories);

        }
    };

    useEffect(() => {
        fetchCategories()
    }, []);

    const { mutate } = useMutation({
        mutationFn: async (res) => {
            const { data } = await ApiInstances.post(`/product/cat/update-order`, res);
            return data;
        },
        onSuccess: () => {
            toast.success("Sorting Successfully");
            fetchCategories();
        },
    });

    const handleSort = () => {

        const categoryIds = OurNew.map(obj => obj._id);
        // mutate({ categoryIds });
    };
const {t} = useTranslation()
    return (
        <DndProvider backend={HTML5Backend}>
        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
            <Form.Group style={{ marginTop: "23px" }} className="view-type">
                <Form.Label>{t('dashboard.drag.ourNew.viewType')}</Form.Label>
                <Form.Select
                    id="view-type"
                    name="viewType"
                    value={viewType}
                    onChange={(e) => setViewType(e.target.value)}
                >
                    <option value="table">{t('dashboard.drag.ourNew.sequential')}</option>
                    <option value="grid">{t('dashboard.drag.ourNew.grid')}</option>
                </Form.Select>
            </Form.Group>
            <Button
                variant="warning"
                style={{ height: "fit-content", margin: "0", marginTop: "50px" }}
                onClick={handleSort}
            >
                {t('dashboard.drag.ourNew.sorting')}
            </Button>
        </div>
        {OurNew.length === 0 ? (
            <div style={{ display: "flex", width: "100%", justifyContent: "center", color: "black", fontSize: "16px" }}>
                <p>{t('dashboard.drag.ourNew.selectCategory')}</p>
            </div>
        ) : viewType === "table" ? (
            <Table className='table-drag'>
                <thead>
                    <tr>
                        <th style={{ width: "70px" }}>{t('dashboard.drag.ourNew.image')}</th>
                        <th>{t('dashboard.drag.ourNew.product')}</th>
                        <th>{t('dashboard.drag.ourNew.usualWeight')}</th>
                        <th>{t('dashboard.drag.ourNew.countryOrigin')}</th>
                        <th>{t('dashboard.drag.ourNew.materialProperty')}</th>
                    </tr>
                </thead>
                <tbody>
                    {OurNew.map((item) => (
                        <TableRow key={item._id} item={item} moveRow={moveRow} />
                    ))}
                </tbody>
            </Table>
        ) : (
            <div className="card-grid">
                {OurNew.map((item) => (
                    <CardItem key={item._id} item={item} moveRow={moveRow} />
                ))}
            </div>
        )}
    </DndProvider>
    );
};

export default OurNewDrag;
