import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
//bootstrap
import { Form, Row, Button, Modal } from "react-bootstrap";

//css
import "./CRPartners.css";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import UnVerifiedAgentsApi from "../../Api/UnVerifiedAgentsApi";
import toast from "react-hot-toast";
import { FaDownload } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const CRPartners = () => {
  const docLang = document.querySelector("html").lang
  const [partners, setPartners] = useState([]);
  const [partnerId, setPartnerId] = useState("");
  const [generateExcelFile, setGenerateExecelFile] = useState()
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [ImgGallery, setImgGallery] = useState({
    ImgGallery: [],
  });
  const [values, setValues] = useState({
    _id: "",
    title: {
      en: "",
      ar: "",
      fr: "",
      de: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },
    desc: {
      en: "",
      ar: "",
      fr: "",
      de: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },
  });
  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });

  const [DeleteParnterModalShow, setDeleteParnterModalShow] = useState(false);
  const handleDeletePartnerModalClose = () => setDeleteParnterModalShow(false);
  const handleDeletePartnerModalShow = () => setDeleteParnterModalShow(true);
  //delete Gallery
  const [DeleteParnterGalleryModalShow, setDeleteParnterGalleryModalShow] =
    useState(false);
  const handleDeletePartnerGalleryModalClose = () =>
    setDeleteParnterGalleryModalShow(false);
  const handleDeletePartnerGalleryModalShow = () =>
    setDeleteParnterGalleryModalShow(true);
  //==============
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);


  //=======================Fetch Partner=========================

  useEffect(() => {
    const fetchPartners = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/partners",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
      } else {
        setPartners(body);
      }
    };
    fetchPartners();
  }, []);

  //=====================================Post Kitchen Gallery====================


  //================================Delete Partner=======================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/partners/${partnerId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          toast.success("item deleted");
          handleDeletePartnerModalClose()

        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //====================Delete Gallary===========================================
  const onDeleteGallery = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/partners/${partnerId}/gallery`,
      requestOptions
    );
    if (!response.ok) {
      toast.error("Partner Gallery NOT Deleted !!!");
      console.log(response);
    } else {
      toast.error("Partner Gallery Has Been Deleted");
    }
  };

  //============================Delete Mutliple Product=====================

  const deleteProducts = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(contactID)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, contactID],
        });
        console.log(deletedProducts);
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultiplePartners = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/partners/collection",
      requestOptions
    );

    if (!response.ok) {
      alert("dashboard.parthners.There Is An Error ");
      console.log(response);
    } else {
      alert("dashboard.parthners.Partners Have Been Deleted !!");
      window.location.reload();
    }
  };
  useEffect(() => {
    if (deletedProducts.ids.length > 0) {
      document.getElementById("multipleDelete").style.visibility = "visible";
    } else {
      document.getElementById("multipleDelete").style.visibility = "hidden";
    }
  }, [deletedProducts.ids]);
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("dashboard.parthners.a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split('dashboard.parthners..').pop();

        // Define MIME types
        const mimeTypes = {
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          png: 'image/png',
          gif: 'image/gif',
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const navigate = useNavigate()
  const { t } = useTranslation()
  const columns = [
    {
      name: t("dashboard.Bites.Image"),
      selector: (row) => (
        <div className="position-relative">
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{
              height: "100px",
              width: "100px",
            }}
          />
          <FaDownload onClick={() => getFile(row.img)} style={{
            color: "red",
            fontSize: "15px",
            position: "absolute",
            top: "0",
            right: "0",
            cursor: "pointer"

          }} />

        </div>
      ),
    },
    {
      name: t("dashboard.drag.act.title"),
      selector: (row) => <span>{row.title[docLang]}</span>,
    },

    {
      name: t("dashboard.drag.act.descriptions"),
      selector: (row) => <span>{row.desc[docLang]}</span>,
    },

    {
      name: t("dashboard.Bites.Update"),
      selector: (row) => (
        <Link to={`/dashboard/crpartners/${row._id}`}>
          <Button variant="dark"> {t("dashboard.Bites.Update")}</Button>
        </Link>
      ),
    },
    {
      name: t("dashboard.Bites.Delete"),
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeletePartnerModalShow();
              setPartnerId(row._id);
            }}
          >
            {t("dashboard.Bites.Delete")}
          </Button>

        </div>
      ),
    },
    {
      name: "Delete Gallery",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeletePartnerGalleryModalShow();
              setPartnerId(row._id);
            }}
          >
            Delete Gallery
          </Button>

          <Modal
            show={DeleteParnterGalleryModalShow}
            onHide={handleDeletePartnerGalleryModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Sure You Want To Delete This Partner Gallery
              </Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleDeletePartnerGalleryModalClose}
              >
                Close
              </Button>
              <Button variant="danger" onClick={() => onDeleteGallery(row)}>
                Delete Gallery
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },

  ];
  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQueryForPartners()
  console.log(Excel)
  const getFileExcel = async () => {

    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("dashboard.parthners.a");
      link.href = url;
      link.setAttribute("download", `${generateExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
    }
  }

  return (
    <section className="cms-create-partners">
      <Row>
        <div className="crud-partners">
          <div className="crud-title">
            <h1>{t('dashboard.parthners.partners')}</h1>
          </div>
          <Button
            id="multipleDelete"
            variant="outline-danger"
            onClick={handleMultipleShow}
            className="m-0 d-none"
          >
            {t("dashboard.parthners.multipleDelete")}
          </Button>

          <Modal
            size="lg"
            show={MultipleDeleteModalShow}
            onHide={() => setMultipleDeleteModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                {t('dashboard.parthners.deleteAllPartnersConfirmation')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleMultipleClose}>
                {t('dashboard.parthners.close')}
              </Button>
              <Button variant="danger" onClick={deleteMultiplePartners}>
                {t('dashboard.parthners.deletePartners')}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* 
    //======================List Partners=========================
                   */}
          <div className="d-flex align-items-center">
            {!generateExcelFile ? <Button variant="warning" id="verify-btn" onClick={() => setGenerateExecelFile(Excel)}>
              {t('dashboard.parthners.exportExcel')}
            </Button> : <Button variant="success" id="verify-btn" onClick={getFileExcel}>
              {t('dashboard.parthners.downloadExcelFile')}
            </Button>}
            <Button variant="danger" id="verify-btn" >
              {t('dashboard.parthners.importExcel')}
            </Button>
            <Button variant="warning" id="verify-btn" onClick={() => navigate("/dashboard/crpartners/add-partners")}>
              {t('dashboard.parthners.addPartners')}
            </Button>
          </div>
          <div className="list-partners">
            <DataTable
              fixedHeader
              pagination
              responsive
              columns={columns}
              data={partners}
              customStyles={customStyles}
            />
          </div>
        </div>
      </Row>

      <Modal
        show={DeleteParnterModalShow}
        onHide={handleDeletePartnerModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('dashboard.parthners.deletePartnerConfirmation')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleDeletePartnerModalClose}
          >
            {t('dashboard.parthners.close')}
          </Button>
          <Button variant="danger" onClick={() => onDelete()}>
            {t('dashboard.parthners.deletePartner')}
          </Button>
        </Modal.Footer>
      </Modal>
    </section>

  );
};

export default CRPartners;
