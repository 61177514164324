import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";

import { useMutation } from "@tanstack/react-query";
import ApiInstances from "../../Api/axiso";
import { useTranslation } from "react-i18next";

const TableRow = ({ item, moveRow }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "row",
    item: { id: item._id },
  });

  const [, dropRef] = useDrop({
    accept: "row",
    hover: (draggedItem) => {
      if (draggedItem.id !== item._id) {
        moveRow(draggedItem.id, item._id);
      }
    },
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <tr ref={(node) => dragRef(dropRef(node))} style={{ opacity }}>
      <td style={{ width: "70px" }}>
        <img
          src={`https://www.api.setalkel.co${item.img}`}
          style={{ cursor: "pointer", height: "70px", width: "70px" }}
          alt={item.en}
        />
      </td>
      <td>{item.title.en}</td>
    </tr>
  );
};

const CardItem = ({ item, moveRow }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "row",
    item: { id: item._id },
  });

  const [, dropRef] = useDrop({
    accept: "row",
    hover: (draggedItem) => {
      if (draggedItem.id !== item._id) {
        moveRow(draggedItem.id, item._id);
      }
    },
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <div
      ref={(node) => dragRef(dropRef(node))}
      className="card-item"
      style={{ opacity }}
    >
      <img
        src={`https://www.api.setalkel.co${item.img}`}
        alt={``}
        style={{ width: "100px", height: "100px" }}
      />
      <div>{item.title.en}</div>
    </div>
  );
};

const PathnerDarg = () => {
  const [bites, SetBites] = useState([]);
  const [viewType, setViewType] = useState("grid");

  const fetchBites = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    const response = await fetch(
      "https://www.api.setalkel.co/partners",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      toast.error("There is an Error");
      SetBites([]);
      // console.log(body);
    } else {
      SetBites(body);
      // console.log(agents[0].password)
      // console.log(decryptedData);
    }
  };
  useEffect(() => {
    fetchBites();
  }, []);

  // console.log(OurNew)
  const moveRow = (draggedId, hoverId) => {
    const draggedIndex = bites.findIndex((item) => item._id === draggedId);
    const hoverIndex = bites.findIndex((item) => item._id === hoverId);

    if (draggedIndex !== -1 && hoverIndex !== -1) {
      const newCategories = [...bites];
      const [draggedItem] = newCategories.splice(draggedIndex, 1);
      newCategories.splice(hoverIndex, 0, draggedItem);
      // console.log(newCategories)
      SetBites(newCategories);
    }
  };

  // console.log(VerifiedAgents)
  const { mutate } = useMutation({
    mutationFn: async (res) => {
      const { data } = await ApiInstances.post(
        `/product/cat/update-order`,
        res
      );
      return data;
    },
    onSuccess: () => {
      toast.success("Sorting Successfully");
      fetchBites();
    },
  });

  const handleSort = () => {
    const categoryIds = bites.map((obj) => obj._id);
    // mutate({ categoryIds });
  };
const {t} = useTranslation()
  return (
    <DndProvider backend={HTML5Backend}>
    <div
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Form.Group style={{ marginTop: "23px" }} className="view-type">
        <Form.Label>{t('dashboard.drag.parthner.viewType')}</Form.Label>
        <Form.Select
          id="view-type"
          name="viewType"
          value={viewType}
          onChange={(e) => setViewType(e.target.value)}
        >
          <option value="table">{t('dashboard.drag.parthner.sequential')}</option>
          <option value="grid">{t('dashboard.drag.parthner.grid')}</option>
        </Form.Select>
      </Form.Group>
      <Button
        variant="warning"
        style={{ height: "fit-content", margin: "0", marginTop: "50px" }}
        onClick={handleSort}
      >
        {t('dashboard.drag.parthner.sorting')}
      </Button>
    </div>
    {bites.length === 0 ? (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          color: "black",
          fontSize: "16px",
        }}
      >
        <p>{t('dashboard.drag.parthner.selectCategory')}</p>
      </div>
    ) : viewType === "table" ? (
      <Table className="table-drag">
        <thead>
          <tr>
            <th style={{ width: "70px" }}>{t('dashboard.drag.parthner.image')}</th>
            <th>{t('dashboard.drag.parthner.title')}</th>
          </tr>
        </thead>
        <tbody>
          {bites.map((item) => (
            <TableRow key={item._id} item={item} moveRow={moveRow} />
          ))}
        </tbody>
      </Table>
    ) : (
      <div className="card-grid">
        {bites.map((item) => (
          <CardItem key={item._id} item={item} moveRow={moveRow} />
        ))}
      </div>
    )}
  </DndProvider>
  
  );
};

export default PathnerDarg;
