import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
//boostrap
import { Row, Form, Button, Modal } from "react-bootstrap";
//css
import "./CRBites.css";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import UnVerifiedAgentsApi from "../../Api/UnVerifiedAgentsApi";
import toast from "react-hot-toast";
import { FaDownload } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const CRBites = () => {
  const docLang = document.querySelector("html").lang
  const [bites, setBites] = useState([]);
  const [biteId, setBiteId] = useState("");
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });

  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });
  //=========================================Delete Bite Modal==================================
  const [DeleteBiteModalShow, setDeleteBiteModalShow] = useState(false);
  const [generateExcelFile, setGenerateExecelFile] = useState();
  const handleDeleteBiteClose = () => setDeleteBiteModalShow(false);
  const handleDeleteBiteShow = () => setDeleteBiteModalShow(true);
  //=========================================Delete Bites Modal==================================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  //=======================Video Handeling===============
 const {t} = useTranslation()
  useEffect(() => {
    const fetchBites = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/kitchen",
        requestOptions
      );
      const body = await response.json();

      if (!response.ok) {
        setBites([]);
        console.log(body);
      } else {
        setBites(body);
      }
    };
    fetchBites();
  }, []);

  //==============Delete Kitchen=====================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/kitchen/${biteId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          toast.success("item deleted");
          handleDeleteBiteClose();
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //============================Delete Mutliple Product=====================
  const deleteProducts = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(contactID)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, contactID],
        });
        console.log(deletedProducts);
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultipleKitchens = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/kitchen/collection",
      requestOptions
    );
    if (!response.ok) {
      toast.error("There Is An Error ");
      console.log(response);
    } else {
      toast.success("Bites/Kitchens Have Been Deleted !!");
      handleMultipleClose();
    }
  };
  useEffect(() => {
    if (deletedProducts.ids.length > 0) {
      document.getElementById("multipleDelete").style.visibility = "visible";
    } else {
      document.getElementById("multipleDelete").style.visibility = "hidden";
    }
  }, [deletedProducts.ids]);
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("dashboard.Bites.a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split("dashboard.Bites..").pop();

        // Define MIME types
        const mimeTypes = {
          jpg: "image/jpeg",
          jpeg: "image/jpeg",
          png: "image/png",
          gif: "image/gif",
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };

  const columns = [
    {
      name: t("dashboard.Bites.Image"),
      selector: (row) => (
        <div className="position-relative">
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{ height: "100px", width: "100px" }}
          />
          <FaDownload
            onClick={() => getFile(row.img)}
            style={{
              color: "red",
              fontSize: "15px",
              position: "absolute",
              top: "0",
              right: "0",
              cursor: "pointer",
            }}
          />
        </div>
      ),
    },
    { name: t("dashboard.drag.act.title"), selector: (row) => <span>{row.title[docLang]}</span> },
    { name: t("dashboard.Bites.Prep"), selector: (row) => <span>{row.prep[0][docLang]}</span> },
    {
      name: t("dashboard.Bites.Ingredient"),
      selector: (row) => <span>{row.ingredient[0][docLang]}</span>,
    },
    {
      name: t("dashboard.Bites.Update"),
      selector: (row) => (
        <Link to={`/dashboard/crbites/${row._id}`}>
          <Button variant="dark">{t("dashboard.Bites.Update")}</Button>
        </Link>
      ),
    },
    {
      name: t("dashboard.Bites.Delete"),
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteBiteShow();
              setBiteId(row._id);
            }}
          >
            {t("dashboard.Bites.Delete")}
          </Button>
        </div>
      ),
    },
    {
      name: t("dashboard.Bites.M-Delete"),
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteProducts(e, row._id)}
        />
      ),
    },
  ];
  const { data: Excel } = UnVerifiedAgentsApi.GenerateExelFileQueryForKitchen();
  const getFileExcel = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("dashboard.Bites.a");
      link.href = url;
      link.setAttribute("download", `${generateExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const navigate = useNavigate();
  return (
    <section className="cms-create-bites">
      <Row style={{ width: "100%" }}>
        <div className="crud-kitchen">
          <div className="crud-title">
            <h1>{t("dashboard.Bites.Bites")}</h1>
          </div>
          <div className="d-flex align-items-center">
            <Button
              type="submit"
              onClick={() => navigate("add-bites")}
              variant="warning"
            >
              {t("dashboard.Bites.Add Bite")}
            </Button>
            {!generateExcelFile ? (
              <Button
                variant="warning"
                id="verify-btn"
                onClick={() => setGenerateExecelFile(Excel)}
              >
                {t("dashboard.Bites.Export Excel for Bite")}
              </Button>
            ) : (
              <Button variant="success" id="verify-btn" onClick={getFileExcel}>
                {t("dashboard.Bites.Download Excel File")}
              </Button>
            )}
            <Button variant="danger" id="verify-btn">
              {t("dashboard.Bites.Import Excel")}
            </Button>
            <Button
              id="multipleDelete"
              variant="outline-danger"
              onClick={handleMultipleShow}
            >
              {t("dashboard.Bites.Multiple Delete")}
            </Button>
          </div>
        </div>
        <Row>
          <div className="list-product">
            <DataTable
              fixedHeader
              pagination
              responsive
              subHeaderWrap
              columns={columns}
              data={bites}
              customStyles={customStyles}
            />
          </div>
        </Row>
        <Modal show={DeleteBiteModalShow} onHide={handleDeleteBiteClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t("dashboard.Bites.Are You Sure You Want To Delete This Bite")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteBiteClose}>
              {t("dashboard.Bites.Close")}
            </Button>
            <Button variant="danger" onClick={() => onDelete()}>
              {t("dashboard.Bites.Delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
    </section>
  );
};

export default CRBites;
