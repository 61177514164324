import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { images } from "../../constants";
import sidebarNav from "../../configs/sidebarNav";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { adminClearCart } from "../../../redux/Admin/adminCartSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const [isHidden, setIsHidden] = useState(false);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const closeSidebar = () => {
    document.querySelector(".main__content").style.transform =
      "scale(1) translateX(0)";
    setTimeout(() => {
      document.body.classList.remove("sidebar-open");
    }, 500);
  };

  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("admin-token");
    localStorage.removeItem("user-data");
    localStorage.removeItem("admin-fcm");
    dispatch(adminClearCart());
    navigate("/dashboard/adminlogin");
    window.location.reload();
    window.history.forward();
    function noBack() {
      window.history.forward();
    }
    noBack();
  };
  const { t } = useTranslation();
  const renderDropdown = (sublinks) => {
    return sublinks.map((link, index) => (
      <React.Fragment key={index}>
        {link.text && (
          <Dropdown.Item>
            <NavLink to={link.link} onClick={closeSidebar}>
              <div className="d-flex justify-content-center align-items-center  w-fit">
                <Button
                  variant={isHidden ? "outline-danger" : "outline-warning"}
                  onClick={toggleVisibility}
                >
                  {isHidden ? (
                    <FaEyeSlash
                      style={{
                        color: "#dc3545",
                      }}
                    />
                  ) : (
                    <FaEye
                      style={{
                        color: "#ffc107",
                      }}
                    />
                  )}{" "}
                </Button>
                <p style={{ fontSize: "13px", color: "white" }}>{t(link.text)}</p>
              </div>
            </NavLink>
          </Dropdown.Item>
        )}
        {link.sublinks2 ? (
          <Dropdown drop="right">
            <Dropdown.Toggle
              as="a"
              style={{
                cursor: "pointer",
              }}
              className="nested-dropdown-toggle text-white fw-normal px-2"
            >
              {link.path2 ? (
                <NavLink to={link.path2} onClick={closeSidebar}>
                  <div className="d-flex justify-content-center align-items-center  w-fit">
                    <Button
                      variant={isHidden ? "outline-danger" : "outline-warning"}
                      onClick={toggleVisibility}
                    >
                      {isHidden ? (
                        <FaEyeSlash
                          style={{
                            color: "#dc3545",
                          }}
                        />
                      ) : (
                        <FaEye
                          style={{
                            color: "#ffc107",
                          }}
                        />
                      )}{" "}
                    </Button>
                    <p style={{ fontSize: "12px", color: "white" }}>
                      {" "}
                      {t(link.textLink)}
                    </p>
                  </div>
                </NavLink>
              ) : (
                t(link.textLink)
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {link.sublinks2.map((sublink, subIndex) => (
                <Dropdown.Item key={subIndex}>
                  <NavLink to={sublink.link} onClick={closeSidebar}>
                    <div className="d-flex justify-content-center align-items-center  w-fit">
                      <Button
                        variant={
                          isHidden ? "outline-danger" : "outline-warning"
                        }
                        onClick={toggleVisibility}
                      >
                        {isHidden ? (
                          <FaEyeSlash
                            style={{
                              color: "#dc3545",
                            }}
                          />
                        ) : (
                          <FaEye
                            style={{
                              color: "#ffc107",
                            }}
                          />
                        )}{" "}
                      </Button>
                      {t(sublink.text)}
                    </div>
                  </NavLink>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        <hr />
      </React.Fragment>
    ));
  };

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={images.logo} alt="" />
        <div className="sidebar-close" onClick={closeSidebar}>
          <i className="bx bx-x"></i>
        </div>
      </div>
      <div className="sidebar__menu">
        {sidebarNav.map((nav, index) =>
          nav.sublinks ? (
            <div className="d-flex" key={index}>
              <DropdownButton
                className="sidebar__menu__item__txt"
                title={t(nav.text)}
                variant="warning"
              >
                {renderDropdown(nav.sublinks)}
              </DropdownButton>
            </div>
          ) : (
            <NavLink
              to={nav.link}
              key={`nav-${index}`}
              className={`sidebar__menu__item ${
                activeIndex === index && "active"
              }`}
              onClick={closeSidebar}
            >
              {nav.text != "Home" ? (
                <div className="d-flex justify-content-center align-items-center  w-fit">
                  <Button
                    variant={isHidden ? "outline-danger" : "outline-warning"}
                    onClick={toggleVisibility}
                    style={{
                      border: "none",
                    }}
                  >
                    {isHidden ? (
                      <FaEyeSlash
                        style={{
                          color: "#dc3545",
                        }}
                      />
                    ) : (
                      <FaEye
                        style={{
                          color: "#ffc107",
                        }}
                      />
                    )}{" "}
                  </Button>
                  <div className="sidebar__menu__item__txt">{t(nav.text)}</div>
                </div>
              ) : (
                <div className="sidebar__menu__item__txt">{t(nav.text)}</div>
              )}
            </NavLink>
          )
        )}
        <div className="sidebar__menu__item" onClick={logout}>
          <div className="sidebar__menu__item__icon">
            <i className="bx bx-log-out"></i>
          </div>
          <div className="sidebar__menu__item__txt">
            {t("sideBar.LogOut")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
