import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";

//css
import "./CRContainers.css";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import UnVerifiedAgentsApi from "../../Api/UnVerifiedAgentsApi";
import { useTranslation } from "react-i18next";
const CRContainers = () => {
  const [containers, setContainers] = useState([]);
  const [containerId, setContainerId] = useState("");
  const [Addcontainers, setAddcontainers] = useState({
    maxWeight: "",
    maxVolume: "",
    isFridge: "",
    usualName: "",
    containerType: "",
    storedMaterialProperty: [],
  });
  const [deletedContainers, setDeletedContainers] = useState({
    ids: [],
  });
  //===============================Delete Modal======================================
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);

  //==============================handleMatrialProp=======================================
  const handleMatrialProp = (e) => {
    if (e.target.checked) {
      setAddcontainers({
        ...Addcontainers,
        storedMaterialProperty: [
          ...Addcontainers.storedMaterialProperty,
          e.target.value,
        ],
      });
    } else {
      setAddcontainers({
        ...Addcontainers,
        storedMaterialProperty: Addcontainers.storedMaterialProperty.filter(
          (item) => item !== e.target.value
        ),
      });
    }
  };
  //==============================handleInputs=======================================

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setAddcontainers({ ...Addcontainers, [name]: value });
  };
  //===========================fetch Containers=========================

  useEffect(() => {
    const fetchContainers = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/container",
        requestOptions
      );
      const body = await response.json();

      if (!response.ok) {
        setContainers([]);
        console.log(body);
      } else {
        setContainers(body);
      }
    };
    fetchContainers();
  }, []);
  //===========================Add Containers=========================
  const addContainer = async (e) => {
    try {
      e.preventDefault();
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");
      if (
        Addcontainers.maxVolume &&
        Addcontainers.maxWeight &&
        Addcontainers.isFridge &&
        Addcontainers.usualName &&
        Addcontainers.containerType &&
        Addcontainers.storedMaterialProperty
      ) {
        var raw = JSON.stringify({
          maxWeight: Addcontainers.maxWeight,
          maxVolume: Addcontainers.maxVolume,
          isFridge: Addcontainers.isFridge,
          usualName: Addcontainers.usualName,
          containerType: Addcontainers.containerType,
          storedMaterialProperty: Addcontainers.storedMaterialProperty,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          "https://www.api.setalkel.co/container/",
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          alert("dashboard.containers.There Is An Error");
          console.log(body);
        } else {
          alert("dashboard.containers.Container Has Been Added");
          setAddcontainers({
            maxWeight: "",
            maxVolume: "",
            isFridge: "",
            isHighcube: "",
            containerType: "",
            storedMaterialProperty: [],
          });
          window.location.reload();
        }
      } else {
        alert("dashboard.containers.Please Fill All The Data To Create A Container");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //===========================Delete Containers=========================

  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/container/${containerId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("dashboard.containers.item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //============================Delete Mutliple Product=====================
  const deleteContainers = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedContainers.ids.includes(contactID)) {
        setDeletedContainers({
          ...deletedContainers,
          ids: [...deletedContainers.ids, contactID],
        });
      }
    } else {
      setDeletedContainers({
        ids: deletedContainers.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultipleContainers = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedContainers);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/container/collection",
      requestOptions
    );
    if (!response.ok) {
      alert("dashboard.containers.There Is An Error ");
      console.log(response);
    } else {
      alert("dashboard.containers.Containers Have Been Deleted !!");
      window.location.reload();
    }
  };
  useEffect(() => {
    if (deletedContainers.ids.length > 0) {
      document.getElementById("multipleDelete").style.visibility = "visible";
    } else {
      document.getElementById("multipleDelete").style.visibility = "hidden";
    }
  }, [deletedContainers.ids]);

  const navigate = useNavigate();
  const [generateExcelFile, setGenerateExecelFile] = useState();
  const { data: Excel } =
    UnVerifiedAgentsApi.GenerateExelFileQueryForContainer();
  const getFileExcel = async () => {
    UnVerifiedAgentsApi.GetFileExcel(generateExcelFile);
    setGenerateExecelFile(undefined);
  };
  const { t } = useTranslation();

  const columns = [
    {
      name: t("dashboard.containers.ID"),
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: t("dashboard.containers.Max Weight"),
      selector: (row) => <span>{row.maxWeight}</span>,
    },
    {
      name: t("dashboard.containers.Max Volume"),
      selector: (row) => <span>{row.maxVolume}</span>,
    },
    {
      name: t("dashboard.containers.HighCube"),
      selector: (row) => <span>{row.isHighcube ? t("dashboard.containers.yes") : t("dashboard.containers.no")}</span>,
    },
    {
      name: t("dashboard.containers.Fridge"),
      selector: (row) => <span>{row.isFridge ? t("dashboard.containers.yes") : t("dashboard.containers.no")}</span>,
    },
    {
      name: t("dashboard.containers.Container Type"),
      selector: (row) => (
        <span>{row.containerType ? row.containerType : t("dashboard.containers.No Type")}</span>
      ),
    },
    {
      name: t("dashboard.containers.Stored Material"),
      selector: (row) => (
        <span>
          {row.storedMaterialProperty.map((prop) => {
            return <span>{prop}</span>;
          })}
        </span>
      ),
    },
    {
      name: t("dashboard.containers.Usual Name"),
      selector: (row) => (
        <span>{row.usualName ? row.usualName : t("dashboard.containers.No usualName")}</span>
      ),
    },
    {
      name: t("dashboard.containers.Update"),
      selector: (row) => (
        <Link to={`/dashboard/crcontainers/${row._id}`}>
          <Button variant="dark">{t("dashboard.containers.Update")}</Button>
        </Link>
      ),
    },
    {
      name: t("dashboard.containers.Delete"),
      selector: (row) => (
        <div>
          <Button
            onClick={() => {
              handleDeleteModalShow();
              setContainerId(row._id);
            }}
            variant="danger"
          >
            {t("dashboard.containers.Delete")}
          </Button>
          <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t("dashboard.containers.Are You Sure You Want To Delete This Container")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="dark" onClick={handleDeleteModalClose}>
                {t("dashboard.containers.Close")}
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                {t("dashboard.containers.Delete")}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
    {
      name: t("dashboard.containers.M-Delete"),
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteContainers(e, row._id)}
        />
      ),
    },
  ];
  return (
    <section className="cms-create-containers">
      <Row>
        <div className="crud-containers">
          <div className="title">
            <h1>{t("dashboard.containers.Containers")}</h1>
          </div>

          <div className="create-container">
            <Form>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Button
                  variant="warning"
                  onClick={() => navigate("add-conainer")}
                >
                  {t("dashboard.containers.Add Container")}
                </Button>
                {!generateExcelFile ? (
                  <Button
                    variant="warning"
                    id="verify-btn"
                    onClick={() => setGenerateExecelFile(Excel)}
                  >
                    {t("dashboard.containers.Export Excel File for Containers")}
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    id="verify-btn"
                    onClick={getFileExcel}
                  >
                    {t("dashboard.containers.Download Excel File")}
                  </Button>
                )}
                <Button variant="danger" id="verify-btn">
                  {t("dashboard.containers.Import Excel")}
                </Button>
                <Button
                  id="multipleDelete"
                  variant="outline-danger"
                  onClick={deleteMultipleContainers}
                >
                  {t("dashboard.containers.Multiple Delete")}
                </Button>
              </div>
            </Form>
          </div>
          <br />
        </div>
      </Row>
      <Row>
        <div className="read-containers"></div>
        <DataTable
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={containers}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
};

export default CRContainers;
