import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FaTrashAlt } from "react-icons/fa"; // Import trash icon
import { AiOutlineFilePdf, AiOutlineFileWord, AiOutlineFileExcel } from "react-icons/ai"; // Import file icons
import "./CRUDAgents.css";
import { useTranslation } from "react-i18next";

const CRUDAgents = ({ online }) => {
  const [agentId, setAgentId] = useState("");
  const [agents, setAgents] = useState([]);
  const [onlineAgent, setOnlineAgent] = useState({});
  const [AgentInfo, setAgentInfo] = useState({
    name: "",
    email: "",
    mobilePhone: "",
    phone: "",
    country: "",
    address: "",
    port: "",
    companyNameVerification: "",
    companyName: "",
    password: "",
    contactEmails: [],
  });
  const [isVerifiedAgentId, setIsVerifiedAgentId] = useState("");
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [verifyAgentInfo, setVerifyAgentInfo] = useState({});
  const [showAgentOnWebsite] = useState([]);
  //=============================Delete Files========================
  const [DeleteVerifyFilesModalShow, setDeleteVerifyFilesModalShow] =
    useState(false);
  const handleDeleteVerifyModalClose = () =>
    setDeleteVerifyFilesModalShow(false);
  const handleDeleteVerifyModalShow = () => setDeleteVerifyFilesModalShow(true);

  //================================Delete Agents Modal States===========================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  const navigate = useNavigate()
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });

  const [fileGallery, setfileGallery] = useState([]);
  //===================Handle Contact email=============================
  const handlefilesChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setfileGallery((prevFiles) => [...prevFiles, ...newFiles]);
  };
  // console.log(fileGallery)
  const handleContactEmails = (e) => {
    setAgentInfo({
      ...AgentInfo,
      contactEmails: [e.target.value],
    });
  };
  //=======================Input Handeling===============
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAgentInfo({
      ...AgentInfo,
      [name]: value,
    });
  };
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };

  //==========================Fetch Agent===================
  useEffect(() => {
    const fetchAgents = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        toast.error("There is an Error")
        setAgents([]);
        // console.log(body);
      } else {
        setAgents(body);
      }
    };
    fetchAgents();
  }, []);
  //===================================================Posting Agents Images==================================================================
  const PostAgentImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );
      const fd = new FormData();
      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };
      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/agent/image-from-admin/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      // console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(fileGallery)
  //=======================Post Agent===============
  const queryClient = useQueryClient()
  const AddAgent = async (e) => {
    e.preventDefault();
    const formData = new FormData()

    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      if (AgentInfo.name && AgentInfo.email && AgentInfo.password) {

        formData.append("name", AgentInfo.name)
        formData.append("email", AgentInfo.email)
        formData.append("mobilePhone", AgentInfo.mobilePhone)
        formData.append("phone", AgentInfo.phone)
        formData.append("country", AgentInfo.country)
        formData.append("address", AgentInfo.address)
        formData.append("port", AgentInfo.port)
        formData.append("companyName", AgentInfo.companyName)
        formData.append("password", AgentInfo.password)
        // formData.append("companyNameVerification", AgentInfo.companyNameVerification)
        fileGallery.forEach((fileList, index) => {
          // console.log(fileList)

          formData.append(`files`, fileList);

        });
        var raw = {
          name: AgentInfo.name,
          email: AgentInfo.email,
          mobilePhone: AgentInfo.mobilePhone,
          phone: AgentInfo.phone,
          country: AgentInfo.country,
          address: AgentInfo.address,
          port: AgentInfo.port,
          companyName: AgentInfo.companyName,
          password: AgentInfo.password,
          contactEmails: AgentInfo.contactEmails

        };
        let NewRaw = raw;
        Object.keys(NewRaw).forEach((key) => {
          if (NewRaw[key] === "" || NewRaw[key] === "undefined") {
            delete NewRaw[key];
          }
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formData,
          redirect: "follow",
        };
        // console.log(raw2);
        const response = await fetch(
          "https://www.api.setalkel.co/agent/register-from-admin",
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          toast.error(body.message);
          // console.log(body);
        } else {
          if (Imgfile.Imgfile) {
            await PostAgentImg(body._id);
          }
          toast.success('Agent Added!');
          queryClient.refetchQueries(["get-agent-not"])
          navigate("/dashboard/unverifiedAgents")
        }
      } else {
        toast.error("Cannot Create This Agent Without Name & Email & Password");
      }
    } catch (error) {
      // console.log(error);
    }
  };


  const fileInputRef = useRef(null);
  useEffect(() => {
    const detectAgent = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencoded = new URLSearchParams();
      urlencoded.append("isOnline", online);
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      if (localStorage.getItem("token")) {
        const response = await fetch(
          "https://www.api.setalkel.co/agent/update-info",
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setOnlineAgent({});
          // console.log(body);
        } else {
          setOnlineAgent(body);
        }
      }
    };
    detectAgent();
  }, [online]);


  //============================Detect IF Agent Verified Or Not=====================
  const verifyAgents = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(verifyAgentInfo);
    // console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/agent/verification/",
      requestOptions
    );
    if (!response.ok) {
      toast.error("Agent Not Verified");
      // console.log(response);
    } else {
      toast.error("Agent State Has Been Changed Successfuly")
    }
  };

  useEffect(() => {
    const getVerifyAgentFiles = async () => {
      if (isVerifiedAgentId) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(
          `https://www.api.setalkel.co/agent/verification/${isVerifiedAgentId}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          setFiles([]);
          setIsLoading(true);
          // console.log(body);
        } else {
          setFiles(body.files);
          setIsLoading(false);
        }
      }
    };
    getVerifyAgentFiles();
  }, [isVerifiedAgentId]);
  const filesRef = useRef()
  const removeFile = (index) => {

    setfileGallery((prevFiles) => prevFiles.filter((_, i) => i !== index));
    if (filesRef.current) {
      filesRef.current.value = ""; // إفراغ الحقل باستخدام useRef
    }
  };
  const renderFileIcon = (file) => {
    const fileType = file.type;
    if (fileType.startsWith("image/")) {
      return <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: "100px", height: "100px" }} />;
    } else if (fileType === "application/pdf") {
      return <AiOutlineFilePdf size={50} color="red" />;
    } else if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || fileType === "application/msword") {
      return <AiOutlineFileWord size={50} color="blue" />;
    } else if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType === "application/vnd.ms-excel") {
      return <AiOutlineFileExcel size={50} color="green" />;
    } else {
      return <span>{file.name}</span>;
    }
  };
  const { t } = useTranslation();
  return (
    <section className="cms-create-agents">
    <Row>
      <div className="crud-title">
        <h2>{t("dashboard.agent.title")}</h2>
      </div>
    </Row>
    <Row>
      <div className="create-agent">
        <Form onSubmit={AddAgent}>
          <Form.Group className="form">
            <div>
              <Form.Label>{t("dashboard.agent.nameLabel")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={AgentInfo.name}
                onChange={handleInputChange}
                placeholder={t("dashboard.agent.namePlaceholder")}
                required
              />
            </div>
            <div>
              <Form.Label>{t("dashboard.agent.emailLabel")}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={AgentInfo.email}
                onChange={handleInputChange}
                placeholder={t("dashboard.agent.emailPlaceholder")}
                required
              />
            </div>
            <div>
              <Form.Label>{t("dashboard.agent.mobilePhoneLabel")}</Form.Label>
              <Form.Control
                type="text"
                name="mobilePhone"
                value={AgentInfo.mobilePhone}
                onChange={handleInputChange}
                placeholder={t("dashboard.agent.mobilePhonePlaceholder")}
              />
            </div>
            <div>
              <Form.Label>{t("dashboard.agent.phoneLabel")}</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={AgentInfo.phone}
                onChange={handleInputChange}
                placeholder={t("dashboard.agent.phonePlaceholder")}
              />
            </div>
            <div>
              <Form.Label>{t("dashboard.agent.countryLabel")}</Form.Label>
              <Form.Control
                type="text"
                name="country"
                value={AgentInfo.country}
                onChange={handleInputChange}
                placeholder={t("dashboard.agent.countryPlaceholder")}
              />
            </div>
            <div>
              <Form.Label>{t("dashboard.agent.addressLabel")}</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={AgentInfo.address}
                onChange={handleInputChange}
                placeholder={t("dashboard.agent.addressPlaceholder")}
              />
            </div>
            <div>
              <Form.Label>{t("dashboard.agent.portLabel")}</Form.Label>
              <Form.Control
                type="text"
                name="port"
                value={AgentInfo.port}
                onChange={handleInputChange}
                placeholder={t("dashboard.agent.portPlaceholder")}
              />
            </div>
            <div>
              <Form.Label>{t("dashboard.agent.companyNameLabel")}</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                value={AgentInfo.companyName}
                onChange={handleInputChange}
                placeholder={t("dashboard.agent.companyNamePlaceholder")}
              />
            </div>
            <div>
              <Form.Label>{t("dashboard.agent.passwordLabel")}</Form.Label>
              <Form.Control
                type="text"
                name="password"
                value={AgentInfo.password}
                onChange={handleInputChange}
                placeholder={t("dashboard.agent.passwordPlaceholder")}
                required
              />
            </div>
            <div>
              <Form.Label>{t("dashboard.agent.contactEmailsLabel")}</Form.Label>
              <Form.Control
                type="email"
                name="contactEmails"
                value={AgentInfo.contactEmails}
                onChange={(e) => handleContactEmails(e)}
                placeholder={t("dashboard.agent.contactEmailsPlaceholder")}
              />
            </div>
            <div>
              <Form.Label htmlFor="ProductImg">{t("dashboard.agent.imageLabel")}</Form.Label>
              <div className="d-flex flex-column">
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  style={{
                    height: "fit-content",
                  }}
                />
                {Imgfile.Imgfile && (
                  <div
                    className="position-relative"
                    style={{
                      width: "fit-content",
                    }}
                  >
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={URL.createObjectURL(Imgfile.Imgfile)}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setImgfile({
                          Imgfile: null,
                        });
                        if (fileInputRef.current) {
                          fileInputRef.current.value = ""; // Reset input field
                        }
                      }}
                      style={{
                        position: "absolute",
                        top: "-18px",
                        right: "5px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      &times;
                    </button>
                  </div>
                )}
              </div>
            </div>
            <Button variant="warning" type="submit" style={{ height: "fit-content" }}>
              {t("dashboard.agent.addButton")}
            </Button>
          </Form.Group>
        </Form>
      </div>
      <hr />
    </Row>
  </section>
  );
};

export default CRUDAgents;
