import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { customStyles } from "../../../../constants/DataTableCustomeStyles";
import { useTranslation } from "react-i18next";

const ImportStateAgents = () => {
  const docLang = document.querySelector("html").lang

  const [importCountries, setImportCountries] = useState([]);
  const [importStates, setImportStates] = useState([]);
  const [stateAgents, setStateAgents] = useState([]);
  const [choosenImportCountryId, setchoosenImportCountryId] = useState("");
  const [choosenImportStateId, setchoosenImportStateId] = useState("");
  const [agents, setAgents] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [agentsIds] = useState([]);
  //===============================Delete Modal======================================
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleDeleteModalClose = () => setDeleteModalShow(false);
  const handleDeleteModalShow = () => setDeleteModalShow(true);
  //=======================Select Country Handling==========
  const handleCountryChange = (e) => {
    setchoosenImportCountryId(e.target.value);
    importCountries.forEach((country) => {
      if (country._id === e.target.value) {
        setImportStates(country.states);
      }
    });
  };
  const handleStateChange = (e) => {
    setchoosenImportStateId(e.target.value);
  };
  useEffect(() => {
    importStates.forEach((state) => {
      if (state._id === choosenImportStateId) {
        setStateAgents(state.agents);
      }
    });
  }, [choosenImportStateId, importStates]);
  //====================handleAgentChange============
  const handleAgentChange = (e, agent) => {
    if (e.target.checked) {
      if (agentsIds.includes(agent._id)) {
        return;
      } else {
        agentsIds.push(agent._id);
      }
    } else {
      for (let i = 0; i < agentsIds.length; i++) {
        const element = agentsIds[i];
        if (element === agent._id) {
          agentsIds.splice(i, 1);
        }
      }
    }
  };
  //===========================Get Agents =================
  useEffect(() => {
    const getAgents = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/agent",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
        setAgents([]);
      } else {
        setAgents(body);
      }
    };
    getAgents();
  }, []);

  //================get All Import Countries Info For Admin ===================
  useEffect(() => {
    const getAllImportCountriesInfo = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(
        "https://www.api.setalkel.co/import-country/admin",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setImportCountries([]);
        console.log(body);
      } else {
        setImportCountries(body);
      }
    };
    getAllImportCountriesInfo();
  }, []);
  //================================= Add Agents To State=========================
  const addAgentsToState = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      countryId: choosenImportCountryId,
      stateId: choosenImportStateId,
      agents: agentsIds,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (
      choosenImportCountryId &&
      choosenImportStateId &&
      agentsIds.length > 0
    ) {
      const response = await fetch(
        "https://www.api.setalkel.co/import-country/state/agents",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
        toast.error("Process Not Completed");
      } else {
        toast.success("Process Has Been Done Successfully !!!");
      }
    } else {
      toast.error(
        "Please Choose Country And State And One Agent At Least To Continue The Process"
      );
    }
  };
  //==============================Delete Agent From State==================
  const deleteAgentFromState = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      countryId: choosenImportCountryId,
      stateId: choosenImportStateId,
      agents: [agentId],
    });
    console.log(raw);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/import-country/state/agents",
      requestOptions
    );
    if (!response.ok) {
      toast.error("Agent Not Deleted");
      console.log(response);
    } else {
      toast.success("Agent has Been Deleted !!");
      handleDeleteModalClose();
    }
  };
  const {t} = useTranslation()
  const columns = [
    { name: t("dashboard.country.importStateAgents.name"), selector: (row) => row.name },
    { name: t("dashboard.country.importStateAgents.email"), selector: (row) => row.email },
    {
      name: t("dashboard.country.importStateAgents.delete"),
      selector: (row) => (
        <span>
          {" "}
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteModalShow();
              setAgentId(row._id);
            }}
          >
            {" "}
            {t("dashboard.country.importStateAgents.delete")}{" "}
          </Button>{" "}
        </span>
      ),
    },
  ];
  return (
    <section>
      {" "}
      <Row>
        {" "}
        <div className="crud-countries">
          {" "}
          <div className="title">
            {" "}
            <h1>{t("dashboard.country.importStateAgents.importStateAgentsTitle")}</h1>{" "}
          </div>{" "}
        </div>{" "}
      </Row>{" "}
      <Row>
        {" "}
        <Form>
          {" "}
          <div className="import-states-agent">
            {" "}
            <div>
              {" "}
              <h6>{t("dashboard.country.importStateAgents.chooseImportCountry")}</h6>{" "}
              <Form.Select
                name="countryName"
                value={choosenImportCountryId}
                onChange={(e) => handleCountryChange(e)}
              >
                {" "}
                <option>{t("dashboard.country.importStateAgents.openSelectMenu")}</option>{" "}
                {importCountries.map((country, index) => (
                  <option key={index} value={country._id}>
                    {" "}
                    {country.countryName[docLang]
                      ? country.countryName[docLang]
                      : "name"}{" "}
                  </option>
                ))}{" "}
              </Form.Select>{" "}
            </div>{" "}
            <div>
              {" "}
              <h6>{t("dashboard.country.importStateAgents.chooseImportState")}</h6>{" "}
              <Form.Select
                aria-label="Default select example"
                name="countryName"
                value={choosenImportStateId}
                onChange={(e) => handleStateChange(e)}
              >
                {" "}
                <option>{t("dashboard.country.importStateAgents.openSelectMenu")}</option>{" "}
                {importStates.map((state, index) => (
                  <option key={index} value={state._id}>
                    {" "}
                    {state.stateName[docLang]}{" "}
                  </option>
                ))}{" "}
              </Form.Select>{" "}
            </div>{" "}
          </div>{" "}
          <Form.Group className="mt-3">
            {" "}
            <Form.Label>
              {" "}
              {t("dashboard.country.importStateAgents.chooseAgents")}{" "}
            </Form.Label>{" "}
            <div className="check-import-state">
              {" "}
              {agents.map((agent, index) => (
                <Form.Check
                  type="checkbox"
                  key={index}
                  label={agent.name}
                  id={agent._id}
                  value={agent._id}
                  onChange={(e) => handleAgentChange(e, agent)}
                />
              ))}{" "}
            </div>{" "}
          </Form.Group>{" "}
          <Button variant="warning" onClick={addAgentsToState}>
            {" "}
            {t("dashboard.country.importStateAgents.addAgentsToState")}{" "}
          </Button>{" "}
        </Form>{" "}
      </Row>{" "}
      <hr />{" "}
      <Row>
        {" "}
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={stateAgents}
          highlightOnHover={true}
          customStyles={customStyles}
        />{" "}
      </Row>{" "}
      <Modal show={DeleteModalShow} onHide={handleDeleteModalClose}>
        {" "}
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>
            {" "}
            {t("dashboard.country.importStateAgents.deleteConfirmation")}{" "}
          </Modal.Title>{" "}
        </Modal.Header>{" "}
        <Modal.Footer>
          {" "}
          <Button variant="dark" onClick={handleDeleteModalClose}>
            {" "}
            {t("dashboard.country.importStateAgents.close")}{" "}
          </Button>{" "}
          <Button variant="danger" onClick={() => deleteAgentFromState()}>
            {" "}
            {t("dashboard.country.importStateAgents.delete")}{" "}
          </Button>{" "}
        </Modal.Footer>{" "}
      </Modal>{" "}
    </section>
  );
};

export default ImportStateAgents;
