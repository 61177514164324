import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// scss
import "./topnav.scss";
// images
import CartImg from "../../../assets/Payments/cart.png";
// notification
import Notification from "../Notification/AdminNotification";
// icons
import { BsFillBellFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { languages } from "../../../languages";
import { NavDropdown } from "react-bootstrap";
import cookies from "js-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
const TopNav = () => {
  const adminInfo = localStorage.getItem("user-data");

  // Toggle sidebar open/close
  const openSidebar = () => {
    document.body.classList.toggle("sidebar-open");
    // document.querySelector(".main__content").classList.toggle("move")

  };

  const adminCartQuantity = useSelector(
    (state) => state.adminCart.adminCartTotalQuantity
  );

  const navigate = useNavigate();
  const navigateToAdminCart = () => {
    navigate("/dashboard/adminCart");
  };

  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir;
  }, [currentLanguage, t]);
   
  return (
    <div className="topnav">
      <div className="topnav-info">
        <span>User : {adminInfo}</span>
        <Notification />
        <div className="d-flex align-items-center">
       
        <div className="footer-langs">
              <NavDropdown
                title={t(`Components.Footer.Languages.Title`)}
                id="basic-nav-dropdown"
              >
                {languages.map(({ code, name, country_code }) => (
                  <li key={country_code}>
                    <Link
                      to="#"
                      className={classNames("dropdown-item", {
                        disabled: currentLanguageCode === code,
                      })}
                      onClick={() => {
                        i18next.changeLanguage(code);
                        window.location.reload();
                      }}
                      style={{
                        textAlign: "start"
                      }}
                    >
                      <span
                        className={`flag-icon flag-icon-${country_code} mx-2`}
                        style={{
                          opacity: currentLanguageCode === code ? 0.5 : 1,
                        }}
                      ></span>
                      {t(`Components.Footer.Languages.${name}`)}
                    </Link>
                  </li>
                ))}
              </NavDropdown>
            </div>
         
          {adminInfo && <BsFillBellFill size={"20px"} />}
        </div>
      </div>

      <div>
        {adminCartQuantity > 0 ? (
          <>
            {adminCartQuantity}
            <br />
            <img
              src={CartImg}
              alt="cart"
              className="cart"
              onClick={() => navigateToAdminCart()}
              style={{
                cursor: "pointer",
              }}
            />
          </>
        ) : null}
      </div>
      
      <div className="sidebar-toggle" onClick={openSidebar}>
        <i className="bx bx-menu-alt-right"></i>
      </div>
    </div>
  );
};

export default TopNav;
