import React from "react";
import { Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Pallet = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Row>
      <div className="d-flex">
        <Button variant="danger" id="verify-btn">
          {t("dashboard.palletActions.exportExcel")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.palletActions.importExcel")}
        </Button>
        <Button
          onClick={() => {
            navigate("/dashboard/add-pallet");
          }}
          variant="warning"
          id="verify-btn"
        >
          {t("dashboard.palletActions.addPallet2")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.palletActions.update")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.palletActions.delete")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.palletActions.showHide")}
        </Button>
      </div>
    </Row>
  );
};

export default Pallet;
