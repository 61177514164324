import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Form, Row } from "react-bootstrap";
//css
import "./UpdateContainer.css";
import { useTranslation } from "react-i18next";
const UpdateContainer = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [Addcontainers, setAddcontainers] = useState({
    _id: "",
    maxWeight: "",
    maxVolume: "",
    isFridge: "",
    usualName: "",
    containerType: "",
    storedMaterialProperty: [],
  });
  //==============================handleMatrialProp=======================================
  const handleMatrialProp = (e) => {
    setAddcontainers({
      ...Addcontainers,
      storedMaterialProperty: [e.target.value],
    });
  };
  //=======================Input Handeling===============
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setAddcontainers({ ...Addcontainers, [name]: value });
  };

  //======================================================================Update Containers Info==============================================================
  //=====================get Container By Id==============================
  const getContainerById = useCallback(async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/container/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    console.log(body);
    setAddcontainers({
      _id: body._id,
      maxWeight: body.maxWeight ? body.maxWeight : "",
      maxVolume: body.maxVolume ? body.maxVolume : "",
      isFridge: body.isFridge,
      usualName: body.usualName ? body.usualName : "",
      containerType: body.containerType ? body.containerType : "",
      storedMaterialProperty: body.storedMaterialProperty
        ? body.storedMaterialProperty
        : "",
    });
  }, [params.Id]);
  useEffect(() => {
    getContainerById();
  }, [getContainerById]);
  //===========================Update Containers=========================

  const updateContainer = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      maxWeight: Addcontainers.maxWeight,
      maxVolume: Addcontainers.maxVolume,
      isFridge: Addcontainers.isFridge,
      usualName: Addcontainers.usualName,
      containerType: Addcontainers.containerType,
      storedMaterialProperty: Addcontainers.storedMaterialProperty,
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      `https://www.api.setalkel.co/container/${Addcontainers._id}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Container Not Updated !!!!");
      console.log(body);
    } else {
      window.location.reload();
      navigate("/dashboard/crcontainers");
    }
  };
  //=========================Cancel Function=========================
  const cancel = () => {
    navigate("/dashboard/crcontainers");
  };
  const { t } = useTranslation()
  return (
    <section className="cms-create-containers">
  <Row>
    <div className="crud-containers">
      <div className="title">
        <h1>{t("dashboard.containers.Update Containers")}</h1>
      </div>

      <div className="create-container">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicmaxWeight">
            <Form.Label>{t("dashboard.containers.Max Weight")}</Form.Label>
            <Form.Control
              type="text"
              name="maxWeight"
              value={Addcontainers.maxWeight}
              placeholder={t("dashboard.containers.Max Weight")}
              onChange={handleInputs}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicmaxVolume">
            <Form.Label>{t("dashboard.containers.Max Volume")}</Form.Label>
            <Form.Control
              type="text"
              name="maxVolume"
              value={Addcontainers.maxVolume}
              placeholder={t("dashboard.containers.Max Volume")}
              onChange={handleInputs}
            />
          </Form.Group>

          <Form.Label>{t("dashboard.containers.Fridge ?")}</Form.Label>
          <Form.Select
            name="isFridge"
            onChange={handleInputs}
            aria-label="Default select example"
          >
            <option value={true}>{t("dashboard.containers.Yes")}</option>
            <option value={false}>{t("dashboard.containers.No")}</option>
          </Form.Select>
          <br />

          <Form.Group className="mb-3" controlId="formBasicmaxVolume">
            <Form.Label>{t("dashboard.containers.Usual Name")}</Form.Label>
            <Form.Control
              name="usualName"
              placeholder={t("dashboard.containers.Usual Name")}
              value={Addcontainers.usualName}
              onChange={handleInputs}
            />
          </Form.Group>

          <Form.Label>{t("dashboard.containers.Container Type")}</Form.Label>
          <Form.Select
            name="containerType"
            value={Addcontainers.containerType}
            onChange={handleInputs}
            aria-label="Default select example"
          >
            <option value={`land`}>{t("dashboard.containers.Land")}</option>
            <option value={`sea`}>{t("dashboard.containers.Sea")}</option>
            <option value={`air`}>{t("dashboard.containers.Air")}</option>
          </Form.Select>

          <Form.Label>{t("dashboard.containers.Stored Material Property")}</Form.Label>
          <Form.Select
            name="storedMaterialProperty"
            value={Addcontainers.storedMaterialProperty}
            onChange={handleMatrialProp}
            aria-label="Default select example"
          >
            <option value={`dried`}>{t("dashboard.containers.Dried")}</option>
            <option value={`frozen`}>{t("dashboard.containers.Frozen")}</option>
            <option value={`mix`}>{t("dashboard.containers.Mix")}</option>
          </Form.Select>

          <Button variant="warning" onClick={updateContainer}>
            {t("dashboard.containers.Update Container")}
          </Button>
          <Button id="multipleDelete" variant="outline-dark" onClick={cancel}>
            {t("dashboard.containers.Cancel")}
          </Button>
        </Form>
      </div>
    </div>
  </Row>
</section>
  );
};

export default UpdateContainer;
