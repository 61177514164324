import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
//boostrap
import { Button, Form, Modal, Row } from "react-bootstrap";
//redux
import { useDispatch } from "react-redux";

import DataTable from "react-data-table-component";

//components
import Notification from "../../../Components/Notification/Notification";
import {
  adminCheckIsEditing,
  adminUpdateSuggestion,
  adminUpdateSuggestionShipment,
  shipmentAgentId,
} from "../../../redux/Admin/adminCartSlice";
import {
  adminAddContainer,
  adminAddContainerId,
  adminAddDeliveryMethod,
  adminAddExportCountryId,
  adminAddImportCountryId,
  adminAddImportStateId,
} from "../../../redux/Admin/adminShipmentStepperSlice";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { useTranslation } from "react-i18next";
var deleteKey = require("key-del");
const WaitingPaymentShipment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [WaitingPaymentShipments, setWaitingPaymentShipments] = useState([]); //third Status
  const docLang = document.querySelector("html").lang

  const [shipmentId, setShipmentId] = useState("");
  const [FreezedShipmentId, setFreezedShipmentId] = useState("");
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [freezeShipment, setFreezeShipment] = useState({
    freezed: false,
  });
  //=======================================Delete Payment Shipment Modal================================
  const [DeletePaymentShipmentModalShow, setDeletePaymentShipmentModalShow] =
    useState(false);

  const handleDeletePaymentShipmentModalClose = () =>
    setDeletePaymentShipmentModalShow(false);
  const handleDeletePaymentShipmentModalShow = () =>
    setDeletePaymentShipmentModalShow(true);
  //========================================Handle Freeze Shipment===========================
  const handleFreezeShipment = (e, shipmentId) => {
    setFreezedShipmentId(shipmentId);
    setFreezeShipment({
      freezed: e.target.value,
    });
  };
  //==================get Waiting Finish Payments ==============================

  useEffect(() => {
    const getWaitingFinishPayments = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/?status=waiting finish payment",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setWaitingPaymentShipments([]);
        console.log(body);
      } else {
        setWaitingPaymentShipments(body);
      }
    };
    getWaitingFinishPayments();
  }, []);

  //========================================freeze Shipment ==============================
  const PostFreezeShipment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      freezed: freezeShipment.freezed,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/freezed/${FreezedShipmentId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("dashboard.shipment.Shipment Status Not Changed");
      console.log(body);
    } else {
      alert("dashboard.shipment.Shipment Status Has Been Changed");
      window.location.reload();
    }
  };
  //=============================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("dashboard.shipment.a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  //================================Delete Shipment==============================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/shipment/admin/${shipmentId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("dashboard.shipment.item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //===============================Change Shipment Status========================
  const chagneShipmentStatus = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      status: shipmentStatus,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/update-shipment-status/${shipmentId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("dashboard.shipment.Shipment Status Not Updated");
      console.log(response);
    } else {
      alert("dashboard.shipment.Shipment Status Has Been Changed");
      window.location.reload();
    }
  };

  //=======================Handle Edit Suggestion=====================

  const handleEdit = async (suggestion) => {
    const adminUpdatedCartItems = [];
    const adminUpdatedShipmentItems = [];
    var result;
    suggestion.order.items.forEach((item) => {
      item.product.cartQuantity = item.count;
      adminUpdatedCartItems.push(item.product);
    });
    for (let i = 0; i < adminUpdatedCartItems.length; i++) {
      const element = adminUpdatedCartItems[i];
      element.count = element.cartQuantity;
      element.product = element._id;
      element.note = "";
      result = deleteKey(element, [
        "addedBy",
        "additionalImage",
        "cat",
        "countInBox",
        "cartQuantity",
        "countryOrigin",
        "createdAt",
        "hidden",
        "img",
        "materialProperty",
        "package",
        "packageGrossWeight",
        "peiceQty",
        "stockOff",
        "subCat",
        "supplierName",
        "unitForPackage",
        "updatedAt",
        "volume",
        "is_new",
        "updatedBy",
        "_id",
      ]);
      adminUpdatedShipmentItems.push(result);
    }

    localStorage.setItem(
      "adminUpdatedCartItems",
      JSON.stringify(adminUpdatedCartItems)
    );
    localStorage.setItem(
      "adminUpdatedShipmentItems",
      JSON.stringify(adminUpdatedShipmentItems)
    );
    localStorage.setItem("container", JSON.stringify(suggestion.containerInfo));
    localStorage.setItem("exportCountryId", suggestion.exportCountry._id);
    localStorage.setItem("importCountryId", suggestion.importCountry._id);
    localStorage.setItem("importStateId", suggestion.state._id);
    localStorage.setItem("deliveryMethod", suggestion.deliveryMethod._id);
    localStorage.setItem("containerId", suggestion.containerInfo._id);
    localStorage.setItem("adminUpdatedSuggestionId", suggestion._id);
    localStorage.setItem("adminUpdatedSuggestionAgentId", suggestion.agent._id);
    dispatch(adminCheckIsEditing(true));
    dispatch(adminUpdateSuggestion(adminUpdatedCartItems));
    dispatch(adminUpdateSuggestionShipment(adminUpdatedShipmentItems));
    dispatch(adminAddExportCountryId(suggestion.exportCountry._id));
    dispatch(adminAddImportCountryId(suggestion.importCountry._id));
    dispatch(adminAddImportStateId(suggestion.state._id));
    dispatch(adminAddDeliveryMethod(suggestion.deliveryMethod._id));
    dispatch(adminAddContainerId(suggestion.containerInfo.container._id));
    dispatch(adminAddContainer(suggestion.containerInfo));
    dispatch(shipmentAgentId(suggestion.agent._id));

    navigate("/dashboard/adminCart");
  };
  const {t} = useTranslation()
  const columns = [
    {
      name: t("dashboard.shipment.id"),
      selector: (row, index) => console.log(row),
    },
    {
      name: t("dashboard.shipment.id"),
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: t("dashboard.shipment.date"),
      selector: (row) => row.order.createdAt.slice(0, 10),
    },
    {
      name: t("dashboard.shipment.container"),
      selector: (row) => row.containerInfo.container.usualName,
    },
    {
      name: t("dashboard.shipment.agent"),
      selector: (row) => row.agent.name,
    },
    {
      name: t("dashboard.shipment.exportCountry"),
      selector: (row) => row.exportCountry.countryName[docLang],
    },
    {
      name: t("dashboard.shipment.importCountry"),
      selector: (row) =>
        row.importCountry.countryName[docLang] + " / " + row.state.stateName[docLang],
    },
    {
      name: t("dashboard.shipment.notePerProducts"),
      selector: (row) => (
        <div
          style={{
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {row?.order?.items?.map((d) => (
            <>
              {d.note ? (
                <div>
                  <span>{d.product.subCat[docLang]}</span> : <span>{d.note}</span>
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
      ),
      width: "200px",
    },
    {
      name: t("dashboard.shipment.adminFiles"),
      selector: (row) => (
        <ul className="p-0">
          {row.adminFiles.map((file, index) => (
            <li key={index}>
              <Button
                variant="warning"
                onClick={() => getFile(file)}
                style={{
                  wordBreak: "break-all",
                }}
              >
                {t("dashboard.shipment.file", { index })}
              </Button>
            </li>
          ))}
        </ul>
      ),
    },
    {
      name: t("dashboard.shipment.shipmentFiles"),
      selector: (row) => (
        <ul className="p-0">
          {Object.entries(row.shipmentFiles).map((file, index) => (
            <li key={index}>
              <Button
                variant="warning"
                onClick={() => getFile(file[1])}
                style={{
                  wordBreak: "break-all",
                }}
              >
                {file[0].slice(0, 16)}
              </Button>
            </li>
          ))}
        </ul>
      ),
    },
    {
      name: t("dashboard.shipment.changeStatus"),
      selector: (row) => (
        <Form.Select
          name="changeStatus"
          value={shipmentId}
          onChange={(e) => {
            setShipmentId(row._id);
            setShipmentStatus(e.target.value);
          }}
        >
          <option>{t("dashboard.shipment.changeStatus")}</option>
          <option value="finished">{t("dashboard.shipment.finished")}</option>
        </Form.Select>
      ),
    },
    {
      name: t("dashboard.shipment.freeze"),
      selector: (row) => (
        <Form.Select
          name="freeze-shipment"
          value={freezeShipment}
          onChange={(e) => handleFreezeShipment(e, row._id)}
        >
          <option>{t("dashboard.shipment.freezeShipment")}</option>
          <option value="true">{t("dashboard.shipment.freeze")}</option>
          <option value="false">{t("dashboard.shipment.defreeze")}</option>
        </Form.Select>
      ),
    },
    {
      name: t("dashboard.shipment.edit"),
      selector: (row) => (
        <Button variant="warning" onClick={() => handleEdit(row)}>
          {t("dashboard.shipment.edit")}
        </Button>
      ),
    },
    {
      name: t("dashboard.shipment.delete"),
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleDeletePaymentShipmentModalShow();
              setShipmentId(row._id);
            }}
          >
            {t("dashboard.shipment.delete")}
          </Button>
  
          <Modal
            show={DeletePaymentShipmentModalShow}
            onHide={handleDeletePaymentShipmentModalClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("dashboard.shipment.deleteShipmentConfirmation")}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeletePaymentShipmentModalClose}>
                {t("dashboard.shipment.close")}
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                {t("dashboard.shipment.deleteShipment")}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
  ];
  
  return (
    <section className="waiting-payment-shipping">
      <Notification />
      <Row>
        <h2>{t("dashboard.shipment.waitingPaymentShipments")}</h2>
        {shipmentId ? (
          <Button variant="warning" onClick={chagneShipmentStatus}>
            {t("dashboard.shipment.changeShipmentStatus")}
          </Button>
        ) : null}
        {freezeShipment.freezed ? (
          <Button id="freezedShipment" variant="dark" onClick={() => PostFreezeShipment()}>
            {t("dashboard.shipment.changeShipmentStatus")}
          </Button>
        ) : null}
      </Row>
      <Row>
        <DataTable
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={WaitingPaymentShipments}
          highlightOnHover={true}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
  
};

export default WaitingPaymentShipment;
