import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Form } from "react-bootstrap";
import toast from 'react-hot-toast';

import { useMutation } from '@tanstack/react-query';
import ApiInstances from '../../../Api/axiso';
import { useTranslation } from 'react-i18next';

const TableRow = ({ item, moveRow }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'row',
        item: { id: item._id },
    });

    const [, dropRef] = useDrop({
        accept: 'row',
        hover: (draggedItem) => {
            if (draggedItem.id !== item._id) {
                moveRow(draggedItem.id, item._id);
            }
        },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <tr ref={(node) => dragRef(dropRef(node))} style={{ opacity }}>
            <td style={{ width: "70px" }}>
                <img
                    src={`https://www.api.setalkel.co${item.img}`}
                    style={{ cursor: 'pointer', height: "70px", width: "70px" }}
                    alt={item.en}
                />
            </td>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.mobilePhone ? item.mobilePhone : "No Country Origin"}</td>


        </tr>
    );
};

const CardItem = ({ item, moveRow }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'row',
        item: { id: item._id },
    });

    const [, dropRef] = useDrop({
        accept: 'row',
        hover: (draggedItem) => {
            if (draggedItem.id !== item._id) {
                moveRow(draggedItem.id, item._id);
            }
        },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <div ref={(node) => dragRef(dropRef(node))} className="card-item" style={{ opacity }}>
            <img src={`https://www.api.setalkel.co${item.img}`} alt={item.name} style={{ width: "100px", height: "100px" }} />
            <div>{item.name}</div>
            <div>{item.email ? `${item.email}` : "No Price Available"}</div>
        </div>
    );
};

const UnVerifiedAgentsDrag = () => {
    const [VerifiedAgents, SetVerifiedAgents] = useState([]);
    const [viewType, setViewType] = useState("grid");

    const fetchAgents = async () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `bearer ${localStorage.getItem("admin-token")}`
        );
        var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: myHeaders,
        };

        const response = await fetch(
            "https://www.api.setalkel.co/agent/admin/?isVerified=false",
            requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
            toast.error("There is an Error");
            SetVerifiedAgents([]);
            // console.log(body);
        } else {
            SetVerifiedAgents(body);
            // console.log(agents[0].password)
            // console.log(decryptedData);
        }
    };
    useEffect(() => {
        fetchAgents();
    }, [])

    // console.log(OurNew)
    const moveRow = (draggedId, hoverId) => {
        const draggedIndex = VerifiedAgents.findIndex((item) => item._id === draggedId);
        const hoverIndex = VerifiedAgents.findIndex((item) => item._id === hoverId);

        if (draggedIndex !== -1 && hoverIndex !== -1) {
            const newCategories = [...VerifiedAgents];
            const [draggedItem] = newCategories.splice(draggedIndex, 1);
            newCategories.splice(hoverIndex, 0, draggedItem);
            // console.log(newCategories)
            SetVerifiedAgents(newCategories);

        }
    };


    // console.log(VerifiedAgents)
    const { mutate } = useMutation({
        mutationFn: async (res) => {
            const { data } = await ApiInstances.post(`/product/cat/update-order`, res);
            return data;
        },
        onSuccess: () => {
            toast.success("Sorting Successfully");
            fetchAgents();
        },
    });

    const handleSort = () => {

        const categoryIds = VerifiedAgents.map(obj => obj._id);
        // mutate({ categoryIds });
    };
    const { t } = useTranslation()
    return (
        <DndProvider backend={HTML5Backend}>
        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
            <Form.Group style={{ marginTop: "23px" }} className="view-type">
                <Form.Label>{t('dashboard.drag.vAgent.viewType')}</Form.Label>
                <Form.Select
                    id="view-type"
                    name="viewType"
                    value={viewType}
                    onChange={(e) => setViewType(e.target.value)}
                >
                    <option value="table">{t('dashboard.drag.vAgent.sequential')}</option>
                    <option value="grid">{t('dashboard.drag.vAgent.grid')}</option>
                </Form.Select>
            </Form.Group>
            <Button
                variant="warning"
                style={{ height: "fit-content", margin: "0", marginTop: "50px" }}
                onClick={handleSort}
            >
                {t('dashboard.drag.vAgent.sorting')}
            </Button>
        </div>
        {VerifiedAgents.length === 0 ? (
            <div style={{ display: "flex", width: "100%", justifyContent: "center", color: "black", fontSize: "16px" }}>
                <p>{t('dashboard.drag.vAgent.selectCategory')}</p>
            </div>
        ) : viewType === "table" ? (
            <Table className='table-drag'>
                <thead>
                    <tr>
                        <th style={{ width: "70px" }}>{t('dashboard.drag.vAgent.image')}</th>
                        <th>{t('dashboard.drag.vAgent.name')}</th>
                        <th>{t('dashboard.drag.vAgent.email')}</th>
                        <th>{t('dashboard.drag.vAgent.mobilePhone')}</th>
                    </tr>
                </thead>
                <tbody>
                    {VerifiedAgents.map((item) => (
                        <TableRow key={item._id} item={item} moveRow={moveRow} />
                    ))}
                </tbody>
            </Table>
        ) : (
            <div className="card-grid">
                {VerifiedAgents.map((item) => (
                    <CardItem key={item._id} item={item} moveRow={moveRow} />
                ))}
            </div>
        )}
    </DndProvider>
    
    );
};

export default UnVerifiedAgentsDrag;
