import React from "react";
import { Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PromotionalMaterials = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  return (
    <Row>
      <h1>{t("dashboard.promotionalMaterials.title")}</h1>
      <div className="d-flex gap-2 align-items-center">
        <Button
          onClick={() => {
            navigate("/dashboard/add-promotionalmaterials");
          }}
          variant="warning"
          id="verify-btn"
        >
          {t("dashboard.promotionalMaterials.addPromotionalMaterials")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.promotionalMaterials.exportExcel")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.promotionalMaterials.importExcel")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.promotionalMaterials.update")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.promotionalMaterials.delete")}
        </Button>
        <Button variant="danger" id="verify-btn">
          {t("dashboard.promotionalMaterials.showHide")}
        </Button>
      </div>
    </Row>
  );
};

export default PromotionalMaterials;
