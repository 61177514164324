import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ApiInstances from "../../Api/axiso";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const PriceProprties = () => {
  const docLang = document.querySelector("html").lang

  const [Price, setPrice] = useState([]);
  const [PriceId, setPriceId] = useState();
  const [PriceProprti, setPriceProprti] = useState([]);
  const [Products, setProducts] = useState([]);
  const [namePrice, setNamePrice] = useState("");
  const [addedPrices, setAddedPrices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryID, setCategoryID] = useState("");

  const handleInfoInputChange = (e) => {
    const { name, value } = e.target;
    setNamePrice(value);
  };
  useEffect(() => {
    const fethcCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setCategories([]);
        // console.log(body);
      } else {
        setCategories(body);
      }
    };
    fethcCategories();
  }, []);
  const fetchPrice = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(
      `https://www.api.setalkel.co/prices`,
      requestOptions
    );
    const body = await response.json();
    // console.log(body);
    if (!response.ok) {
      console.log(body);
    } else {
      setPrice(body?.prices);

      // console.log(body)
    }
  };
  useEffect(() => {
    const getPriceById = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `https://www.api.setalkel.co/prices/${PriceId}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          // console.log(body?.prices);
        } else {
          setPriceProprti(body?.prices);
          // console.log(body?.prices?.prices)
        }
      } catch (error) {
        console.log(error);
      }
    };
    PriceId && getPriceById();
  }, [PriceId]);
  // console.log(PriceProprti)
  useEffect(() => {
    fetchPrice();
  }, []);
  const queryClient = useQueryClient();
  const { data, refetch } = useQuery({
    queryKey: ["get-product", categoryID],
    queryFn: async () => {
      const { data } = await ApiInstances.get(`/product/?cat=${categoryID}`);
      return data;
    },
    enabled: !!categoryID,
  });
  const { data: PriceProprtisss } = useQuery({
    queryKey: ["get-price", categoryID],
    queryFn: async () => {
      const { data } = await ApiInstances.get(`/prices/${PriceId}`);
      return data;
    },
    enabled: !!PriceId,
  });
  useEffect(() => {
    const fetchProducts = async (categoryID) => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/product/?cat=${categoryID}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setProducts([]);
        // console.log(body);
      } else {
        setProducts(body?.data);
      }
    };
    categoryID && fetchProducts(categoryID);
  }, [categoryID]);
  const handleProductPrice = (value, productId, countryOrigin) => {
    // console.log(value)
    if (value === "") {
      const index = addedPrices.findIndex(
        (order) => order.product === productId
      );
      if (index !== -1) {
        addedPrices.splice(index, 1); // حذف العنصر المطابق لـ productId
      }
      return;
    }
    if (value > 0) {
      let flag = false;
      addedPrices.forEach((order) => {
        if (order.product === productId) {
          flag = true;
          order.price = parseFloat(value);
        }
      });
      if (!flag) {
        addedPrices.unshift({
          product: productId,
          countryOrigin: countryOrigin,
          price: isNaN(parseFloat(value)) ? "" : parseFloat(value),
        });
      } else if (flag && value === "") {
        // console.log(flag)
      }
    } else {
      toast.error("Product Price Cannot Be Negative or 0");
    }
  };
  const handleProductPicePrice = (value, productId) => {
    // console.log(value)
    if (value === "") {
      const index = addedPrices.findIndex(
        (order) => order.product === productId
      );
      if (index !== -1) {
        addedPrices.splice(index, 1); // حذف العنصر المطابق لـ productId
      }
      return;
    }
    if (value > 0) {
      let flag = false;
      addedPrices.forEach((order) => {
        if (order.product === productId) {
          flag = true;
          order.piecePrice = parseFloat(value);
        }
      });
      if (!flag) {
        addedPrices.unshift({
          product: productId,
          piecePrice: isNaN(parseFloat(value)) ? "" : parseFloat(value),
        });
      } else if (flag && value === "") {
        // console.log(flag)
      }
    } else {
      toast.error("Product Price Cannot Be Negative or 0");
    }
  };
  const navigate = useNavigate();
  const PostProductsPrices = async () => {
    if (addedPrices.length > 0) {
      const prieces = {
        id: PriceId,
        name: Price.find((d) => d._id === PriceId)?.name,
        prices: addedPrices,
      };
      console.log(Price.find((d) => d._id === PriceId)?.name);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify(addedPrices);
      // console.log(raw);
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(prieces),
        redirect: "follow",
      };
      const response = await fetch(
        `https://www.api.setalkel.co/prices/${PriceId}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        // setAddedPrices([]);
        // console.log(body);
        toast.error("There Is Somthing Wrong");
      } else {
        toast.success("Price Added..");
        setPriceId(PriceId);
        queryClient.refetchQueries(["get-product"]);
        queryClient.refetchQueries(["get-price"]);
        const inputFields = document.querySelectorAll("#price");
        inputFields.forEach((field) => {
          field.value = "";
        });
        // navigate("/dashboard/crprices3")
        setAddedPrices([]);
        setNamePrice("");
      }
    }
  };

  const { t } = useTranslation();

  const columns = [
    {
      name: t("dashboard.price.#"),
      selector: (row, index) => <span>{index + 1}</span>,
    },
    {
      name: t("dashboard.price.Image"),
      selector: (row) => (
        <span>
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{
              height: "70px",
              width: "70px",
            }}
          />
        </span>
      ),
    },
    { name: t("dashboard.price.Category"), selector: (row) => <span>{row.cat[docLang]}</span> },
    {
      name: t("dashboard.price.Product-Name"),
      width: 300,
      selector: (row) => <span>{row.subCat[docLang]}</span>,
    },
    {
      name: t("dashboard.price.Usual-Weight"),
      selector: (row) => <span>{row.peiceQty.usualWeight}</span>,
    },
    {
      name: t("dashboard.price.Country-Origin"),
      selector: (row) => (
        <span>
          {row.countryOrigin ? row.countryOrigin : t("dashboard.price.No Country Origin")}
        </span>
      ),
    },
    {
      name: t("dashboard.price.Material-Property"),
      selector: (row) => (
        <span>
          {row.countryOrigin ? row.materialProperty : t("dashboard.price.No Material Property")}
        </span>
      ),
    },
    {
      name: t("dashboard.price.Box Prices"),
      selector: (row) => (
        <span>
          {PriceProprtisss &&
            PriceProprtisss?.prices?.find((d) => d.product === row._id)?.price}
        </span>
      ),
    },
    {
      name: t("dashboard.price.Piece Price"),
      selector: (row) => (
        <span>
          {PriceProprtisss &&
            PriceProprtisss?.prices?.find((d) => d.product === row._id)
              ?.piecePrice}
        </span>
      ),
    },
    {
      name: t("dashboard.price.Enter Box Price :"),
      selector: (row) => (
        <>
          <Form.Control
            id="price"
            type="number"
            name="price"
            lang="en"
            placeholder=" $ "
            min={1}
            onChange={(e) => {
              handleProductPrice(e.target.value, row._id, row.countryOrigin);
            }}
          />
        </>
      ),
      width: "150px",
    },
    {
      name: t("dashboard.price.Enter Piece Price :"),
      selector: (row) => (
        <>
          <Form.Control
            id="price"
            type="number"
            name="price"
            lang="en"
            placeholder=" $ "
            min={1}
            onChange={(e) => {
              handleProductPicePrice(e.target.value, row._id);
            }}
          />
        </>
      ),
      width: "150px",
    },
    {
      name: t("dashboard.price.Enter Promotion price :"),
      selector: (row) => (
        <>
          <Form.Control
            id="price"
            type="number"
            name="price"
            lang="en"
            placeholder=" $ "
            min={1}
            style={{
              border: "1px solid red",
            }}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: t("dashboard.price.Delete"),
      selector: (row) => (
        <Button
          variant="danger"
          onClick={() => {
            // handleDeleteModalShowShow();
            // setPriceId(row._id);
          }}
        >
          {t("dashboard.price.Delete Price")}
        </Button>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Form.Group
          style={{
            marginBottom: "20px",
          }}
        >
          <Form.Label>{t("dashboard.price.Please Select Category")}</Form.Label>
          <Form.Select
            name="categoryID"
            value={categoryID}
            onChange={(e) => setCategoryID(e.target.value)}
          >
            <option>{t("dashboard.price.Please Select Category")}</option>
            {categories.length > 0
              ? categories.map((category, index) => {
                  return (
                    <option key={index} value={category._id}>
                      {category[docLang]}
                    </option>
                  );
                })
              : null}
          </Form.Select>
        </Form.Group>
        <Form.Group
          style={{
            marginBottom: "20px",
          }}
        >
          <Form.Label>{t("dashboard.price.Please Select Price")}</Form.Label>
          <Form.Select
            name="categoryID"
            value={PriceId}
            onChange={(e) => setPriceId(e.target.value)}
          >
            <option>{t("dashboard.price.Please Select Price")}</option>
            {Price.length > 0
              ? Price.map((price, index) => {
                  return (
                    <option key={index} value={price._id}>
                      {price.name}
                    </option>
                  );
                })
              : null}
          </Form.Select>
        </Form.Group>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button variant="outline-warning" onClick={PostProductsPrices}>
          {t("dashboard.price.Post Products Prices")}
        </Button>
        <Button variant="warning" id="verify-btn">
          {t("dashboard.price.Export Excel File for Agents")}
        </Button>
      </div>
      <DataTable
        DataTable
        fixedHeader
        responsive
        subHeaderWrap
        highlightOnHover
        columns={columns}
        customStyles={customStyles}
        data={data?.data ?? []}
        noDataComponent={<p>{t("dashboard.price.Please Select Price")}</p>}
      />
    </>
  );
};

export default PriceProprties;
