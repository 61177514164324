import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const AddAppVersion = () => {
  const [values, setValues] = useState({
    platform: "",
    current_version: "",
    minimum_required_version: "",
    update_url: "",
    update_required: "",
  });
  // const [appByPlatform,setAppByPlatform]
  const handleInfoInputChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const { id } = useParams();
  const getAppById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    const response = await fetch(
      `https://www.api.setalkel.co/app-version/?platform=${id}`,
      requestOptions
    );
    const body = await response.json();
    console.log(body);
    setValues({
      platform: body.platform,
      current_version: body.current_version,
      minimum_required_version: body.minimum_required_version,
      update_required: body.update_required,
      update_url: body.update_url,
    });
    // console.log(ImgGallery.ImgGallery)
  }, [id]);
  useEffect(() => {
    id && getAppById();
  }, [getAppById]);
  // console.log(localStorage.getItem("admin-token"))
  const PostApp = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", `application/json`);
    const data = {
      ...values,
      update_required: values.update_required === "true" ? true : false,
    };
    var requestOptions = {
      method: id ? "PUT" : "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };
    const response = await fetch(
      `https://www.api.setalkel.co/app-version/${id ? id : ""}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      console.log(body);
    } else {
      navigate(-1);
      toast.success("App Version has been Added :)");
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <Row>
        {" "}
        <div className="crud-products">
          {" "}
          <div className="crud-title">
            {" "}
            <h1>{t("dashboard.appVersion.addAppVersion.title")}</h1>{" "}
          </div>{" "}
        </div>{" "}
        <Form>
          {" "}
          <div className="product-langs">
            {" "}
            <Form.Group>
              {" "}
              <Form.Label>{t("dashboard.appVersion.addAppVersion.platform")}</Form.Label>{" "}
              <Form.Select
                name="platform"
                value={values.platform}
                onChange={handleInfoInputChange}
                style={{ height: "fit-content" }}
              >
                {" "}
                <option value="">{t("dashboard.appVersion.addAppVersion.chooseState")}</option>{" "}
                <option value="Android">{t("dashboard.appVersion.addAppVersion.android")}</option>{" "}
                <option value="iOS">{t("dashboard.appVersion.addAppVersion.ios")}</option>{" "}
              </Form.Select>{" "}
            </Form.Group>{" "}
            <Form.Group>
              {" "}
              <Form.Label>{t("dashboard.appVersion.addAppVersion.currentVersion")}</Form.Label>{" "}
              <Form.Control
                name="current_version"
                label="current_version"
                value={values.current_version}
                onChange={handleInfoInputChange}
                placeholder={t("dashboard.appVersion.addAppVersion.currentVersion")}
              />{" "}
            </Form.Group>{" "}
            <Form.Group>
              {" "}
              <Form.Label>
                {t("dashboard.appVersion.addAppVersion.minimumRequiredVersion")}
              </Form.Label>{" "}
              <Form.Control
                name="minimum_required_version"
                label="minimum_required_version"
                value={values.minimum_required_version}
                onChange={handleInfoInputChange}
                placeholder={t("dashboard.appVersion.addAppVersion.minimumRequiredVersion")}
              />{" "}
            </Form.Group>{" "}
            <Form.Group>
              {" "}
              <Form.Label>{t("dashboard.appVersion.addAppVersion.updateRequired")}</Form.Label>{" "}
              <Form.Select
                name="update_required"
                value={values.update_required}
                onChange={handleInfoInputChange}
                style={{ height: "fit-content" }}
              >
                {" "}
                <option value="">{t("dashboard.appVersion.addAppVersion.choose")}</option>{" "}
                <option value={true}>{t("dashboard.appVersion.addAppVersion.yes")}</option>{" "}
                <option value={false}>{t("dashboard.appVersion.addAppVersion.no")}</option>{" "}
              </Form.Select>{" "}
            </Form.Group>{" "}
            <Form.Group>
              {" "}
              <Form.Label>{t("dashboard.appVersion.addAppVersion.updateUrl")}</Form.Label>{" "}
              <Form.Control
                name="update_url"
                label="update_url"
                value={values.update_url}
                onChange={handleInfoInputChange}
                placeholder={t("dashboard.appVersion.addAppVersion.updateUrl")}
              />{" "}
            </Form.Group>{" "}
          </div>{" "}
          <div>
            {" "}
            <Button onClick={PostApp} variant="warning">
              {" "}
              {id
                ? t("dashboard.appVersion.addAppVersion.buttonText").replace("Add", "Update")
                : t("dashboard.appVersion.addAppVersion.buttonText")}{" "}
            </Button>{" "}
          </div>{" "}
        </Form>{" "}
      </Row>{" "}
    </>
  );
};

export default AddAppVersion;
