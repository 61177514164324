import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next"; // استيراد دالة الترجمة

//css
import "./CRContactUs.css";
//bootstrap
import { Row, Button, Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import toast from "react-hot-toast";
import UnVerifiedAgentsApi from "../../Api/UnVerifiedAgentsApi";

const CRContactus = () => {
  const { t } = useTranslation(); // استخدام الترجمة
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactId] = useState("");
  const [deletedContacts, setDeletedContacts] = useState({
    ids: [],
  });
  const [DeleteContactShow, setDeleteContactShow] = useState(false);
  const handleClose = () => setDeleteContactShow(false);
  const handleShow = () => setDeleteContactShow(true);
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  const [generateExcelFile, setGenerateExecelFile] = useState();

  useEffect(() => {
    const fetchContacts = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/contactUs",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        console.log(body);
      } else {
        setContacts(body);
      }
    };
    fetchContacts();
  }, []);

  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/contactUs/${contactId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          toast.success(t("dashboard.contactUs.item_deleted"));
          handleClose();
        }
      })
      .catch((error) => {
        toast.error(error.response);
        console.log("error", error.response);
      });
  };

  const getFile = async (file) => {
    console.log(file);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("dashboard.contactUs.a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };

  const deleteContacts = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedContacts.ids.includes(contactID)) {
        setDeletedContacts({
          ...deletedContacts,
          ids: [...deletedContacts.ids, contactID],
        });
      }
    } else {
      setDeletedContacts({
        ids: deletedContacts.ids.filter((item) => item !== contactID),
      });
    }
  };

  const deleteMultipleContacts = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(deletedContacts);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      "https://www.api.setalkel.co/contactUs/collection",
      requestOptions
    );
    if (!response.ok) {
      alert(t("dashboard.contactUs.error_message"));
    } else {
      alert(t("dashboard.contactUs.contacts_deleted"));
      window.location.reload();
    }
  };

  useEffect(() => {
    if (deletedContacts.ids.length > 0) {
      document.getElementById("multipleDelete").style.visibility = "visible";
    } else {
      document.getElementById("multipleDelete").style.visibility = "hidden";
    }
  }, [deletedContacts.ids]);

  const columns = [
    { name: t("dashboard.contactUs.name"), selector: (row) => row.name },
    {
      name: t("dashboard.contactUs.company_name"),
      selector: (row) =>
        row.companyName ? row.companyName : t("dashboard.contactUs.no_company_name"),
    },
    {
      name: t("dashboard.contactUs.phone_number"),
      selector: (row) =>
        row.phoneNumber ? row.phoneNumber : t("dashboard.contactUs.no_phone_number"),
    },
    {
      name: t("dashboard.contactUs.website"),
      selector: (row) => (row.website ? row.website : t("dashboard.contactUs.no_website")),
    },
    {
      name: t("dashboard.contactUs.role"),
      selector: (row) => (
        <span>{row.role ? row.role : t("dashboard.contactUs.no_role")}</span>
      ),
    },
    {
      name: t("dashboard.contactUs.email"),
      selector: (row) => (
        <span>{row.email ? row.email : t("dashboard.contactUs.no_email")}</span>
      ),
    },
    {
      name: t("dashboard.contactUs.country"),
      selector: (row) => (
        <span>{row.country ? row.country : t("dashboard.contactUs.no_country")}</span>
      ),
    },
    {
      name: t("dashboard.contactUs.city"),
      selector: (row) => (
        <span>{row.city ? row.city : t("dashboard.contactUs.no_city")}</span>
      ),
    },
    {
      name: t("dashboard.contactUs.trade_activity"),
      selector: (row) => (
        <span>
          {row.tradeActivity ? row.tradeActivity : t("dashboard.contactUs.no_trade_activity")}
        </span>
      ),
    },
    {
      name: t("dashboard.contactUs.visitor_interests"),
      selector: (row) => (
        <span>
          {row.visitorInterests?.length > 0
            ? row.visitorInterests.join(",")
            : t("dashboard.contactUs.no_visitor_interests")}
        </span>
      ),
      width: "700px",
    },
    {
      name: t("dashboard.contactUs.special_requirements"),
      selector: (row) => (
        <Form.Control
          className="textarea"
          as="textarea"
          disabled
          row={10}
          cols={10}
          width="10rem"
        >
          {row.specialRequirements
            ? row.specialRequirements
            : t("dashboard.contactUs.no_special_requirements")}
        </Form.Control>
      ),
    },
    {
      name: t("dashboard.contactUs.visitor_remarks"),
      selector: (row) => (
        <span>
          {row.visitorRemarks ? row.visitorRemarks : t("dashboard.contactUs.no_visitor_remarks")}
        </span>
      ),
    },
    {
      name: t("dashboard.contactUs.visitor_summary"),
      selector: (row) => (
        <span>
          {row.visitSummary ? row.visitSummary : t("dashboard.contactUs.no_visitor_summary")}
        </span>
      ),
    },
    {
      name: t("dashboard.contactUs.files"),
      selector: (row) => (
        <div>
          {row.files.length > 0
            ? row.files.map((file) => (
                <Button
                  variant="outline-warning"
                  onClick={() => getFile(file)}
                >
                  {file.slice(16)}
                </Button>
              ))
            : t("dashboard.contactUs.no_files")}
        </div>
      ),
    },
    {
      name: t("dashboard.contactUs.received_at"),
      selector: (row) => (
        <span>
          {row.createdAt ? row.createdAt : t("dashboard.contactUs.no_received_date")}
        </span>
      ),
    },
    {
      name: t("dashboard.contactUs.delete"),
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleShow();
              setContactId(row._id);
            }}
          >
            {t("dashboard.contactUs.delete")}
          </Button>
        </>
      ),
    },
    {
      name: t("dashboard.contactUs.m_delete"),
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteContacts(e, row._id)}
        />
      ),
    },
  ];

  const { data: Excel } =
    UnVerifiedAgentsApi.GenerateExelFileQueryForContactUs();

  const getFileExcel = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("dashboard.contactUs.a");
      link.href = url;
      link.setAttribute("download", `${generateExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <section className="cms-contactus" style={{ overflow: "hidden" }}>
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%", textAlign: "center" }}>
          <h1>{t("dashboard.contactUs.contacts")}</h1>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {!generateExcelFile ? (
          <Button
            variant="warning"
            id="verify-btn"
            onClick={() => setGenerateExecelFile(Excel)}
          >
            {t("dashboard.contactUs.export_excel")}
          </Button>
        ) : (
          <Button variant="success" id="verify-btn" onClick={getFileExcel}>
            {t("dashboard.contactUs.download_excel")}
          </Button>
        )}
        <Button variant="danger" id="verify-btn">
          {t("dashboard.contactUs.import_excel")}
        </Button>

        <Button
          id="multipleDelete"
          variant="outline-danger"
          onClick={() => handleMultipleShow()}
        >
          {t("dashboard.contactUs.multiple_delete")}
        </Button>
      </div>
      <Row>
        <DataTable
          fixedHeaderScrollHeight="600px"
          fixedHeader
          responsive
          subHeaderWrap
          columns={columns}
          data={contacts}
          customStyles={customStyles}
        />
      </Row>
      <div>
        <br />

        <Modal
          size="lg"
          show={MultipleDeleteModalShow}
          onHide={() => setMultipleDeleteModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("dashboard.contactUs.delete_multiple_contacts")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("dashboard.contactUs.delete_confirmation")}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setMultipleDeleteModal(false)}
            >
              {t("dashboard.contactUs.cancel")}
            </Button>
            <Button variant="danger" onClick={deleteMultipleContacts}>
              {t("dashboard.contactUs.delete")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={DeleteContactShow}
          onHide={() => setDeleteContactShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("dashboard.contactUs.delete_contact")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("dashboard.contactUs.delete_confirmation_single")}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("dashboard.contactUs.cancel")}
            </Button>
            <Button variant="danger" onClick={onDelete}>
              {t("dashboard.contactUs.delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
};

export default CRContactus;
