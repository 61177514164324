import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Row, Form, Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AddBites = () => {
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [ImgGallery, setImgGallery] = useState({
    ImgGallery: [],
  });
  const [Videofile, setVideofile] = useState({
    Videofile: null,
  });
  const [values, setValues] = useState({
    title: {
      en: "",
      ar: "",
      fr: "",
      de: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },

    prep: [
      {
        en: "",
        ar: "",
        fr: "",
        de: "",
        ru: "",
        cn: "",
        tur: "",
        ir: "",
        dk: "",
      },
    ],
    ingredient: [
      {
        en: "",
        ar: "",
        fr: "",
        de: "",
        ru: "",
        cn: "",
        tur: "",
        ir: "",
        dk: "",
      },
    ],
  });
  const handleVideoChange = (e) => {
    setVideofile({
      Videofile: e.target.files[0],
    });
  };
  //=======================Title Input Handeling===============
  const handleTitleChange = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, title: { ...values.title, [name]: value } });
  };
  //=======================Title Input Handeling===============
  const handlePrepChange = (e) => {
    const { name, value } = e.target;
    const element = values.prep[0];
    element[name] = value;
    setValues({ ...values, prep: [element] });
  };
  //=======================Title Input Handeling===============
  const handleIngredientChange = (e) => {
    const { name, value } = e.target;
    const element = values.ingredient[0];
    element[name] = value;
    setValues({ ...values, ingredient: [element] });
  };

  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //==================Image Gallery Handeling===============
  const handleGalleryChange = (e) => {
    const newImages = Array.from(e.target.files);

    setImgGallery((prevState) => {
      const allImages = [...prevState.ImgGallery, ...newImages];
      return {
        ImgGallery: allImages,
      };
    });
  };
  //=======
  //=====================================Post Activity Video====================
  const PostBiteVideo = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      fd.append("file", Videofile.Videofile, Videofile.Videofile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/kitchen/video/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //=====================================Post Bite Gallery====================
  const PostBiteGallery = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      for (let i = 0; i < ImgGallery.ImgGallery.length; i++) {
        fd.append(
          "images",
          ImgGallery.ImgGallery[i],
          ImgGallery.ImgGallery[i].name
        );
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/kitchen/${id}/gallery`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //=====================================Post Bite Image====================
  const PostBiteImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/kitchen/image/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //=====================================Post Bite ====================
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const PostBite = async (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = values;
    let NewTitle = raw.title;
    let NewPrep = raw.prep[0];
    let NewIngredient = raw.ingredient[0];
    let NewRaw = raw;

    Object.keys(NewTitle).forEach((key) => {
      if (NewTitle[key] === "" || NewTitle[key] === "undefined") {
        delete NewTitle[key];
      }
    });
    Object.keys(NewPrep).forEach((key) => {
      if (NewPrep[key] === "" || NewPrep[key] === "undefined") {
        delete NewPrep[key];
      }
    });
    Object.keys(NewIngredient).forEach((key) => {
      if (NewIngredient[key] === "" || NewIngredient[key] === "undefined") {
        delete NewIngredient[key];
      }
    });
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "" || NewRaw[key] === "undefined") {
        delete NewRaw[key];
      }
    });

    const raw2 = JSON.stringify(NewRaw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/kitchen",
      requestOptions
    );
    const body = await response.json();

    if (!response.ok) {
      alert(body.message);
      alert("dashboard.Bites.Bite Not Added !!!");
      console.log(body);
    } else {
      if (ImgGallery.ImgGallery.length > 0) {
        await PostBiteGallery(body._id);
      }
      if (Imgfile.Imgfile) {
        await PostBiteImg(body._id);
      }
      if (Videofile.Videofile) {
        await PostBiteVideo(body._id);
      }
      toast.success("Bites Has Been Added");
      navigate("/dashboard/crbites");
      setValues({
        title: {
          en: "",
          ar: "",
          fr: "",
          de: "",
          ru: "",
          cn: "",
          tur: "",
          ir: "",
          dk: "",
        },

        prep: [
          {
            en: "",
            ar: "",
            fr: "",
            de: "",
            ru: "",
            cn: "",
            tur: "",
            ir: "",
            dk: "",
          },
        ],
        ingredient: [
          {
            en: "",
            ar: "",
            fr: "",
            de: "",
            ru: "",
            cn: "",
            tur: "",
            ir: "",
            dk: "",
          },
        ],
      });
    }
  };
  const { t } = useTranslation();
  return (
    <div className="crud-kitchen">
      <Form className="kitchen-form">
        <Form.Group>
          <h4>{t("dashboard.Bites.bitesLangs")}</h4>
          <div className="kitchen-langs">
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.biteNameEnglish")}
              </Form.Label>
              <Form.Control
                type="text"
                name="en"
                label="category"
                value={values.title.en}
                onChange={handleTitleChange}
                placeholder={t("dashboard.Bites.biteNamePlaceholderEnglish")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInAr">
                {t("dashboard.Bites.biteNameArabic")}
              </Form.Label>
              <Form.Control
                type="text"
                name="ar"
                label="category"
                value={values.title.ar}
                onChange={handleTitleChange}
                placeholder={t("dashboard.Bites.biteNamePlaceholderArabic")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInFr">
                {t("dashboard.Bites.biteNameFrench")}
              </Form.Label>
              <Form.Control
                name="fr"
                label="category"
                value={values.title.fr}
                onChange={handleTitleChange}
                placeholder={t("dashboard.Bites.biteNamePlaceholderFrench")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInRu">
                {t("dashboard.Bites.biteNameRussian")}
              </Form.Label>
              <Form.Control
                name="ru"
                label="category"
                value={values.title.ru}
                onChange={handleTitleChange}
                placeholder={t("dashboard.Bites.biteNamePlaceholderRussian")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInDe">
                {t("dashboard.Bites.biteNameGerman")}
              </Form.Label>
              <Form.Control
                name="de"
                label="category"
                value={values.title.de}
                onChange={handleTitleChange}
                placeholder={t("dashboard.Bites.biteNamePlaceholderGerman")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInCn">
                {t("dashboard.Bites.biteNameChinese")}
              </Form.Label>
              <Form.Control
                name="cn"
                label="category"
                value={values.title.cn}
                onChange={handleTitleChange}
                placeholder={t("dashboard.Bites.biteNamePlaceholderChinese")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInTur">
                {t("dashboard.Bites.biteNameTurkish")}
              </Form.Label>
              <Form.Control
                name="tur"
                label="category"
                value={values.title.tur}
                onChange={handleTitleChange}
                placeholder={t("dashboard.Bites.biteNamePlaceholderTurkish")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInIr">
                {t("dashboard.Bites.biteNamePersian")}
              </Form.Label>
              <Form.Control
                name="ir"
                label="category"
                value={values.title.ir}
                onChange={handleTitleChange}
                placeholder={t("dashboard.Bites.biteNamePlaceholderPersian")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInDk">
                {t("dashboard.Bites.biteNameDanish")}
              </Form.Label>
              <Form.Control
                name="dk"
                label="category"
                value={values.title.dk}
                onChange={handleTitleChange}
                placeholder={t("dashboard.Bites.biteNamePlaceholderDanish")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="KitchenImg">{t("dashboard.Bites.biteImage")}</Form.Label>
              <div className="d-flex flex-column ">
                <Form.Control
                  type="file"
                  name="image"
                  id="productImg"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  style={{
                    height: "fit-content",
                  }}
                />
                {Imgfile.Imgfile && (
                  <div
                    className="position-relative"
                    style={{
                      width: "fit-content",
                    }}
                  >
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={URL.createObjectURL(Imgfile.Imgfile)}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setImgfile({
                          Imgfile: null,
                        });
                        if (fileInputRef.current) {
                          fileInputRef.current.value = ""; // Clear input using useRef
                        }
                      }}
                      style={{
                        position: "absolute",
                        top: "-18px",
                        right: "-14px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      &times;
                    </button>
                  </div>
                )}
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("dashboard.Bites.biteImageGallery")}</Form.Label>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Form.Control
                  type="file"
                  name="images"
                  multiple
                  id="productImg"
                  onChange={handleGalleryChange}
                />
                <div style={{ display: "flex", gap: "5px" }}>
                  {ImgGallery.ImgGallery.map((image, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={URL.createObjectURL(image)}
                        alt={`Gallery Img ${index + 1}`}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setImgGallery((prevState) => ({
                            ImgGallery: prevState.ImgGallery.filter(
                              (_, indexIM) => indexIM !== index
                            ),
                          }));
                        }}
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-11px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("dashboard.Bites.biteVideo")}</Form.Label>
              <Form.Control
                type="file"
                name="video"
                onChange={handleVideoChange}
              />
            </Form.Group>
          </div>
          {/* =================================Start Bite Preperations================================= */}
          <br />
          <h4>{t("dashboard.Bites.Bite Preparations")}</h4>
          <div className="kitchen-prep">
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.Bite Preparation In English")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="en"
                label="category"
                onChange={handlePrepChange}
                placeholder={t("dashboard.Bites.Bite Preparation In English")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.Bite Preparation In Arabic")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="ar"
                label="category"
                onChange={handlePrepChange}
                placeholder={t("dashboard.Bites.Bite Preparation In Arabic")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.Bite Preparation In French")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="fr"
                label="category"
                onChange={handlePrepChange}
                placeholder={t("dashboard.Bites.Bite Preparation In French")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.Bite Preparation In Russian")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="ru"
                label="category"
                onChange={handlePrepChange}
                placeholder={t("dashboard.Bites.Bite Preparation In Russian")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.Bite Preparation In German")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="de"
                label="category"
                onChange={handlePrepChange}
                placeholder={t("dashboard.Bites.Bite Preparation In German")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.Bite Preparation In China")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="cn"
                label="category"
                onChange={handlePrepChange}
                placeholder={t("dashboard.Bites.Bite Preparation In China")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.Bite Preparation In Turkish")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="tur"
                label="category"
                onChange={handlePrepChange}
                placeholder={t("dashboard.Bites.Bite Preparation In Turkish")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.Bite Preparation In Persian")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="ir"
                label="category"
                onChange={handlePrepChange}
                placeholder={t("dashboard.Bites.Bite Preparation In Persian")}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="ProductNameInEn">
                {t("dashboard.Bites.Bite Preparation In Danish")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="dk"
                label="category"
                onChange={handlePrepChange}
                placeholder={t("dashboard.Bites.Bite Preparation In Danish")}
              />
            </Form.Group>
          </div>
          {/* =================================End Bite Preperations================================= */}
          <div>
            <h4>{t("dashboard.Bites.Bite Ingredients")}</h4>
            <div className="kitchen-ingredient">
              <Form.Group>
                <Form.Label htmlFor="ProductNameInEn">
                  {t("dashboard.Bites.Bite Ingredient In English")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="en"
                  label="category"
                  onChange={handleIngredientChange}
                  placeholder={t("dashboard.Bites.Bite Ingredient In English")}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="ProductNameInEn">
                  {t("dashboard.Bites.Bite Ingredient In Arabic")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="ar"
                  label="category"
                  onChange={handleIngredientChange}
                  placeholder={t("dashboard.Bites.Bite Ingredient In Arabic")}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="ProductNameInEn">
                  {t("dashboard.Bites.Bite Ingredient In French")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="fr"
                  label="category"
                  onChange={handleIngredientChange}
                  placeholder={t("dashboard.Bites.Bite Ingredient In French")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="ProductNameInEn">
                  {t("dashboard.Bites.Bite Ingredient In Russian")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="ru"
                  label="category"
                  onChange={handleIngredientChange}
                  placeholder={t("dashboard.Bites.Bite Ingredient In Russian")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="ProductNameInEn">
                  {t("dashboard.Bites.Bite Ingredient In German")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="de"
                  label="category"
                  onChange={handleIngredientChange}
                  placeholder={t("dashboard.Bites.Bite Ingredient In German")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="ProductNameInEn">
                  {t("dashboard.Bites.Bite Ingredient In China")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="cn"
                  label="category"
                  onChange={handleIngredientChange}
                  placeholder={t("dashboard.Bites.Bite Ingredient In China")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="ProductNameInEn">
                  {t("dashboard.Bites.Bite Ingredient In Turkish")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="tur"
                  label="category"
                  onChange={handleIngredientChange}
                  placeholder={t("dashboard.Bites.Bite Ingredient In Turkish")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="ProductNameInEn">
                  {t("dashboard.Bites.Bite Ingredient In Persian")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="ir"
                  label="category"
                  onChange={handleIngredientChange}
                  placeholder={t("dashboard.Bites.Bite Ingredient In Persian")}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="ProductNameInEn">
                  {t("dashboard.Bites.Bite Ingredient In Danish")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="dk"
                  label="category"
                  onChange={handleIngredientChange}
                  placeholder={t("dashboard.Bites.Bite Ingredient In Danish")}
                />
              </Form.Group>
            </div>
            <div className="add-bites">
              <Button
                type="submit"
                onClick={(e) => PostBite(e)}
                variant="warning"
              >
                {t("dashboard.Bites.Add Bite")}
              </Button>
            </div>
          </div>
        </Form.Group>
      </Form>
    </div>
  );
};

export default AddBites;
