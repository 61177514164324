import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Notification from "../../../Components/Notification/Notification";

import DataTable from "react-data-table-component";

import { useDispatch } from "react-redux";
import {
  adminCheckIsEditing,
  adminUpdateSuggestion,
  adminUpdateSuggestionShipment,
  shipmentAgentId,
} from "../../../redux/Admin/adminCartSlice";
import {
  adminAddContainer,
  adminAddContainerId,
  adminAddDeliveryMethod,
  adminAddExportCountryId,
  adminAddImportCountryId,
  adminAddImportStateId,
} from "../../../redux/Admin/adminShipmentStepperSlice";

import { Button, Form, Modal, Row } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { useTranslation } from "react-i18next";
var deleteKey = require("key-del");
const SuggestionShipments = () => {
  const [suggestionShippments, setSuggestionShippments] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [NotesShow, setNotesShow] = useState(false);
  const handleClose = () => setNotesShow(false);
  const handleShow = () => setNotesShow(true);

  const [
    DeleteSuggestionShipmentModalshow,
    setDeleteSuggestionShipmentModalShow,
  ] = useState(false);

  const handleDeleteSuggestionShipmentModalClose = () =>
    setDeleteSuggestionShipmentModalShow(false);
  const handleDeleteSuggestionShipmentModalShow = () =>
    setDeleteSuggestionShipmentModalShow(true);

  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });
  const [shipmentId, setShipmentId] = useState("");
  const [freezeShipment, setFreezeShipment] = useState({
    freezed: false,
  });
  //========================================Handle Freeze Shipment===========================
  const handleFreezeShipment = (e, shipmentId) => {
    setShipmentId(shipmentId);
    setFreezeShipment({
      freezed: e.target.value,
    });
  };
  //============================get Suggestion========================

  useEffect(() => {
    const getSuggestions = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/?status=suggestion",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setSuggestionShippments([]);
        console.log(body);
      } else {
        setSuggestionShippments(body);
      }
    };
    getSuggestions();
  }, []);
  //========================================freeze Shipment ==============================
  const PostFreezeShipment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      freezed: freezeShipment.freezed,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/freezed/${shipmentId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("dashboard.shipment.Shipment Status Not Changed");
      console.log(body);
    } else {
      alert("dashboard.shipment.Shipment Status Has Been Changed");
      window.location.reload();
    }
  };
  //=========================================download File ===============================================
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("dashboard.shipment.a");
        link.href = url;
        link.setAttribute("download", `${file}`);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };

  //=======================Handle Edit Suggestion=====================

  const handleEdit = async (suggestion) => {
    const adminUpdatedCartItems = [];
    const adminUpdatedShipmentItems = [];
    var result;
    suggestion.order.items.forEach((item) => {
      console.log(item);
      item.product.cartQuantity = item.count;
      adminUpdatedCartItems.push(item.product);
    });
    for (let i = 0; i < adminUpdatedCartItems.length; i++) {
      const element = adminUpdatedCartItems[i];
      element.count = element.cartQuantity;
      element.product = element._id;
      element.note = "";
      result = deleteKey(element, [
        "addedBy",
        "additionalImage",
        "cat",
        "countInBox",
        "cartQuantity",
        "countryOrigin",
        "createdAt",
        "hidden",
        "img",
        "materialProperty",
        "package",
        "packageGrossWeight",
        "peiceQty",
        "stockOff",
        "subCat",
        "supplierName",
        "unitForPackage",
        "updatedAt",
        "volume",
        "is_new",
        "updatedBy",
        "_id",
      ]);
      adminUpdatedShipmentItems.push(result);
    }

    localStorage.setItem(
      "adminUpdatedCartItems",
      JSON.stringify(adminUpdatedCartItems)
    );
    localStorage.setItem(
      "adminUpdatedShipmentItems",
      JSON.stringify(adminUpdatedShipmentItems)
    );
    localStorage.setItem("container", JSON.stringify(suggestion.containerInfo));
    localStorage.setItem("exportCountryId", suggestion.exportCountry._id);
    localStorage.setItem("importCountryId", suggestion.importCountry._id);
    localStorage.setItem("importStateId", suggestion.state._id);
    localStorage.setItem("deliveryMethod", suggestion.deliveryMethod._id);
    localStorage.setItem("containerId", suggestion.containerInfo._id);
    localStorage.setItem("adminUpdatedSuggestionId", suggestion._id);
    localStorage.setItem("adminUpdatedSuggestionAgentId", suggestion.agent._id);
    dispatch(adminCheckIsEditing(true));
    dispatch(adminUpdateSuggestion(adminUpdatedCartItems));
    dispatch(adminUpdateSuggestionShipment(adminUpdatedShipmentItems));
    dispatch(adminAddExportCountryId(suggestion.exportCountry._id));
    dispatch(adminAddImportCountryId(suggestion.importCountry._id));
    dispatch(adminAddImportStateId(suggestion.state._id));
    dispatch(adminAddDeliveryMethod(suggestion.deliveryMethod._id));
    dispatch(adminAddContainerId(suggestion.containerInfo.container._id));
    dispatch(adminAddContainer(suggestion.containerInfo));
    dispatch(shipmentAgentId(suggestion.agent._id));
    navigate("/dashboard/adminCart");
  };

  //============================Delete Shippment===============================

  const DeleteShippment = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/admin/${shipmentId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("dashboard.shipment.Shippment Not Deleted !!!");
      console.log(response);
    } else {
      alert("dashboard.shipment.Shippment Deleted !!");
      window.location.reload();
    }
  };
  //============================Delete Mutliple Product=====================

  const deleteShipments = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(contactID)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, contactID],
        });
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const docLang = document.querySelector("html").lang

  const deleteMultipleShipments = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/shipment/admin/collection",
      requestOptions
    );
    if (!response.ok) {
      alert("dashboard.shipment.There Is An Error ");
      console.log(response);
    } else {
      alert("dashboard.shipment.Shipments Have Been Deleted !!");
      window.location.reload();
    }
  };
  const { t } = useTranslation();
  const columns = [
    {
      name: t("dashboard.shipment.id"),
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: t("dashboard.shipment.status"),
      selector: (row) => row.status,
    },
    {
      name: t("dashboard.shipment.canceled"),
      selector: (row) => (row.isCanceled === true ? t("dashboard.shipment.yes") : t("dashboard.shipment.no")),
    },
    {
      name: t("dashboard.shipment.date"),
      selector: (row) => row.order.createdAt,
    },
    {
      name: t("dashboard.shipment.container"),
      selector: (row) => row.containerInfo.container.usualName,
    },
    {
      name: t("dashboard.shipment.agent"),
      selector: (row) => row.agent.name,
    },
    {
      name: t("dashboard.shipment.exportCountry"),
      selector: (row) => row.exportCountry.countryName[docLang],
    },
    {
      name: t("dashboard.shipment.importCountry"),
      selector: (row) =>
        row.importCountry.countryName[docLang] + " / " + row.state.stateName[docLang],
    },
    {
      name: t("dashboard.shipment.notePerProducts"),
      selector: (row) => (
        <div
          style={{
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {row?.order?.items?.map((d) => (
            <>
              {d.note ? (
                <div>
                  {" "}
                  <span>{d.product.subCat[docLang]}</span> : <span>{d.note}</span>
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
      ),
      width: "200px",
    },
    {
      name: t("dashboard.shipment.adminFiles"),
      selector: (row) => (
        <ul className="p-0">
          {row.adminFiles.map((file, index) => (
            <li key={index}>
              <Button
                variant="warning"
                onClick={() => getFile(file)}
                style={{
                  wordBreak: "break-all",
                }}
              >
                {t("dashboard.shipment.adminFile", { index })}
              </Button>
            </li>
          ))}
        </ul>
      ),
    },
    {
      name: t("dashboard.shipment.shipmentFiles"),
      selector: (row) => (
        <ul className="p-0">
          {Object.entries(row.shipmentFiles).map((file, index) => (
            <li key={index}>
              <Button
                variant="warning"
                onClick={() => getFile(file[1])}
                style={{
                  wordBreak: "break-all",
                }}
              >
                {file[0].slice(0, 16)}
              </Button>
            </li>
          ))}
        </ul>
      ),
    },
    {
      name: t("dashboard.shipment.freeze"),
      selector: (row) => (
        <Form.Select
          name="freeze-shipment"
          value={freezeShipment}
          onChange={(e) => handleFreezeShipment(e, row._id)}
        >
          <option>{t("dashboard.shipment.freezeShipment")}</option>
          <option value="true">{t("dashboard.shipment.freeze")}</option>
          <option value="false">{t("dashboard.shipment.defreeze")}</option>
        </Form.Select>
      ),
    },
    {
      name: t("dashboard.shipment.edit"),
      selector: (row) => (
        <Button variant="warning" onClick={() => handleEdit(row)}>
          {t("dashboard.shipment.edit")}
        </Button>
      ),
    },
    {
      name: t("dashboard.shipment.notes"),
      selector: (row) => (
        <>
          <Button variant="warning" onClick={handleShow}>
            {t("dashboard.shipment.notes")}
          </Button>
          <Modal show={NotesShow} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{t("dashboard.shipment.shipmentNotes")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table>
                <TableHeader>
                  <TableHeaderCell>{t("dashboard.shipment.id")}</TableHeaderCell>
                  <TableHeaderCell>{t("dashboard.shipment.productName")}</TableHeaderCell>
                  <TableHeaderCell>{t("dashboard.shipment.productGrossWeight")}</TableHeaderCell>
                  <TableHeaderCell>{t("dashboard.shipment.note")}</TableHeaderCell>
                </TableHeader>
                <TableBody>
                  {row.order.items.length > 0
                    ? row.order.items.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.product.subCat[docLang]}</TableCell>
                          <TableCell>
                            {item.product.peiceQty.grossWeightForPeice}
                          </TableCell>
                          <TableCell>
                            <Form.Control
                              as="textarea"
                              value={item.note}
                              disabled
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("dashboard.shipment.close")}
              </Button>
              <Button variant="warning" onClick={handleClose}>
                {t("dashboard.shipment.saveChanges")}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
    {
      name: t("dashboard.shipment.delete"),
      selector: (row) => (
        <>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteSuggestionShipmentModalShow();
              setShipmentId(row._id);
            }}
          >
            {t("dashboard.shipment.delete")}
          </Button>
  
          <Modal
            show={DeleteSuggestionShipmentModalshow}
            onHide={handleDeleteSuggestionShipmentModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {t("dashboard.shipment.areYouSureYouWantToDeleteThisShipment")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleDeleteSuggestionShipmentModalClose}
              >
                {t("dashboard.shipment.close")}
              </Button>
              <Button variant="danger" onClick={() => DeleteShippment()}>
                {t("dashboard.shipment.deleteShipment")}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ),
    },
    {
      name: t("dashboard.shipment.mDelete"),
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedShipments"
          id="deletedShipments"
          onChange={(e) => deleteShipments(e, row._id)}
        />
      ),
    },
  ];
  
  return (
    <section className="suggestion-shipment">
      <Notification />
      <Row>
        <div className="shippments">
          <Row>
            <div className="crud-title">
              <h1>{t("dashboard.shipment.helloCRUDShippments")}</h1>
            </div>
          </Row>
          <div className="details-table">
            <h2>{t("dashboard.shipment.suggestionShipments")}</h2>
            <hr />
            <Row>
              {deletedProducts.ids.length > 0 ? (
                <Button
                  id="multipleDelete"
                  variant="danger"
                  onClick={() => deleteMultipleShipments()}
                >
                  {t("dashboard.shipment.multipleDelete")}
                </Button>
              ) : null}
              {freezeShipment.freezed ? (
                <Button
                  id="freezedShipment"
                  variant="dark"
                  onClick={() => PostFreezeShipment()}
                >
                  {t("dashboard.shipment.changeShipmentStatus")}
                </Button>
              ) : null}
            </Row>
            <Row>
              <DataTable
                fixedHeader
                pagination
                responsive
                subHeaderWrap
                columns={columns}
                data={suggestionShippments}
                customStyles={customStyles}
              />
            </Row>
          </div>
        </div>
      </Row>
    </section>
  );
};

export default SuggestionShipments;
