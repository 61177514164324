import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Form, Row } from "react-bootstrap";
//css
import "./UpdateDeliveryMethod.css";
import { useTranslation } from "react-i18next";
const UpdateDeliveryMethod = () => {
  const params = useParams();

  const [methodInfo, setmethodInfo] = useState({
    methodId: "",
    methodName: "",
  });
  const navigate = useNavigate();
  //===================================handleMethodName===========================
  // const handleMethodName = (e) => {
  //   const { name, value } = e.target;
  //   setMethodName({ ...methodName, [name]: value });
  // };
  //===========handle Info ===============
  const handleInfo = (e) => {
    const { name, value } = e.target;
    setmethodInfo({ ...methodInfo, [name]: value });
  };
  //================================================getMethodById================================
  const getMethodById = useCallback(async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/delivery-method/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    setmethodInfo({
      methodId: body._id,
      methodName: body.methodName,
    });
  }, [params.Id]);
  useEffect(() => {
    getMethodById();
  }, [getMethodById]);

  //=====================Update Delivery Method==================
  const updateDeliveryMethod = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      methodName: methodInfo.methodName,
    });
    console.log(raw);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      `https://www.api.setalkel.co/delivery-method/${methodInfo.methodId}`,
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      alert("Delivery Method Not Updated !!!!!");
      console.log(body);
    } else {
      alert("Delivery Method Updated Succesfully");
      navigate("/dashboard/crdeliverymethod");
    }
  };
  //=====================Cancel=======================
  const cancel = () => {
    navigate("/dashboard/crdeliverymethod");
  };
  const { t } = useTranslation();
  return (
    <section className="cms-update-delivery-method">
      {" "}
      <Row>
        {" "}
        <div className="crud-title">
          {" "}
          <h1>{t("dashboard.deliveryMethod.updateDeliveryMethod.deliveryMethodTitle")}</h1>{" "}
        </div>{" "}
        <div className="create-delivery-method">
          {" "}
          <Form>
            {" "}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {" "}
              <Form.Label>
                {t("dashboard.deliveryMethod.updateDeliveryMethod.methodNameLabel")}
              </Form.Label>{" "}
              <Form.Check
                type="radio"
                name="methodName"
                label={t("dashboard.deliveryMethod.updateDeliveryMethod.exw")}
                id="1"
                value={"EXW"}
                onChange={(e) => handleInfo(e)}
              />{" "}
              <Form.Check
                type="radio"
                name="methodName"
                label={t("dashboard.deliveryMethod.updateDeliveryMethod.cif")}
                id="2"
                value={"CIF"}
                onChange={(e) => handleInfo(e)}
              />{" "}
            </Form.Group>{" "}
            <Button variant="warning" onClick={updateDeliveryMethod}>
              {" "}
              {t("dashboard.deliveryMethod.updateDeliveryMethod.updateDeliveryMethod")}{" "}
            </Button>{" "}
            <Button variant="dark" onClick={cancel}>
              {" "}
              {t("dashboard.deliveryMethod.updateDeliveryMethod.cancel")}{" "}
            </Button>{" "}
          </Form>{" "}
        </div>{" "}
      </Row>{" "}
    </section>
  );
};

export default UpdateDeliveryMethod;
