import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  Modal,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { customStyles } from "../../../constants/DataTableCustomeStyles";
import "./CRExportCountry.css";
import toast from "react-hot-toast";
import UnVerifiedAgentsApi from "../../../Api/UnVerifiedAgentsApi";
import { useTranslation } from "react-i18next";

const CRCountries = () => {
  const docLang = document.querySelector("html").lang

  const [countries, setCountries] = useState([]);

  const [
    allExportCountriesWithCategories,
    setAllExportCountriesWithCategories,
  ] = useState([]);

  const [exportCountryId, setExportCountryId] = useState("");

  const [deleteCountriesCollection, setDeleteCountriesCollection] = useState({
    countries: [],
  });

  const [DeleteExportCountryModalShow, setDeleteExportCountryModalShow] =
    useState(false);

  const handleClose = () => setDeleteExportCountryModalShow(false);
  const handleShow = () => setDeleteExportCountryModalShow(true);

  //===================================Mutliple Delete Offers====================================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);

  //==============Get All Export Countries With Thier Categories==================
  useEffect(() => {
    const getAllExportCountriesWithCategories = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/export-country/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setAllExportCountriesWithCategories([]);
        console.log(body);
      } else {
        setAllExportCountriesWithCategories(body);
      }
    };
    getAllExportCountriesWithCategories();
  }, []);

  //==========================Delete Export Country==============================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/export-country/${exportCountryId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          toast.success("item deleted");
          handleClose();
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  //============================Delete Mutliple Export Countries===================
  const deleteExportCountriesCollection = (e, countryId) => {
    if (e.target.checked) {
      if (!deleteCountriesCollection.countries.includes(countryId)) {
        setDeleteCountriesCollection({
          ...deleteCountriesCollection,
          countries: [...deleteCountriesCollection.countries, countryId],
        });
      }
    } else {
      setDeleteCountriesCollection({
        countries: deleteCountriesCollection.countries.filter(
          (item) => item !== countryId
        ),
      });
    }
  };

  const deleteCollectionOfExportCountries = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");
      if (deleteCountriesCollection.countries.length > 0) {
        var raw = JSON.stringify({
          countries: deleteCountriesCollection.countries,
        });

        var requestOptions = {
          method: "DELETE",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          "https://www.api.setalkel.co/export-country/",
          requestOptions
        );
        if (!response.ok) {
          alert("dashboard.country.There Is An Error ");
          console.log(response);
        } else {
          alert("dashboard.country.Countries Have Been Deleted !!");
          window.location.reload();
        }
      } else {
        alert("dashboard.country.Please Select Multiple Countries To Delete");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //=======================Toggle Show Hide Delete Btn==========================
  // useEffect(() => {
  //   if (deleteCountriesCollection.countries.length > 0) {
  //     document.getElementById("multipleDelete").style.visibility = "visible";
  //   } else {
  //     document.getElementById("multipleDelete").style.visibility = "hidden";
  //   }
  // }, [deleteCountriesCollection.countries]);

  const navigate = useNavigate();
  const [generateExcelFile, setGenerateExecelFile] = useState();
  const { data: Excel } =
    UnVerifiedAgentsApi.GenerateExelFileQueryForExportCountry();
  const getFileExcel = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co${generateExcelFile.pathFile}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("dashboard.country.a");
      link.href = url;
      link.setAttribute("download", `${generateExcelFile.pathFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const { t } = useTranslation()
  const columns = [
    {
      name: t("dashboard.country.exportCountry.countryCode"),
      selector: (row) => <span>{row?.countryCode}</span>,
    },
    {
      name: t("dashboard.country.exportCountry.image"),
      selector: (row) => (
        <span>
          {" "}
          <img
            src={"https://www.api.setalkel.co" + row?.img}
            alt="exportCountryImg"
            width="70px"
            height="70px"
          />{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.country.exportCountry.englishCountryName"),
      selector: (row) => <span>{row?.countryName[docLang]}</span>,
    },
  
    {
      name: t("dashboard.country.exportCountry.categories"),
      selector: (row) => (
        <span className="">
          {" "}
          <ul className="p-0">
            {" "}
            {row.categories.length > 0
              ? row.categories?.map((category, index) => {
                  return (
                    <li className="">
                      {" "}
                      <span key={index}>{category?.cat[docLang]}</span>{" "}
                    </li>
                  );
                })
              : null}{" "}
          </ul>{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.country.exportCountry.showCategoriesAndProducts"),
      selector: (row) => (
        <Link
          to={`/dashboard/crexportcountries/showdetails/${row._id}`}
          state={row?.categories}
        >
          {" "}
          <Button variant="dark">
            {t("dashboard.country.exportCountry.categories")} &{" "}
            {t("dashboard.country.exportCountry.showCategoriesAndProducts")}
          </Button>{" "}
        </Link>
      ),
    },
    {
      name: t("dashboard.country.exportCountry.containersInCountry"),
      selector: (row) => (
        <span>
          {" "}
          <ul className="p-0">
            {" "}
            {row.containerInCountry.length > 0 &&
              row.containerInCountry.map((container, index) => {
                return (
                  <li>
                    {" "}
                    <span key={index}>{container?.usualName}</span>{" "}
                    <span>{` ||| `}</span>{" "}
                    <span>
                      {container.isFridge
                        ? t("dashboard.country.exportCountry.frozen")
                        : t("dashboard.country.exportCountry.notFrozen")}
                    </span>{" "}
                    <br />{" "}
                  </li>
                );
              })}{" "}
          </ul>{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.country.exportCountry.update"),
      selector: (row) => (
        <Link to={`/dashboard/crexportcountries/${row._id}`}>
          {" "}
          <Button variant="dark">{t("dashboard.country.exportCountry.update")}</Button>{" "}
        </Link>
      ),
    },
    {
      name: t("dashboard.country.exportCountry.delete"),
      selector: (row) => (
        <span>
          {" "}
          <Button
            onClick={() => {
              handleShow();
              setExportCountryId(row._id);
            }}
            variant="danger"
          >
            {" "}
            {t("dashboard.country.exportCountry.delete")}{" "}
          </Button>{" "}
        </span>
      ),
    },
    {
      name: t("dashboard.country.exportCountry.multipleDelete"),
      selector: (row) => (
        <Form.Check
          className="deletedCountriesCheck"
          type="checkbox"
          name="deletedProducts"
          id="deletedCountries"
          onChange={(e) => deleteExportCountriesCollection(e, row._id)}
        />
      ),
    },
  ];
  return (
    <section className="cms-create-export-country">
      {" "}
      <Row>
        {" "}
        <div className="crud-countries">
          {" "}
          <div className="title text-center">
            {" "}
            <h1>{t("dashboard.country.exportCountry.exportCountries")}</h1>{" "}
          </div>{" "}
        </div>{" "}
      </Row>{" "}
      <div className="d-flex">
        {" "}
        <Button
          variant="warning"
          onClick={() =>
            navigate("/dashboard/crexportcountries/add-exporcountry")
          }
        >
          {" "}
          {t("dashboard.country.exportCountry.addExportCountry")}{" "}
        </Button>{" "}
        {deleteCountriesCollection.countries.length > 0 && (
          <Button
            id="multipleDelete"
            variant="danger"
            onClick={handleMultipleShow}
          >
            {" "}
            {t("dashboard.country.exportCountry.multipleDelete")}{" "}
          </Button>
        )}{" "}
        {!generateExcelFile ? (
          <Button
            variant="warning"
            id="verify-btn"
            onClick={() => setGenerateExecelFile(Excel)}
          >
            {" "}
            {t("dashboard.country.exportCountry.exportExcelExportCountries")}{" "}
          </Button>
        ) : (
          <Button variant="success" id="verify-btn" onClick={getFileExcel}>
            {" "}
            {t("dashboard.country.exportCountry.downloadExcelFile")}{" "}
          </Button>
        )}{" "}
        <Button variant="danger" id="verify-btn">
          {" "}
          {t("dashboard.country.exportCountry.importExcel")}{" "}
        </Button>{" "}
      </div>{" "}
      <Row>
        {" "}
        <DataTable
          fixedHeader
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={allExportCountriesWithCategories}
          customStyles={customStyles}
        />{" "}
      </Row>{" "}
      <Modal show={DeleteExportCountryModalShow} onHide={handleClose}>
        {" "}
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>
            {" "}
            {t("dashboard.country.exportCountry.deleteConfirmation")}{" "}
          </Modal.Title>{" "}
        </Modal.Header>{" "}
        <Modal.Footer>
          {" "}
          <Button variant="secondary" onClick={handleClose}>
            {" "}
            {t("dashboard.country.exportCountry.close")}{" "}
          </Button>{" "}
          <Button variant="danger" onClick={() => onDelete()}>
            {" "}
            {t("dashboard.country.exportCountry.deleteCountry")}{" "}
          </Button>{" "}
        </Modal.Footer>{" "}
      </Modal>{" "}
      <Modal
        size="lg"
        show={MultipleDeleteModalShow}
        onHide={() => setMultipleDeleteModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {" "}
        <Modal.Header closeButton>
          {" "}
          <Modal.Title id="example-modal-sizes-title-lg">
            {" "}
            {t("dashboard.country.exportCountry.multipleDeleteConfirmation")}{" "}
          </Modal.Title>{" "}
        </Modal.Header>{" "}
        <Modal.Footer>
          {" "}
          <Button variant="secondary" onClick={handleMultipleClose}>
            {" "}
            {t("dashboard.country.exportCountry.close")}{" "}
          </Button>{" "}
          <Button variant="danger" onClick={deleteCollectionOfExportCountries}>
            {" "}
            {t("dashboard.country.exportCountry.deleteExportCountries")}{" "}
          </Button>{" "}
        </Modal.Footer>{" "}
      </Modal>{" "}
    </section>
  );
};

export default CRCountries;
