import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { Button, Modal, Form, Row } from "react-bootstrap";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ApiInstances from "../../Api/axiso";
import { ARRAYOFCountries } from "../Products/CountriesArray";
import MultiSelect from "react-multiple-select-dropdown-lite";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { TiEdit } from "react-icons/ti";
import { useTranslation } from "react-i18next";
const ProductPrice = () => {
  const handleInfoInputChange = (e) => {
    const { name, value } = e.target;
    setNamePrice(value);
  };
  const [Prices, setPrices] = useState([]);
  const [editPrice, setEditPrice] = useState(false);
  const [editPicePrice, setEditPicePrice] = useState(false);

  const [PriceModelShow, SetPriceModelShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [prodductId, setProductId] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [value, setvalue] = useState("");
  const [categoryProducts, setCategoryProducts] = useState([]);

  const [addedPrices, setAddedPrices] = useState([]);
  const [addedPicePrices, setAddedPicePrices] = useState([]);
  const [namePrice, setNamePrice] = useState("");
  const [valuePrice, setValuePrice] = useState({
    name: "",
    prices: {
      price: "",
      piecePrice: "",
      product: "",
      countryOrigin: [""],
    },
  });

  useEffect(() => {
    const fethcCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setCategories([]);
        // console.log(body);
      } else {
        setCategories(body);
      }
    };
    fethcCategories();
  }, []);
  useEffect(() => {
    const fetchProducts = async (categoryID) => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/product/?cat=${categoryID}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setCategoryProducts([]);
        // console.log(body);
      } else {
        setCategoryProducts(body?.data);
      }
    };
    categoryID && fetchProducts(categoryID);
  }, [categoryID]);
  useEffect(() => {
    const getPriceById = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${localStorage.getItem("admin-token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `https://www.api.setalkel.co/prices/${id}`,
          requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
          console.log(body);
        } else {
          setPrices(body);
        }
      } catch (error) {
        console.log(error);
      }
    };
    id && getPriceById();
    if (Prices) {
      setNamePrice(Prices.name);
    }
  }, [Prices]);
  const navigate = useNavigate();
  const { id } = useParams();
  const PostProductsPrices = async () => {
    console.log(addedPrices, addedPicePrices);
    if (addedPrices.length > 0) {
      const prieces = {
        id: id,
        name: namePrice,
        prices: addedPrices,
      };
      console.log(prieces);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify(addedPrices);
      // console.log(raw);
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(prieces),
        redirect: "follow",
      };
      const response = await fetch(
        `https://www.api.setalkel.co/prices/${id}`,
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        // setAddedPrices([]);
        // console.log(body);
        toast.error("There Is Somthing Wrong");
      } else {
        toast.success("Price Added..");
        navigate("/dashboard/crprices3");
        setAddedPrices([]);
        setNamePrice("");
      }
    }
  };
  // const GetPriceQuery = (categoryID) => {
  //     const queryResult = useQuery({
  //         queryKey: ["get-price", categoryID],
  //         queryFn: async () => {
  //             const { data } = await ApiInstances.get(`https://www.api.setalkel.co/product/?cat=${categoryID}`)
  //             return data;
  //         },
  //         enabled: !!categoryID
  //     });
  //     return queryResult;
  // };
  // const { data: ImportPrice } = GetPriceQuery(categoryID)
  const handleProductPrice = (value, productId, countryOrigin) => {
    // console.log(value)
    if (value === "") {
      const index = addedPrices.findIndex(
        (order) => order.product === productId
      );
      if (index !== -1) {
        addedPrices.splice(index, 1); // حذف العنصر المطابق لـ productId
      }
      return;
    }
    if (value > 0) {
      let flag = false;
      addedPrices.forEach((order) => {
        if (order.product === productId) {
          flag = true;
          order.price = parseFloat(value);
        }
      });
      if (!flag) {
        addedPrices.unshift({
          product: productId,
          countryOrigin: countryOrigin,
          price: isNaN(parseFloat(value)) ? "" : parseFloat(value),
        });
      } else if (flag && value === "") {
        // console.log(flag)
      }
    } else {
      toast.error("Product Price Cannot Be Negative or 0");
    }
  };
  const handleProductPicePrice = (value, productId) => {
    // console.log(value)
    if (value === "") {
      const index = addedPrices.findIndex(
        (order) => order.product === productId
      );
      if (index !== -1) {
        addedPrices.splice(index, 1); // حذف العنصر المطابق لـ productId
      }
      return;
    }
    if (value > 0) {
      let flag = false;
      addedPrices.forEach((order) => {
        if (order.product === productId) {
          flag = true;
          order.piecePrice = parseFloat(value);
        }
      });
      if (!flag) {
        addedPrices.unshift({
          product: productId,
          piecePrice: isNaN(parseFloat(value)) ? "" : parseFloat(value),
        });
      } else if (flag && value === "") {
        // console.log(flag)
      }
    } else {
      toast.error("Product Price Cannot Be Negative or 0");
    }
  };

  // console.log(addedPrices)
  const handleSortingChange = (e) => {
    if (e.target.checked) {
      if (e.target.id === "A-Z") {
        const strAscending = [...categoryProducts].sort((a, b) =>
          a.subCat.en > b.subCat.en ? 1 : -1
        );
        setCategoryProducts(strAscending);
      } else if (e.target.id === "Z-A") {
        const strDescending = [...categoryProducts].sort((a, b) =>
          a.subCat.en > b.subCat.en ? -1 : 1
        );
        setCategoryProducts(strDescending);
      }
    }
  };
  const handleWeightSorting = (e) => {
    if (e.target.checked) {
      if (e.target.id === "1-100") {
        const numAscending = [...categoryProducts].sort(
          (a, b) =>
            a.peiceQty.grossWeightForPeice - b.peiceQty.grossWeightForPeice
        );
        setCategoryProducts(numAscending);
      } else if (e.target.id === "100-1") {
        const numDescending = [...categoryProducts].sort(
          (a, b) =>
            b.peiceQty.grossWeightForPeice - a.peiceQty.grossWeightForPeice
        );
        setCategoryProducts(numDescending);
      }
    }
  };
  const handleMatriealSorting = (e) => {
    if (e.target.checked) {
      if (e.target.id === "dried") {
        const driedData = async () => {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
          };

          const response = await fetch(
            `https://www.api.setalkel.co/product/?cat=${categoryID}&materialProperty=dried`,
            requestOptions
          );
          const body = await response.json();
          setCategoryProducts(body.data);
        };
        driedData();
      } else if (e.target.id === "mix") {
        const mixData = async () => {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
          };

          const response = await fetch(
            `https://www.api.setalkel.co/product/?cat=${categoryID}&materialProperty=mix`,
            requestOptions
          );
          const body = await response.json();
          // console.log(body);
          setCategoryProducts(body.data);
        };
        mixData();
      } else if (e.target.id === "frozen") {
        const frozenData = async () => {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
          };

          const response = await fetch(
            `https://www.api.setalkel.co/product/?cat=${categoryID}&materialProperty=frozen`,
            requestOptions
          );
          const body = await response.json();
          // console.log(body);
          setCategoryProducts(body.data);
        };
        frozenData();
      }
    }
  };
  const { t } = useTranslation();

  const columns = [
    {
      name: t("dashboard.price.Image"),
      selector: (row) => (
        <span>
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{
              height: "70px",
              width: "70px",
            }}
          />
        </span>
      ),
    },
    {
      name: t("dashboard.price.Category"),
      selector: (row) => <span>{row.cat.en}</span>,
    },
    {
      name: t("dashboard.price.Product-Name"),
      width: 300,
      selector: (row) => <span>{row.subCat.en}</span>,
    },
    {
      name: t("dashboard.price.Usual-Weight"),
      selector: (row) => <span>{row.peiceQty.usualWeight}</span>,
    },

    {
      name: t("dashboard.price.Country-Origin"),
      selector: (row) => (
        <span>
          {row.countryOrigin
            ? row.countryOrigin
            : t("dashboard.price.No Country Origin")}
        </span>
      ),
    },
    {
      name: t("dashboard.price.Material-Property"),
      selector: (row) => (
        <span>
          {row.countryOrigin
            ? row.materialProperty
            : t("dashboard.price.No Material Property")}
        </span>
      ),
    },
    {
      name: t("dashboard.price.Box Prices"),
      selector: (row) => (
        <span>
          {Prices && Prices?.prices?.find((d) => d.product === row._id)?.price}
        </span>
      ),
    },
    {
      name: t("dashboard.price.Piece Price"),
      selector: (row) => (
        <span>
          {Prices &&
            Prices?.prices?.find((d) => d.product === row._id)?.piecePrice}
        </span>
      ),
    },
    {
      name: t("dashboard.price.Enter Box Price :"),
      selector: (row) => (
        <>
          <Form.Control
            id="price"
            type="number"
            name="price"
            lang="en"
            placeholder={t("dashboard.price. $ ")}
            min={1}
            onChange={(e) => {
              handleProductPrice(e.target.value, row._id, row.countryOrigin);
            }}
          />
        </>
      ),
      width: "50px",
    },
    {
      name: t("dashboard.price.Enter Pice Price :"),
      selector: (row) => (
        <>
          <Form.Control
            id="price"
            type="number"
            name="price"
            lang="en"
            placeholder={t("dashboard.price. $ ")}
            min={1}
            onChange={(e) => {
              handleProductPicePrice(e.target.value, row._id);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <Row>
      <div
        style={{
          width: "fit-content",
          marginBottom: "30px",
        }}
      >
        <Form.Group>
          <Form.Label>{t("dashboard.price.Please Select Category")}</Form.Label>
          <Form.Select
            name="categoryID"
            value={categoryID}
            onChange={(e) => setCategoryID(e.target.value)}
          >
            <option>{t("dashboard.price.Please Select Category")}</option>
            {categories.length > 0
              ? categories.map((category, index) => {
                  return (
                    <option key={index} value={category._id}>
                      {category.en}
                    </option>
                  );
                })
              : null}
          </Form.Select>
        </Form.Group>
      </div>
      <div style={{ width: "fit-content" }}>
        <Form.Group>
          <Form.Label htmlFor="ProductNameInEn">
            {t("dashboard.price.Name of Price")}
          </Form.Label>
          <Form.Control
            name="name"
            label="valuePrice"
            value={namePrice}
            onChange={handleInfoInputChange}
            placeholder={t("dashboard.price.price")}
          />
        </Form.Group>
      </div>
      <div>
        <h4>{t("dashboard.price.Alphabetically Sorting")}</h4>
        <Form.Check
          type="radio"
          name="sort"
          id="A-Z"
          label={<span>{t("dashboard.price.Sorting From A-Z")}</span>}
          onChange={(e) => handleSortingChange(e)}
        />
        <Form.Check
          type="radio"
          name="sort"
          id="Z-A"
          label={<span>{t("dashboard.price.Sorting From Z-A")}</span>}
          onChange={(e) => handleSortingChange(e)}
        />
      </div>
      <div>
        <h4>{t("dashboard.price.Weight Sorting")}</h4>
        <Form.Check
          type="radio"
          name="sort-weight"
          id="1-100"
          label={<span>{t("dashboard.price.Sorting From 1-100")}</span>}
          onChange={(e) => handleWeightSorting(e)}
        />
        <Form.Check
          type="radio"
          name="sort-weight"
          id="100-1"
          label={<span>{t("dashboard.price.Sorting From 100-1")}</span>}
          onChange={(e) => handleWeightSorting(e)}
        />
      </div>
      <div>
        <h4>{t("dashboard.price.Matrial Property Sorting")}</h4>
        <Form.Check
          type="radio"
          name="sort-matrial-property"
          id="dried"
          label={<span>{t("dashboard.price.Dried")}</span>}
          onChange={(e) => handleMatriealSorting(e)}
        />
        <Form.Check
          type="radio"
          name="sort-matrial-property"
          id="mix"
          label={<span>{t("dashboard.price.MIX")}</span>}
          onChange={(e) => handleMatriealSorting(e)}
        />
        <Form.Check
          type="radio"
          name="sort-matrial-property"
          id="frozen"
          label={<span>{t("dashboard.price.Frozen")}</span>}
          onChange={(e) => handleMatriealSorting(e)}
        />
      </div>
      <Button variant="outline-warning" onClick={PostProductsPrices}>
        {t("dashboard.price.Post Products Prices")}
      </Button>
      <DataTable
        fixedHeader
        responsive
        columns={columns}
        customStyles={customStyles}
        data={categoryProducts}
      />
    </Row>
  );
};

export default ProductPrice;
