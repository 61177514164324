import React, { useRef } from 'react'
import { useState } from 'react';
import { Form, Row, Button, Modal } from "react-bootstrap";
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AddCRPathers = () => {
  const { t } = useTranslation()
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [ImgGallery, setImgGallery] = useState({
    ImgGallery: [],
  });
  const [values, setValues] = useState({
    _id: "",
    title: {
      en: "",
      ar: "",
      fr: "",
      de: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },
    desc: {
      en: "",
      ar: "",
      fr: "",
      de: "",
      ru: "",
      cn: "",
      tur: "",
      ir: "",
      dk: "",
    },
  });
  const navigate = useNavigate()
  const handleInputDesc = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      desc: { ...values.desc, [name]: value },
    });
  };
  //=======================Handle Imput Title=========================
  const handleInputTitle = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      title: { ...values.title, [name]: value },
    });
  };

  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //=======================Image Gallery Handeling===============
  const handleGalleryChange = (e) => {
    const newImages = Array.from(e.target.files);
    setImgGallery(prevState => {
      const allImages = [...prevState.ImgGallery, ...newImages];
      return {
        ImgGallery: allImages
      };
    });
  };

  //=====================================Post Kitchen Gallery====================
  const PostPartnersGallery = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      for (let i = 0; i < ImgGallery.ImgGallery.length; i++) {
        fd.append(
          "images",
          ImgGallery.ImgGallery[i],
          ImgGallery.ImgGallery[i].name
        );
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/partner/${id}/gallery`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //=====================================Post Partner Image====================

  const PostPartnerImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();
      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/partners/image/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };
  //===========================Add Partner===================================
  const AddPartner = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = {
      title: {
        ar: values.title.ar,
        en: values.title.en,
        de: values.title.de,
        fr: values.title.fr,
        ru: values.title.ru,
        cn: values.title.cn,
        tur: values.title.tur,
        ir: values.title.ir,
        dk: values.title.dk,
      },
      desc: {
        ar: values.desc.ar,
        en: values.desc.en,
        de: values.desc.de,
        fr: values.desc.fr,
        ru: values.desc.ru,
        cn: values.desc.cn,
        tur: values.desc.tur,
        ir: values.desc.ir,
        dk: values.desc.dk,
      },
    };
    let NewTitle = raw.title;
    let NewDesc = raw.desc;
    let NewRaw = raw;
    Object.keys(NewTitle).forEach((key) => {
      if (NewTitle[key] === "" || NewTitle[key] === "undefined") {
        delete NewTitle[key];
      }
    });
    Object.keys(NewDesc).forEach((key) => {
      if (NewDesc[key] === "" || NewDesc[key] === "undefined") {
        delete NewDesc[key];
      }
    });
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "" || NewRaw[key] === "undefined") {
        delete NewRaw[key];
      }
    });
    const raw2 = JSON.stringify(NewRaw);
    console.log(raw2);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/partners",
      requestOptions
    );
    const body = await response.json();

    if (!response.ok) {
      alert("Partner Not Added :(");
      console.log(body);
    } else {
      if (Imgfile.Imgfile) {
        await PostPartnerImg(body._id);
      }
      if (ImgGallery.ImgGallery.length > 0) {
        await PostPartnersGallery(body._id);
      }
      toast.success("Partner Has Been Added :)");
      navigate("/dashboard/crpartners")
      setValues({
        _id: "",
        title: {
          en: "",
          ar: "",
          fr: "",
          de: "",
          ru: "",
          cn: "",
          tur: "",
          ir: "",
          dk: "",
        },
        desc: {
          en: "",
          ar: "",
          fr: "",
          de: "",
          ru: "",
          cn: "",
          tur: "",
          ir: "",
          dk: "",
        },
      });
    }
  };
  const fileInputRef = useRef(null);
  return (
    <div className="create-partner">
      <div className="partner-titles-form">
        <Form>
          {/* ======================Start Title Langs=========================*/}
          <Form.Group>
            <Form.Label>{t('dashboard.parthners.titleInEnglish')}</Form.Label>
            <Form.Control
              type="text"
              name="en"
              value={values.title.en}
              onChange={(e) => handleInputTitle(e)}
              placeholder={t('dashboard.parthners.titleInEnglishPlaceholder')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('dashboard.parthners.titleInArabic')}</Form.Label>
            <Form.Control
              type="text"
              name="ar"
              value={values.title.ar}
              onChange={(e) => handleInputTitle(e)}
              placeholder={t('dashboard.parthners.titleInArabicPlaceholder')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('dashboard.parthners.titleInFrench')}</Form.Label>
            <Form.Control
              type="text"
              name="fr"
              value={values.title.fr}
              onChange={(e) => handleInputTitle(e)}
              placeholder={t('dashboard.parthners.titleInFrenchPlaceholder')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('dashboard.parthners.titleInRussian')}</Form.Label>
            <Form.Control
              type="text"
              name="ru"
              placeholder={t('dashboard.parthners.titleInRussianPlaceholder')}
              value={values.title.ru}
              onChange={(e) => handleInputTitle(e)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('dashboard.parthners.titleInGerman')}</Form.Label>
            <Form.Control
              type="text"
              name="de"
              value={values.title.de}
              onChange={(e) => handleInputTitle(e)}
              placeholder={t('dashboard.parthners.titleInGermanPlaceholder')}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('dashboard.parthners.titleInChinese')}</Form.Label>
            <Form.Control
              type="text"
              name="cn"
              placeholder={t('dashboard.parthners.titleInChinesePlaceholder')}
              value={values.title.cn}
              onChange={(e) => handleInputTitle(e)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('dashboard.parthners.titleInTurkish')}</Form.Label>
            <Form.Control
              type="text"
              name="tur"
              value={values.title.tur}
              onChange={(e) => handleInputTitle(e)}
              placeholder={t('dashboard.parthners.titleInTurkishPlaceholder')}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('dashboard.parthners.titleInPersian')}</Form.Label>
            <Form.Control
              type="text"
              name="ir"
              value={values.title.ir}
              onChange={(e) => handleInputTitle(e)}
              placeholder={t('dashboard.parthners.titleInPersianPlaceholder')}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('dashboard.parthners.titleInDanish')}</Form.Label>
          </Form.Group>

          {/* ======================End Title Langs=========================*/}

          <Form.Group>
            <Form.Label htmlFor="ProductImg">{t('dashboard.parthners.partnerImage')}</Form.Label>
            <div className="d-flex flex-column gap-2">
              <Form.Control
                type="file"
                name="image"
                id="productImg"
                ref={fileInputRef}
                onChange={handleImageChange}
                style={{
                  height: "fit-content"
                }}
              />
              {Imgfile.Imgfile && (
                <div className="position-relative" style={{
                  width: "fit-content"
                }}>
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src={URL.createObjectURL(Imgfile.Imgfile)}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setImgfile({
                        Imgfile: null,
                      });
                      if (fileInputRef.current) {
                        fileInputRef.current.value = ""; // إفراغ الحقل باستخدام useRef
                      }
                    }}
                    style={{
                      position: "absolute",
                      top: "-18px",
                      right: "-13px",
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                  >
                    &times;
                  </button>
                </div>
              )}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="PartnerGallery">{t('dashboard.parthners.partnerImageGallery')}</Form.Label>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Form.Control
                type="file"
                name="images"
                multiple
                id="productImg"
                onChange={handleGalleryChange}
              />
              <div style={{ display: "flex", gap: "5px" }}>
                {ImgGallery.ImgGallery.map((image, index) => (
                  <div key={index} style={{ position: "relative", display: "inline-block" }}>
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover"
                      }}
                      src={URL.createObjectURL(image)}
                      alt={`Gallery Img ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setImgGallery(prevState => ({
                          ImgGallery: prevState.ImgGallery.filter((_, indexIM) => indexIM !== index)
                        }));
                      }}
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-11px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Form.Group>
          <Button variant="warning" onClick={AddPartner}>
            {t('dashboard.parthners.addPartner')}
          </Button>
        </Form>

      </div>
      {/* ======================Start Title Desc=========================*/}

      <div className="partner-desc-form">
        <Form>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{t('dashboard.parthners.descriptionInEnglish')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="en"
              value={values.desc.en}
              onChange={(e) => handleInputDesc(e)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{t('dashboard.parthners.descriptionInArabic')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="ar"
              value={values.desc.ar}
              onChange={(e) => handleInputDesc(e)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{t('dashboard.parthners.descriptionInFrench')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={values.desc.fr}
              name="fr"
              onChange={(e) => handleInputDesc(e)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{t('dashboard.parthners.descriptionInRussian')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={values.desc.ru}
              name="ru"
              onChange={(e) => handleInputDesc(e)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{t('dashboard.parthners.descriptionInGerman')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={values.desc.de}
              name="de"
              onChange={(e) => handleInputDesc(e)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{t('dashboard.parthners.descriptionInChinese')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={values.desc.cn}
              name="cn"
              onChange={(e) => handleInputDesc(e)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{t('dashboard.parthners.descriptionInTurkish')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="tur"
              value={values.desc.tur}
              onChange={(e) => handleInputDesc(e)}
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{t('dashboard.parthners.descriptionInPersian')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="ir"
              value={values.desc.ir}
              onChange={(e) => handleInputDesc(e)}
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>{t('dashboard.parthners.descriptionInDanish')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="dk"
              value={values.desc.dk}
              onChange={(e) => handleInputDesc(e)}
            />
          </Form.Group>
        </Form>
      </div>

    </div>
  )
}

export default AddCRPathers