const sidebarNav = [
  {
    link: "/",
    section: "home",
    text: "sideBar.Home",
  },
  {
    link: "/dashboard/cragents",
    section: "agents",
    text: "sideBar.Visitors",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/address",
        section: "Shipment",
        textLink: "sideBar.PotentialVisitors",
        sublinks2: [
          {
            id: 1,
            link: "/dashboard/websitevisitors",
            section: "Shipment",
            text: "sideBar.WebsiteVisitors",
          },
          {
            id: 1,
            link: "/dashboard/applicationvisitors",
            section: "Shipment",
            text: "sideBar.ApplicationVisitors",
          }
        ]
      },
      {
        id: 1,
        link: "/dashboard/address",
        section: "Shipment",
        textLink: "sideBar.ActualVisitors",
        sublinks2: [
          {
            id: 1,
            link: "/dashboard/cragents",
            section: "agents",
            text: "sideBar.CreateAgent",
          },
          {
            id: 2,
            link: "/dashboard/verifiedAgents",
            section: "agents",
            text: "sideBar.VerifyAgent",
          },
          {
            id: 2,
            link: "/dashboard/unverifiedAgents",
            section: "agents",
            text: "sideBar.UnVerifyAgent",
          },
        ]
      },

    ],
  },
  {
    link: "/dashboard/addaddress",
    section: "aboutUs",
    text: "sideBar.Address",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/address",
        section: "Shipment",
        text: "sideBar.Address",
      },
      {
        id: 1,
        link: "/dashboard/addaddress",
        section: "Shipment",
        text: "sideBar.AddAddress",
      },

    ]
  },

  {
    link: "/dashboard/craboutUs",
    section: "aboutUs",
    text: "sideBar.AboutUs",
  },
  {
    link: "/dashboard/crproducts",
    section: "products",

    text: "sideBar.Products",
  },
  {
    link: "/dashboard/crcategories",
    section: "products",
    text: "sideBar.Sorting",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/crcategories/drag",
        section: "Shipment",
        text: "sideBar.Categories",
      },
      {
        id: 1,
        link: "/dashboard/crproducts/drag",
        section: "Products",
        text: "sideBar.Products",
      },
      {
        id: 1,
        link: "/dashboard/new/drag",
        section: "Products",
        text: "sideBar.OurNew",
      },
      {
        id: 1,
        link: "/dashboard/verifiedAgents/drag",
        section: "Products",
        text: "sideBar.VerifiedAgents",
      },
      {
        id: 1,
        link: "/dashboard/unverifiedAgents/drag",
        section: "Products",
        text: "sideBar.UnVerifiedAgents",
      },
      {
        id: 1,
        link: "/dashboard/crouractivities/drag",
        section: "Products",
        text: "sideBar.Activities",
      },
      {
        id: 1,
        link: "/dashboard/bannerimages/drag",
        section: "Products",
        text: "sideBar.Banner-(Blog SetAlkel)",
      },
      {
        id: 1,
        link: "/dashboard/crbites/drag",
        section: "Products",
        text: "sideBar.Bites",
      },
      {
        id: 1,
        link: "/dashboard/craboutUs/drag",
        section: "Products",
        text: "sideBar.AboutUs",
      },
      {
        id: 1,
        link: "/dashboard/crpartners/drag",
        section: "Products",
        text: "sideBar.Partners",
      },
      {
        id: 1,
        link: "/dashboard/croffers/drag",
        section: "Products",
        text: "sideBar.Offers",
      },
      {
        id: 1,
        link: "/dashboard/crexportcountries/drag",
        section: "Products",
        text: "sideBar.ExportCountries",
      },
      {
        id: 1,
        link: "/dashboard/crimportcountries/drag",
        section: "Products",
        text: "sideBar.ImportCountries",
      },
    ]
  },
  {
    link: "/dashboard/crcategories",
    section: "products",
    text: "sideBar.Categories",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/crcategories",
        section: "Shipment",
        text: "sideBar.CategoriesWepSite",
      },
      {
        id: 1,
        link: "/dashboard/crcategoriesalameen",
        section: "Shipment",
        text: "sideBar.CategoriesAlAmeen",
      },
      {
        id: 1,
        link: "/dashboard/crcategoryorganizationCoding",
        section: "Shipment",
        text: "sideBar.CategoryGSI",
      },
    ]
  },
  {
    link: "/dashboard/crcontactus",
    section: "products",

    text: "sideBar.ContactUs",
  },
  {
    link: "/dashboard/crbites",
    section: "products",
    text: "sideBar.Bites",
  },
  {
    link: "/dashboard/crpartners",
    section: "products",
    text: "sideBar.Partners",
  },
  {
    link: "/dashboard/crouractivities",
    section: "products",
    text: "sideBar.Activities",
  },
  {
    link: "/dashboard/crprices",
    section: "products",

    text: "sideBar.PricesInefficient",
  },
  {
    link: "/dashboard/crprices3",
    section: "products",
    text: "sideBar.Prices",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/crprices3",
        section: "Shipment",
        text: "sideBar.PriceNames",
      },
      {
        id: 1,
        link: "/dashboard/crprices2",
        section: "Shipment",
        text: "sideBar.Prices",
      },
      {
        id: 1,
        link: "/dashboard/agentprice",
        section: "Shipment",
        text: "sideBar.AgentPrice",
      },
    ]
  },
  {
    link: "/dashboard/croffers",
    section: "products",

    text: "sideBar.Offers",
  },

  {
    link: "/dashboard/crcontainers",
    section: "products",

    text: "sideBar.Containers",
  },
  {
    link: "/dashboard/suggestionshipments",
    section: "products",
    text: "sideBar.Shipments",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/suggestionshipments",
        section: "Shipment",
        text: "sideBar.SuggestionShipments",
      },
      {
        id: 2,
        link: "/dashboard/waitingshippingshipment",
        section: "Shipment",
        text: "sideBar.WaitingShippingShipment",
        sublinks2: [
          {
            link: "/dashboard/uploadshipmentfiles",
            section: "uploadshipmentfiles",
            text: "sideBar.UploadShipmentFiles",
          },
          {
            link: "/dashboard/shippingterms",
            section: "shipmentterms",

            text: "sideBar.AgentShippingTerms",
          },
        ]
      },
      {
        id: 3,
        link: "/dashboard/waitingpaymentshipment",
        section: "Shipment",
        text: "sideBar.WaitingPaymentShipment",
      },
      {
        id: 4,
        link: "/dashboard/finishshipment",
        section: "Shipment",
        text: "sideBar.FinishShipment",
      },
      {
        id: 5,
        link: "/dashboard/freezedshipments",
        section: "Shipment",
        text: "sideBar.FreezedShipments",
      },
    ],
  },
  {
    link: "/dashboard/crexportcountries",
    section: "countires",
    text: "sideBar.Countries",
    sublinks: [
      {
        id: 1,
        path2: "/dashboard/crexportcountries",
        section: "Countries",
        textLink: "sideBar.ExportCountries",
        sublinks2: [
          {
            id: 1,
            link: "/dashboard/crexportcountriescategoriesandproducts",
            section: "Countries",
            text: "sideBar.ExportCountriesProducts&Categories",
          },
          {
            id: 2,
            link: "/dashboard/exportcountriesstatescontainers",
            section: "Countries",
            text: "sideBar.ExportStatesContainers",
          },
        ],
      },

      {
        id: 3,
        path2: "/dashboard/crimportcountries",
        section: "Countries",
        textLink: "sideBar.ImportCountries",
        sublinks2: [
          {
            id: 1,
            link: "/dashboard/crimportcountriesstate",
            section: "Countries",
            text: "sideBar.AddImportCountryState",
          },
          {
            id: 2,
            link: "/dashboard/crimportcountrycontainer",
            section: "Countries",
            text: "sideBar.AddImportCountryStateContainer",
          },
          {
            id: 3,
            link: "/dashboard/crimportcountryagents",
            section: "Countries",
            text: "sideBar.AddImportCountryStateAgents",
          },
        ],
      },
    ],
  },

  {
    link: "/dashboard/crdeliverymethod",
    section: "DeliveryMethod",

    text: "sideBar.DeliveryMethods",
  },
  {
    link: "/dashboard/bannerimages",
    section: "BannerImages",
    text: "sideBar.Banner-(Blog SetAlkel)",
  },
  {
    link: "/dashboard/ratings",
    section: "ratings",
    text: "sideBar.Ratings",
  },
  {
    link: "/dashboard/paymentaccount",
    section: "paymentaccount",
    text: "sideBar.PaymentAccount",
  },
  {
    link: "/dashboard/cradmins",
    section: "products",
    text: "sideBar.Admins",
  },
  // {
  //   link: "/dashboard/permissions",
  //   section: "products",
  //   text: "sideBar.Permissions",
  // },
  {
    link: "/dashboard/app-version",
    section: "products",
    text: "sideBar.AppVersion",
  },
  {
    link: "/dashboard/pallet",
    section: "products",
    text: "sideBar.Pallet",
  },
  {
    link: "/dashboard/advertisements",
    section: "products",
    text: "sideBar.Advertisements",
  },
  {
    link: "/dashboard/promotionalmaterials",
    section: "products",
    text: "sideBar.PromotionalMaterials",
  },
  {
    link: "/dashboard/session-admin",
    section: "Sessions",
    text: "sideBar.Sessions",
    sublinks: [
      {
        id: 1,
        link: "/dashboard/session-admin",
        section: "Shipment",
        text: "sideBar.SessionsAdmin",
      },
      {
        id: 1,
        link: "/dashboard/session-agent",
        section: "Shipment",
        text: "sideBar.SessionsAgent",
      },
    ]
  },

];

export default sidebarNav;
