import React from "react";

import { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const AddPallet = () => {
  const { t } = useTranslation();
  return (
    <section className="cms-create-products">
      <Row>
        <div className="crud-products">
          <div className="crud-title">
            <h1>{t("dashboard.palletActions.addPallet.title")}</h1>
          </div>
        </div>
      </Row>
      <Form onSubmit={() => {}} className="product-info">
        <Form.Group>
          <Form.Label>{t("dashboard.palletActions.addPallet.weight")}</Form.Label>
          <Form.Control
            style={{ border: "1px solid red" }}
            name="weight"
            label="weight"
            placeholder={t("dashboard.palletActions.addPallet.weight")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("dashboard.palletActions.addPallet.weightUnit")}</Form.Label>
          <Form.Control
            style={{ border: "1px solid red" }}
            name="weightUnit"
            label="weightUnit"
            placeholder={t("dashboard.palletActions.addPallet.weightUnit")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("dashboard.palletActions.addPallet.length")}</Form.Label>
          <Form.Control
            style={{ border: "1px solid red" }}
            name="length"
            label="length"
            placeholder={t("dashboard.palletActions.addPallet.length")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("dashboard.palletActions.addPallet.width")}</Form.Label>
          <Form.Control
            style={{ border: "1px solid red" }}
            name="width"
            label="width"
            placeholder={t("dashboard.palletActions.addPallet.width")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("dashboard.palletActions.addPallet.height")}</Form.Label>
          <Form.Control
            style={{ border: "1px solid red" }}
            name="height"
            label="height"
            placeholder={t("dashboard.palletActions.addPallet.height")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="ProductImg">
            {t("dashboard.palletActions.addPallet.palletImage")}
          </Form.Label>
          <div style={{ display: "flex", gap: "5px" }}>
            <Form.Control
              style={{ border: "1px solid red" }}
              type="file"
              name="image"
              id="productImg"
            />
          </div>
        </Form.Group>
      </Form>
    </section>
  );
};

export default AddPallet;
