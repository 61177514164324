import React, { useEffect, useState } from "react";
//boostrap
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";

//components
import Notification from "../../../Components/Notification/Notification";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { useTranslation } from "react-i18next";
const UploadShipmentFiles = () => {
  const [shipments, setShipments] = useState([]);
  const [shipmentId, setShipmentId] = useState("");

  const [TelexRelaseShow, setTelexRelaseShow] = useState(false);
  const docLang = document.querySelector("html").lang

  const handleCloseTelexRelease = () => setTelexRelaseShow(false);
  const handleShowTelexRelease = () => setTelexRelaseShow(true);

  const [telexRelease, setTelexRelease] = useState({
    telexRelease: null,
  });
  const [uploadFilesModalShow, setUploadFilesModalShow] = React.useState(false);
  const handleFilesClose = () => setUploadFilesModalShow(false);
  const handleFilesShow = () => setUploadFilesModalShow(true);
  const [FilesShipmentId, setFilesShipmentId] = useState("");
  const [shippingPolicy, setShippingPolicy] = useState({
    shippingPolicy: null,
  });
  const [finalInvoice, setFinalInvoice] = useState({
    finalInvoice: null,
  });
  const [packingList, setPackingList] = useState({
    packingList: null,
  });
  const [healthCertificate, setHealthCertificate] = useState({
    healthCertificate: null,
  });
  const handleTelexRelease = (e) => {
    setTelexRelease({
      telexRelease: e.target.files[0],
    });
  };
  //=======================Shipping Policy Handeling===============
  const handleShippingPolicyChange = (e) => {
    setShippingPolicy({
      shippingPolicy: e.target.files[0],
    });
  };
  //=======================finalInvoice Handeling===============
  const handleFinalInvoiceChange = (e) => {
    setFinalInvoice({
      finalInvoice: e.target.files[0],
    });
  };
  //=======================packingList Handeling===============

  const handlePackingListChange = (e) => {
    setPackingList({
      packingList: e.target.files[0],
    });
  };
  //=======================Health Certificate Handeling===============

  const handleHealthCertificateChange = (e) => {
    setHealthCertificate({
      healthCertificate: e.target.files[0],
    });
  };
  //==========================Upload Telex Release=======================
  const uploadTelexRelease = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    const formdata = new FormData();
    formdata.append(
      "file",
      telexRelease.telexRelease,
      telexRelease.telexRelease.name
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/telex-release/${shipmentId}`,
      requestOptions
    );
    if (response.ok) {
      alert("dashboard.shipment.Telex Release Has Been Uploaded");
    } else {
      alert("dashboard.shipment.Telex Release NOT UPLOADED");
      console.log(response);
    }
  };
  //============================ Get all shipments=====================

  useEffect(() => {
    const getAllShipments = async () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `bearer ${localStorage.getItem("admin-token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/shipment/",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setShipments([]);
        console.log(body);
      } else {
        console.log(body);
        setShipments(body);
      }
    };
    getAllShipments();
  }, []);

  //========================Upload Shiping policy============
  const uploadShippingPolicy = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );
      const formdata = new FormData();
      formdata.append(
        "file",
        shippingPolicy.shippingPolicy,
        shippingPolicy.shippingPolicy.name
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      const FileResponse = await fetch(
        `https://www.api.setalkel.co/shipment/shipping-policy/${FilesShipmentId}`,
        requestOptions
      );
      if (FileResponse.ok) {
        alert("dashboard.shipment.Shipping Policy Has Been Uploaded");
      } else {
        alert("dashboard.shipment.Shipping Policy NOT UPLOADED");
        console.log(FileResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //========================Upload Final Invoice=====================
  const uploadFinalInvoice = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    const formdata = new FormData();
    formdata.append(
      "file",
      finalInvoice.finalInvoice,
      finalInvoice.finalInvoice.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/final-invoice/${FilesShipmentId}`,
      requestOptions
    );
    if (response.ok) {
      alert("dashboard.shipment.Final Invoice Has Been Uploaded");
    } else {
      alert("dashboard.shipment.Final Invoice NOT UPLOADED");
      console.log(response);
    }
  };
  //========================Upload packingList=======================
  const uploadPackingList = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    const formdata = new FormData();
    formdata.append(
      "file",
      packingList.packingList,
      packingList.packingList.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/packing-list/${FilesShipmentId}`,
      requestOptions
    );
    if (response.ok) {
      alert("dashboard.shipment.Packing List Has Been Uploaded");
    } else {
      alert("dashboard.shipment.Packing List NOT UPLOADED");
      console.log(response);
    }
  };
  //========================Upload healthCertificate=======================
  const uploadHealthCertificate = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    const formdata = new FormData();
    formdata.append(
      "file",
      healthCertificate.healthCertificate,
      healthCertificate.healthCertificate.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/shipment/health-certificate/${FilesShipmentId}`,
      requestOptions
    );
    if (response.ok) {
      alert("dashboard.shipment.Health Certificate Has Been Uploaded");
    } else {
      alert("dashboard.shipment.Health Certificate NOT UPLOADED");
      console.log(response);
    }
  };
  const { t } = useTranslation();
  const columns = [
    {
      name: t("dashboard.shipment.id"),
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: t("dashboard.shipment.status"),
      selector: (row) => <span>{row.status}</span>,
    },
    {
      name: t("dashboard.shipment.containerDetails"),
      selector: (row) => (
        <div>
          <ul className="p-0">
            <li>{t("dashboard.shipment.type", { type: row.containerInfo.container.containerType })}</li>
            <li>
              {t("dashboard.shipment.fridge", {
                fridge: row.containerInfo.container.isFridge ? t("dashboard.shipment.fridge") : t("dashboard.shipment.notFridge"),
              })}
            </li>
            <li>{t("dashboard.shipment.maxVol", { vol: row.containerInfo.container.maxVolume })}</li>
            <li>{t("dashboard.shipment.maxWeight", { weight: row.containerInfo.container.maxWeight })}</li>
            <li>{t("dashboard.shipment.maxWeightState", { state: row.containerInfo.maxWeightInState })}</li>
          </ul>
        </div>
      ),
    },
    {
      name: t("dashboard.shipment.agentInfo"),
      selector: (row) => (
        <span>
          <span>{row.agent.name}</span>
          <br />
          <span>{row.agent.email}</span>
          <br />
          <span>{row.agent.mobilePhone}</span>
          <br />
          <span>{row.agent.companyName}</span>
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.exportCountry"),
      selector: (row) => (
        <span>
          {t("dashboard.shipment.countryCode", { code: row.exportCountry.countryCode })}
          <br />
          {t("dashboard.shipment.countryName", { name: row.exportCountry.countryName[docLang] })}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.importCountry"),
      selector: (row) => (
        <span>
          {t("dashboard.shipment.countryCode", { code: row.importCountry.countryCode })}
          <br />
          {t("dashboard.shipment.countryName", { name: row.importCountry.countryName[docLang] })}
        </span>
      ),
    },
    {
      name: t("dashboard.shipment.importState"),
      selector: (row) => <span>{row.state.stateName[docLang]}</span>,
    },
    {
      name: t("dashboard.shipment.uploadTelexRelease"),
      selector: (row) => (
        <div>
          <Button
            variant="warning"
            onClick={() => {
              handleShowTelexRelease();
              setShipmentId(row._id);
            }}
          >
            {t("dashboard.shipment.uploadTelexRelease")}
          </Button>
          <Modal show={TelexRelaseShow} onHide={handleCloseTelexRelease}>
            <Modal.Header closeButton>
              <Modal.Title>{t("dashboard.shipment.telexRelease")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>{t("dashboard.shipment.telexRelease")}</Form.Label>
                  <Form.Control
                    type="file"
                    name="telexRelease"
                    onChange={handleTelexRelease}
                    autoFocus
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseTelexRelease}>
                {t("dashboard.shipment.close")}
              </Button>
              <Button
                variant="warning"
                onClick={async () => {
                  await uploadTelexRelease();
                  window.location.reload();
                }}
              >
                {t("dashboard.shipment.saveChanges")}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
    {
      name: t("dashboard.shipment.uploadShipmentFiles"),
      selector: (row) => (
        <div>
          <Button
            variant="warning"
            onClick={() => {
              handleFilesShow();
              setFilesShipmentId(row._id);
            }}
          >
            {t("dashboard.shipment.uploadFiles")}
          </Button>
        </div>
      ),
    },
  ];
  
  return (
    <section className="waiting-payment-shipping">
      <Notification />
      <Modal show={uploadFilesModalShow} onHide={handleFilesClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("dashboard.shipment.uploadFiles")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("dashboard.shipment.shippingPolicy")}</Form.Label>
              <Form.Control
                type="file"
                name="shippingPolicy"
                onChange={handleShippingPolicyChange}
                placeholder={t("dashboard.shipment.shippingPolicy")}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("dashboard.shipment.finalInvoice")}</Form.Label>
              <Form.Control
                type="file"
                name="shippingPolicy"
                onChange={handleFinalInvoiceChange}
                placeholder={t("dashboard.shipment.finalInvoice")}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("dashboard.shipment.packingList")}</Form.Label>
              <Form.Control
                type="file"
                name="shippingPolicy"
                onChange={handlePackingListChange}
                placeholder={t("dashboard.shipment.packingList")}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("dashboard.shipment.healthCertificate")}</Form.Label>
              <Form.Control
                type="file"
                name="healthCertificate"
                onChange={handleHealthCertificateChange}
                placeholder={t("dashboard.shipment.healthCertificate")}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFilesClose}>
            {t("dashboard.shipment.close")}
          </Button>
          <Button
            variant="warning"
            onClick={async () => {
              if (shippingPolicy.shippingPolicy) {
                await uploadShippingPolicy();
              }
              if (finalInvoice.finalInvoice) {
                await uploadFinalInvoice();
              }
              if (packingList.packingList) {
                await uploadPackingList();
              }
              if (healthCertificate.healthCertificate) {
                await uploadHealthCertificate();
              }
            }}
          >
            {t("dashboard.shipment.saveChanges")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <h2>{t("dashboard.shipment.allShipments")}</h2>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={shipments}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
  
};

export default UploadShipmentFiles;
