import React from "react";
import { Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Advertisements = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Row
      style={{
        background: "white",
        textAlign: "center",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      
      <h1>{t("dashboard.advertisements.title")}</h1>
      <Row>
        
        <div className="d-flex gap-2 align-items-center">
          
          <Button
            style={{ width: "fit-content" }}
            variant="warning"
            id="verify-btn"
            onClick={() => navigate("/dashboard/add-advertisements")}
          >
            
            {t("dashboard.advertisements.addAdvertisements2")}
          </Button>
          <Button variant="danger" id="verify-btn">
            
            {t("dashboard.advertisements.exportExcel")}
          </Button>
          <Button variant="danger" id="verify-btn">
            
            {t("dashboard.advertisements.importExcel")}
          </Button>
          <Button variant="danger" id="verify-btn">
            
            {t("dashboard.advertisements.update")}
          </Button>
          <Button variant="danger" id="verify-btn">
            
            {t("dashboard.advertisements.delete")}
          </Button>
          <Button variant="danger" id="verify-btn">
            
            {t("dashboard.advertisements.showHide")}
          </Button>
        </div>
      </Row>
    </Row>
  );
};

export default Advertisements;
