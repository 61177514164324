import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { customStyles } from '../constants/DataTableCustomeStyles';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Address = () => {
    const docLang = document.querySelector("html").lang
    const [address, setAdress] = useState([])
    const [id, setId] = useState("")
    const [DeleteModalShow, setDeleteModal] = useState(false);
    const handleClose = () => setDeleteModal(false);
    const handleShow = () => setDeleteModal(true);
    const fetchAddress = async () => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",

        };

        const response = await fetch(
            "https://www.api.setalkel.co/address",
            requestOptions
        );
        const body = await response.json();
        if (!response.ok) {
            setAdress([]);

        } else {
            setAdress(body);
            console.log(body);
        }
    };
    useEffect(() => {

        fetchAddress();
    }, []);
    const onDelete = () => {
        axios
            .delete(`https://www.api.setalkel.co/address/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("admin-token"),
                },
            })
            .then((response) => {
                if (response.status !== 204) {
                    // console.log(response.data.msg);
                } else {
                    toast.success("item deleted");
                    handleClose()
                    fetchAddress()
                }
            })
            .catch((error) => {
                toast.error(error.response)
                // console.log("error", error.response);
            });
    };
    const navigate = useNavigate()
    const { t } = useTranslation()
    const columns = [
        {
            name: t('dashboard.address.address'),
            selector: (row) => <span>{row.address[docLang]}</span>,
        },
        {
            name: t('dashboard.drag.expCountry.countryName'),
            selector: (row) => <span>{row.country[docLang]}</span>,
        },
        {
            name: t('dashboard.address.state'),
            selector: (row) => <span>{row.state[docLang]}</span>,
        },
        {
            name: t('dashboard.address.email1'),
            selector: (row) => <span>{row.email1}</span>,
        },
        {
            name: t('dashboard.address.email2'),
            selector: (row) => <span>{row.email2}</span>,
        },
        {
            name: t('dashboard.address.phone1'),
            selector: (row) => <span>{row.phone1}</span>,
        },
        {
            name: t('dashboard.address.phone2'),
            selector: (row) => <span>{row.phone2}</span>,
        },
        {
            name: t('dashboard.address.showHidden'),
            selector: (row) => <span>{row.hidden ? t('dashboard.address.show') : t('dashboard.address.hidden')}</span>,
        },
        {
            name: t('dashboard.address.delete'),
            selector: (row) => (
                <Button
                    variant="danger"
                    onClick={() => {
                        handleShow();
                        setId(row._id);
                    }}
                >
                    {t('dashboard.address.delete')}
                </Button>
            ),
        },
        {
            name: t('dashboard.address.update'),
            selector: (row) => (
                <Button
                    variant="warning"
                    onClick={() => navigate(`/dashboard/address/${row._id}`)}
                >
                    {t('dashboard.address.update')}
                </Button>
            ),
        },
    ];
    return (
        <>
            <Row>
                <div className="d-flex align-items-center">
                    <Button variant="warning" id="verify-btn">
                        {t('dashboard.address.exportExcel')}
                    </Button>
                    <Button variant="danger" id="verify-btn">
                        {t('dashboard.address.importExcel')}
                    </Button>
                    <Button variant="warning" id="verify-btn">
                        {t('dashboard.address.showHideAddress')}
                    </Button>
                </div>
            </Row>
            <Row>
                <div className="list-category">
                    <DataTable
                        fixedHeaderScrollHeight="300px"
                        responsive
                        subHeaderWrap
                        columns={columns}
                        data={address}
                        customStyles={customStyles}
                    />
                </div>
            </Row>
            <Modal
                size="lg"
                show={DeleteModalShow}
                onHide={handleClose}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {t('dashboard.address.deleteConfirmation')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('dashboard.address.close')}
                    </Button>
                    <Button variant="danger" onClick={onDelete}>
                        {t('dashboard.address.deleteAddress')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default Address