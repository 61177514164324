import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
//semantic Ui
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
//css
import "./CROffers.css";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
const docLang = document.querySelector("html").lang

const CROffers = () => {
  const docLang = document.querySelector("html").lang

  const [pieceQTYModalShow, setPieceQTYModalShow] = React.useState(false);
  const [productNamesModalShow, setProductNamesModalShow] = useState(false);
  const [packageModalShow, setPackageModalShow] = React.useState(false);
  const [offers, setOffers] = useState([]);
  const [offerId, setOfferId] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [CatFilterID, setCatFilterID] = useState("");
  const [isFree] = useState("false");
  const [offerData, setOfferData] = useState({
    offer: [],
  });
  const fileInputRef = useRef(null);
  const [values, setValues] = useState({
    offerName: {
      en: "",
      ar: "",
      fr: "",
      ru: "",
      de: "",
      dk: "",
      ir: "",
      cn: "",
      tur: "",
    },
    grossweight: "",
    volume: "",
    countInBox: "",
  });
  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [deletedProducts, setDeletedProducts] = useState({
    ids: [],
  });
  const [choosenProduct, setChoosenProduct] = useState();
  const {t} = useTranslation()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //=================Delete Offer Modal=============================
  const [DeleteModalShow, setDeleteOfferModalShow] = useState(false);
  const handleDeleteOfferModalClose = () => setDeleteOfferModalShow(false);
  const handleDeleteOfferModalShow = () => setDeleteOfferModalShow(true);
  //=========================Delete Multiple Delete Offer=======================

  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //=======================handle Names Input======================
  const handleNamesInput = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      offerName: { ...values.offerName, [name]: value },
    });
  };
  //=======================handle Input======================
  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSwitch = (e, productId) => {
    if (e.target.checked) {
      if (!offerData.offer.includes(productId)) {
        setOfferData({
          ...offerData,
          offer: [
            ...offerData.offer,
            { product: productId, free: isFree, count: "1" },
          ],
        });
      } else {
        setOfferData({
          ...offerData,
          offer: offerData.offer.filter((item) => item.product !== productId),
        });
      }
    } else {
      setOfferData({
        ...offerData,
        offer: offerData.offer.filter((item) => item.product !== productId),
      });
    }
  };
  const handleSwitchFree = (e, productID) => {
    if (e.target.checked) {
      offerData.offer.forEach((item) => {
        if (offerData.offer.includes(item)) {
          if (item.product === productID) {
            if (item.free === "false") {
              item.free = "true";
            }
          }
        }
      });
    } else {
      offerData.offer.forEach((item) => {
        if (offerData.offer.includes(item)) {
          if (item.product === productID) {
            if (item.free === "true") {
              item.free = "false";
            }
          }
        }
      });
    }
  };
  const handleCount = (value, productId) => {
    offerData.offer.forEach((item) => {
      if (offerData.offer.includes(item)) {
        if (item.product === productId) {
          item.count = value;
        }
      }
    });
  };
  //=============================fetch Offers=============================

  useEffect(() => {
    const fetchOffers = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/offer",
        requestOptions
      );
      const body = await response.json();
      if (!response.ok) {
        setOffers([]);
        console.log(body);
      } else {
        setOffers(body);
      }
    };
    fetchOffers();
  }, []);

  //=============================================Fetching Categories========================================

  useEffect(() => {
    const fetchCategories = async () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://www.api.setalkel.co/product/cat/",
        requestOptions
      );
      const body = await response.json();
      // console.log(body);

      if (!response.ok) {
        setCategories([]);
        console.log(body);
      } else {
        setCategories(body);
      }
    };
    fetchCategories();
  }, []);

  //===================================================Filter Products Products==================================================================
  const filterProductsByCategoryID = async () => {
    if (CatFilterID) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        `https://www.api.setalkel.co/product/?cat=${CatFilterID}`,
        requestOptions
      );
      const body = await response.json();

      if (!response.ok) {
        setCategoryProducts([]);
        console.log(body);
      } else {
        setCategoryProducts(body.data);
      }
    } else {
      var requestOptions2 = {
        method: "GET",
        redirect: "follow",
      };

      const response2 = await fetch(
        `https://www.api.setalkel.co/product`,
        requestOptions2
      );
      const body2 = await response2.json();

      if (!response2.ok) {
        setCategoryProducts([]);
        console.log(body2);
      } else {
        setCategoryProducts(body2.data);
      }
    }
  };
  useEffect(() => {
    filterProductsByCategoryID();
  }, [CatFilterID]);
  //===================================================Posting Image==================================================================
  const PostOfferImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );

      const fd = new FormData();

      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/offer/image/${id}`,
        requestOptions
      );
      await ImgResponse.json();

      alert("Image Has Been Uploaded");
    } catch (error) {
      console.log(error);
    }
  };
  //====================================POST Offer====================================
  const PostOffer = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = {
      offerName: values.offerName,
      offer: offerData.offer,
      grossWeight: values.grossweight,
      volume: values.volume,
      countInBox: values.countInBox,
    };
    let NewRaw = raw;
    let NewOfferName = raw.offerName;
    Object.keys(NewOfferName).forEach((key) => {
      if (NewOfferName[key] === "") {
        delete NewOfferName[key];
      }
    });
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "") {
        delete NewRaw[key];
      }
    });
    const LastRaw = JSON.stringify(NewRaw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: LastRaw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/offer",
      requestOptions
    );
    const body = await response.json();

    if (!response.ok) {
      alert("There Is An Error !!");
      setOfferData([]);
      setValues([]);
      console.log(body);
    } else {
      if (Imgfile) {
        await PostOfferImg(body._id);
      }
      alert("Offer Has Been Added");
      window.location.reload();
    }
  };
  //==========================Delete Product=====================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/offer/${offerId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  //============================Delete Mutliple Product=====================

  const deleteProducts = (e, contactID) => {
    if (e.target.checked) {
      if (!deletedProducts.ids.includes(contactID)) {
        setDeletedProducts({
          ...deletedProducts,
          ids: [...deletedProducts.ids, contactID],
        });
      }
    } else {
      setDeletedProducts({
        ids: deletedProducts.ids.filter((item) => item !== contactID),
      });
    }
  };
  const deleteMultipleOffers = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/offer/collection",
      requestOptions
    );

    if (!response.ok) {
      alert("There Is An Error ");
      console.log(response);
    } else {
      alert("Offers Have Been Deleted !!");
      window.location.reload();
    }
  };

  //=============================Modals=============================
  //=============================================================Product Modals===============================================================
  function PieceDetailsModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('dashboard.offer.pieceDetails2.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <TableHeader>
              <TableHeaderCell>{t('dashboard.offer.pieceDetails2.grossWeight')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.pieceDetails2.netWeight')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.pieceDetails2.drainedWeight')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.pieceDetails2.freeOffer')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.pieceDetails2.unit')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.pieceDetails2.packageForPiece')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.pieceDetails2.usualWeight')}</TableHeaderCell>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  {choosenProduct
                    ? choosenProduct.peiceQty.grossWeightForPeice
                    : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct
                    ? choosenProduct.peiceQty.netWeightForPeice
                    : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct
                    ? choosenProduct.peiceQty.drainedWeightForPeice
                    : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.peiceQty.freeOffer : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.peiceQty.unit : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct
                    ? choosenProduct.peiceQty.packageForPeice
                    : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.peiceQty.usualWeight : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t('dashboard.offer.pieceDetails2.close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  function ProductNamesModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('dashboard.offer.productNames2.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <TableHeader>
              <TableHeaderCell>{t('dashboard.offer.productNames2.english')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.productNames2.arabic')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.productNames2.french')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.productNames2.turkish')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.productNames2.dutch')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.productNames2.german')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.productNames2.chinese')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.productNames2.russian')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.productNames2.persian')}</TableHeaderCell>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.en : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.ar : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.fr : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.tur : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.dk : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.de : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.cn : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.ru : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.subCat.ir : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t('dashboard.offer.productNames2.close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  function PackageInfoModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('dashboard.offer.packageInfo2.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <TableHeader>
              <TableHeaderCell>{t('dashboard.offer.packageInfo2.packageGrossWeight')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.packageInfo2.unitForPackage')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.packageInfo2.volume')}</TableHeaderCell>
              <TableHeaderCell>{t('dashboard.offer.packageInfo2.countInBox')}</TableHeaderCell>
            </TableHeader>
  
            <TableBody>
              <TableRow>
                <TableCell>
                  {choosenProduct ? choosenProduct.packageGrossWeight : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.unitForPackage : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.volume : ""}
                </TableCell>
                <TableCell>
                  {choosenProduct ? choosenProduct.countInBox : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t('dashboard.offer.packageInfo2.close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  //=======================================================================================================

  const OffersColumns = [
    {
      name: t('dashboard.offer.id'),
      selector: (row, index) => <span>{index}</span>,
    },
    {
      name: t('dashboard.offer.image'),
      selector: (row) => (
        <span>
          <img
            src={`https://www.api.setalkel.co${row.img}`}
            alt="offerImg"
            width="100px"
            height="100px"
          />
        </span>
      ),
    },
    {
      name: t('dashboard.offer.offerName'),
      selector: (row) => <span>{row.offerName[docLang]}</span>,
    },
    {
      name: t('dashboard.offer.numberOfProducts'),
      selector: (row) => <span>{row.offer.length}</span>,
    },
    {
      name: t('dashboard.offer.productsNamesFree'),
      selector: (row) => (
        <ul className="p-0">
          {row.offer.map((item) => {
            return (
              <li>{`${item.product.subCat[docLang]} ===> ${item.free ? t('dashboard.offer.yes') : t('dashboard.offer.no')}`}</li>
            );
          })}
        </ul>
      ),
    },
    {
      name: t('dashboard.offer.grossWeight'),
      selector: (row) => <span>{row.grossWeight}</span>,
    },
    {
      name: t('dashboard.offer.volume'),
      selector: (row) => <span>{row.volume}</span>,
    },
    {
      name: t('dashboard.offer.countInBox'),
      selector: (row) => <span>{row.countInBox}</span>,
    },
    {
      name: t('dashboard.offer.update'),
      selector: (row) => (
        <Link to={`/dashboard/croffers/${row._id}`}>
          <Button variant="dark">{t('dashboard.offer.update')}</Button>
        </Link>
      ),
    },
    {
      name: t('dashboard.offer.delete'),
      selector: (row) => (
        <div>
          <Button variant="danger" onClick={() => {
            handleDeleteOfferModalShow()
            setOfferId(row._id)
          }}>
            {t('dashboard.offer.delete')}
          </Button>
  
          <Modal show={DeleteModalShow} onHide={handleDeleteOfferModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t('dashboard.offer.areYouSureDeleteOffer')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleDeleteOfferModalClose}>
                {t('dashboard.offer.close')}
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                {t('dashboard.offer.deleteOffer')}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
    {
      name: t('dashboard.offer.mDelete'),
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteProducts(e, row._id)}
        />
      ),
    },
  ];
  
  const columns = [
    {
      name: t('dashboard.offer.image'),
      selector: (row) => (
        <span>
          <img
            src={"https://www.api.setalkel.co" + row.img}
            alt="productImg"
            style={{
              height: "70px",
              width: "70px",
            }}
          />
        </span>
      ),
    },
    { name: t('dashboard.offer.category'), selector: (row) => <span>{row.cat[docLang]}</span> },
    {
      name: t('dashboard.offer.productName'),
      selector: (row) => (
        <div>
          <Button
            id="productName"
            variant="dark"
            onClick={() => {
              setProductNamesModalShow(true);
              setChoosenProduct(row);
            }}
          >
            {t('dashboard.offer.productNames')}
          </Button>
  
          <ProductNamesModal
            show={productNamesModalShow}
            onHide={() => setProductNamesModalShow(false)}
          />
        </div>
      ),
    },
    {
      name: t('dashboard.offer.pieceDetails'),
      selector: (row) => (
        <div>
          <Button
            id="usualWeight"
            variant="dark"
            onClick={() => {
              setPieceQTYModalShow(true);
              setChoosenProduct(row);
            }}
          >
            {row.peiceQty.usualWeight}
          </Button>
  
          <PieceDetailsModal
            show={pieceQTYModalShow}
            onHide={() => setPieceQTYModalShow(false)}
          />
        </div>
      ),
    },
    {
      name: t('dashboard.offer.packageDetails'),
      selector: (row) => (
        <div>
          <Button
            variant="dark"
            onClick={() => {
              setPackageModalShow(true);
              setChoosenProduct(row);
            }}
          >
            {t('dashboard.offer.packageInfo')}
          </Button>
  
          <PackageInfoModal
            show={packageModalShow}
            onHide={() => setPackageModalShow(false)}   
          />
        </div>
      ),
    },
    {
      name: t('dashboard.offer.countryOrigin'),
      selector: (row) => (
        <Form.Control
          as="textarea"
          disabled
          value={row.countryOrigin ? row.countryOrigin : t('dashboard.offer.noCountryOrigin')}
        />
      ),
    },
    {
      name: t('dashboard.offer.materialProperty'),
      selector: (row) => (
        <Form.Control
          as="textarea"
          disabled
          value={
            row.materialProperty ? row.materialProperty : t('dashboard.offer.noMaterialProperty')
          }
        />
      ),
    },
    {
      name: t('dashboard.offer.supplierName'),
      selector: (row) => (
        <span>{row.supplierName ? row.supplierName : t('dashboard.offer.noSupplierName')}</span>
      ),
    },
    {
      name: t('dashboard.offer.stockOff'),
      selector: (row) => <span>{row.stockOff ? t('dashboard.offer.yes') : t('dashboard.offer.noStockOff')}</span>,
    },
    {
      name: t('dashboard.offer.hidden'),
      selector: (row) => <span>{row.hidden ? t('dashboard.offer.yes') : t('dashboard.offer.no')}</span>,
    },
    {
      name: t('dashboard.offer.isNew'),
      selector: (row) => <span>{row.is_new ? t('dashboard.offer.yes') : t('dashboard.offer.no')}</span>,
    },
    {
      name: t('dashboard.offer.addToOffer'),
      selector: (row) => (
        <Form.Check
          type="switch"
          name="offer"
          id="offer"
          onChange={(e) => handleSwitch(e, row._id)}
        />
      ),
    },
    {
      name: t('dashboard.offer.free'),
      selector: (row) => (
        <Form.Check
          type="switch"
          name="free"
          id="free"
          onChange={(e) => handleSwitchFree(e, row._id)}
        />
      ),
    },
    {
      name: t('dashboard.offer.count'),
      selector: (row) => (
        <Form.Control
          type="number"
          name="count"
          onKeyUp={(e) => {
            handleCount(e.target.value, row._id);
          }}
          placeholder={t('dashboard.offer.howMany')}
        />
      ),
    },
  ];

  
  return (
  
        <section className="cms-create-offers">
          <Row>
            <div className="crud-title">
              <h2>{t('dashboard.offer.offers')}</h2>
            </div>
          </Row>
          <Row>
            <div className="create-offer">
              <Form>
                <div className="product-langs">
                  <Form.Label htmlFor="ProductNameInEn" style={{ color: "red" }}>
                    {t('dashboard.offer.arabic_and_english_required')}
                  </Form.Label>
                  <Form.Group>
                    <Form.Label htmlFor={values.offerName[docLang]}>
                      {t('dashboard.offer.offer_name_in_english')} / 
                      <span style={{ color: "red" }}>{t('dashboard.offer.required')}</span>
                    </Form.Label>
                    <Form.Control
                      name="en"
                      label="Offer"
                      value={values.offerName[docLang]}
                      onChange={(e) => handleNamesInput(e)}
                      placeholder={t('dashboard.offer.offer_name_in_english')}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor={values.offerName.ar}>
                      {t('dashboard.offer.offer_name_in_arabic')} / 
                      <span style={{ color: "red" }}>{t('dashboard.offer.required')}</span>
                    </Form.Label>
                    <Form.Control
                      name="ar"
                      label="Offer"
                      value={values.offerName.ar}
                      onChange={(e) => handleNamesInput(e)}
                      placeholder={t('dashboard.offer.offer_name_in_arabic')}
                      required
                    />
                  </Form.Group>
    
                  <Form.Group>
                    <Form.Label htmlFor={values.offerName.fr}>
                      {t('dashboard.offer.offer_name_in_french')}
                    </Form.Label>
                    <Form.Control
                      name="fr"
                      label="Offer"
                      value={values.offerName.fr}
                      onChange={(e) => handleNamesInput(e)}
                      placeholder={t('dashboard.offer.offer_name_in_french')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor={values.offerName.ru}>
                      {t('dashboard.offer.offer_name_in_russian')}
                    </Form.Label>
                    <Form.Control
                      name="ru"
                      label="Offer"
                      value={values.offerName.ru}
                      onChange={(e) => handleNamesInput(e)}
                      placeholder={t('dashboard.offer.offer_name_in_russian')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor={values.offerName.de}>
                      {t('dashboard.offer.offer_name_in_german')}
                    </Form.Label>
                    <Form.Control
                      name="de"
                      label="Offer"
                      value={values.offerName.de}
                      onChange={(e) => handleNamesInput(e)}
                      placeholder={t('dashboard.offer.offer_name_in_german')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor={values.offerName.cn}>
                      {t('dashboard.offer.offer_name_in_china')}
                    </Form.Label>
                    <Form.Control
                      name="cn"
                      label="Offer"
                      value={values.offerName.cn}
                      onChange={(e) => handleNamesInput(e)}
                      placeholder={t('dashboard.offer.offer_name_in_china')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor={values.offerName.tur}>
                      {t('dashboard.offer.offer_name_in_turkish')}
                    </Form.Label>
                    <Form.Control
                      name="tur"
                      label="Offer"
                      value={values.offerName.tur}
                      onChange={(e) => handleNamesInput(e)}
                      placeholder={t('dashboard.offer.offer_name_in_turkish')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor={values.offerName.ir}>
                      {t('dashboard.offer.offer_name_in_persian')}
                    </Form.Label>
                    <Form.Control
                      name="ir"
                      label="Offer"
                      value={values.offerName.ir}
                      onChange={(e) => handleNamesInput(e)}
                      placeholder={t('dashboard.offer.offer_name_in_persian')}
                    />
                  </Form.Group>
    
                  <Form.Group>
                    <Form.Label htmlFor={values.offerName.dk}>
                      {t('dashboard.offer.offer_name_in_danish')}
                    </Form.Label>
                    <Form.Control
                      name="dk"
                      label="Offer"
                      value={values.offerName.dk}
                      onChange={(e) => handleNamesInput(e)}
                      placeholder={t('dashboard.offer.offer_name_in_danish')}
                    />
                  </Form.Group>
                </div>
                <Form.Group>
                  <Form.Label>{t('dashboard.offer.offer_gross_weight')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="grossweight"
                    value={values.grossweight}
                    onChange={handleInput}
                    placeholder={t('dashboard.offer.offer_gross_weight')}
                    required
                  />
                  <Form.Label>{t('dashboard.offer.offer_volume')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="volume"
                    value={values.volume}
                    onChange={handleInput}
                    placeholder={t('dashboard.offer.offer_volume')}
                    required
                  />
                  <Form.Label>{t('dashboard.offer.offer_count_in_box')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="countInBox"
                    value={values.countInBox}
                    onChange={handleInput}
                    placeholder={t('dashboard.offer.offer_count_in_box')}
                    required
                  />
                  <Form.Label htmlFor="ProductImg">{t('dashboard.offer.offer_image')}</Form.Label>
                  <div className="d-flex flex-column ">
                    <Form.Control
                      type="file"
                      name="image"
                      id="productImg"
                      ref={fileInputRef}
                      onChange={handleImageChange}
                      style={{
                        height: "fit-content"
                      }}
                    />
                    {Imgfile.Imgfile && (
                      <div className="position-relative" style={{
                        width: "fit-content"
                      }}>
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={URL.createObjectURL(Imgfile.Imgfile)}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            setImgfile({
                              Imgfile: null,
                            });
                            if (fileInputRef.current) {
                              fileInputRef.current.value = ""; // Clear input using useRef
                            }
                          }}
                          style={{
                            position: "absolute",
                            top: "-18px",
                            right: "5px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Button
                  style={{ margin: "10px" }}
                  variant="warning"
                  onClick={PostOffer}
                >
                  {t('dashboard.offer.post_offer')}
                </Button>
                <Button
                  style={{ margin: "10px" }}
                  variant="outline-dark"
                  onClick={handleShow}
                >
                  {t('dashboard.offer.view_offers')}
                </Button>
                <Modal fullscreen={true} show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      {t('dashboard.offer.our_offers')}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      {deletedProducts.ids.length > 0 ? (
                        <>
                          <Button
                            id="multipleDelete"
                            variant="outline-danger"
                            onClick={handleMultipleShow}
                          >
                            {t('dashboard.offer.multiple_delete')}
                          </Button>
    
                          <Modal
                            size="lg"
                            show={MultipleDeleteModalShow}
                            onHide={() => setMultipleDeleteModal(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title id="example-modal-sizes-title-lg">
                                {t('dashboard.offer.are_you_sure_you_want_to_delete_all_those_offers')}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleMultipleClose}
                              >
                                {t('dashboard.offer.close')}
                              </Button>
                              <Button
                                variant="danger"
                                onClick={deleteMultipleOffers}
                              >
                                {t('dashboard.offer.delete_offers')}
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </>
                      ) : null}
                    </div>
    
                    <DataTable
                      fixedHeader
                      pagination
                      responsive
                      subHeaderWrap
                      columns={OffersColumns}
                      data={offers}
                      customStyles={customStyles}
                    />
                  </Modal.Body>
                  <Button variant="dark" onClick={handleClose}>
                    {t('dashboard.offer.close')}
                  </Button>
                </Modal>
              </Form>
              <hr />
              <div className="d-flex align-items-center gap-5">
                <Form.Group>
                  <Form.Label>{t('dashboard.offer.filter_category')}</Form.Label>
                  <select
                    id="category-list"
                    name="catFilterId"
                    value={CatFilterID}
                    onChange={(e) => setCatFilterID(e.target.value)}
                  >
                    <option>{t('dashboard.offer.please_choose_a_category')}</option>
                    {categories.map((category, index) => {
                      return (
                        <option key={index} value={category._id}>
                          {category[docLang]}
                        </option>
                      );
                    })}
                  </select>
                </Form.Group>
              </div>
            </div>
          </Row>
          <Row>
            <DataTable
              fixedHeader
              pagination
              responsive
              subHeaderWrap
              columns={columns}
              data={categoryProducts}
              customStyles={customStyles}
            />
          </Row>
        </section>
      );
    
  
};

export default CROffers;
