import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { customStyles } from "../../constants/DataTableCustomeStyles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AppVeriosn = () => {
  const [appversions, setAppversions] = useState([]);
  const navigate = useNavigate();
  const fetchApp = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(
      "https://www.api.setalkel.co/app-version/all",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setAppversions([]);
    } else {
      setAppversions(body);
      console.log(body);
    }
  };
  useEffect(() => {
    fetchApp();
  }, []);
  const naviaget = useNavigate();
  const { t } = useTranslation();
  const columns = [
    {
      name: t("dashboard.appVersion.platform"),
      selector: (row) => <span>{row.platform}</span>,
    },
    {
      name: t("dashboard.appVersion.currentVersion"),
      selector: (row) => <span>{row.current_version}</span>,
    },
    {
      name: t("dashboard.appVersion.minimumRequiredVersion"),
      selector: (row) => <span>{row.minimum_required_version}</span>,
    },
    {
      name: t("dashboard.appVersion.updateRequired"),
      selector: (row) => (
        <span>
          {row.update_required ? t("dashboard.appVersion.yes") : t("dashboard.appVersion.no")}
        </span>
      ),
    },
    {
      name: t("dashboard.appVersion.updateUrl"),
      selector: (row) => <span>{row.update_url}</span>,
    },
    {
      name: t("dashboard.appVersion.update"),
      selector: (row) => (
        <div>
          {" "}
          <Button
            variant="warning"
            onClick={() => {
              navigate(`/dashboard/app-version/${row.platform}`);
            }}
          >
            {" "}
            {t("dashboard.appVersion.update")}{" "}
          </Button>{" "}
        </div>
      ),
    },
  ];
  return (
    <>
      {" "}
      <Row>
        {" "}
        <h1>{t("dashboard.appVersion.appVersionTitle")}</h1>{" "}
        <Button
          variant="warning"
          style={{ width: "fit-content" }}
          onClick={() => {
            navigate("/dashboard/add-app-version");
          }}
        >
          {" "}
          {t("dashboard.appVersion.addAppVersion2")}{" "}
        </Button>{" "}
      </Row>{" "}
      <Row>
        <div className="list-category">
          <DataTable
            fixedHeaderScrollHeight="300px"
            responsive
            subHeaderWrap
            columns={columns}
            data={appversions}
            customStyles={customStyles}
          />{" "}
        </div>{" "}
      </Row>{" "}
    </>
  );
};

export default AppVeriosn;
