import axios from "axios";
import React, { useEffect, useState } from "react";
//bootstrap
import { Button, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import { customStyles } from "../../../constants/DataTableCustomeStyles";
import { useTranslation } from "react-i18next";

const ShippingTerms = () => {
  const [terms, setTerms] = useState({
    terms: { en: "", ar: "", fr: "", de: "", ru: "", cn: "", tur: "" },
  });
  const [agents, setAgents] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [deletedProducts, setDeletedProducts] = useState({
    products: [],
  });
  //=================================== Delete Offers====================================

  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const handleClose = () => setDeleteModalShow(false);
  const handleShow = () => setDeleteModalShow(true);

  //===================================Mutliple Delete Offers====================================
  const [MultipleDeleteModalShow, setMultipleDeleteModal] = useState(false);
  const handleMultipleClose = () => setMultipleDeleteModal(false);
  const handleMultipleShow = () => setMultipleDeleteModal(true);

  //=======================Input Handeling===============
  const handleTermsChange = (e) => {
    const { name, value } = e.target;
    setTerms({
      ...terms,
      terms: { ...terms.terms, [name]: value },
    });
  };
  //================================get agents=======================
  const getAgents = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/agent/",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setAgents([]);
      console.log(body);
    } else {
      setAgents(body);
    }
  };

  //==========================Post Agent Terms=============================
  const postAgentTerms = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = terms.terms;
    let NewRaw = raw;
    const NewTerms = terms.terms;

    Object.keys(NewTerms).forEach((key) => {
      if (NewTerms[key] === "" || NewTerms[key] === "undefined") {
        delete NewTerms[key];
      }
    });
    Object.keys(NewRaw).forEach((key) => {
      if (NewRaw[key] === "" || NewRaw[key] === "undefined") {
        delete NewRaw[key];
      }
    });
    const raw2 = JSON.stringify(NewRaw);
    console.log(raw2);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/payment-term/${agentId}`,
      requestOptions
    );
    if (!response.ok) {
      alert("dashboard.shipment.Terms Not Added !!!");
      console.log(response);
    } else {
      alert("dashboard.shipment.Terms Has Been Added Successfuly");
      window.location.reload();
    }
  };
  //=========================get All Payment Terms====================
  const getAllPaymentTerms = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://www.api.setalkel.co/payment-term/",
      requestOptions
    );
    const body = await response.json();
    if (!response.ok) {
      setPaymentTerms([]);
      console.log(body);
    } else {
      setPaymentTerms(body);
    }
  };
  //==========================Delete Product==============================
  const onDelete = () => {
    axios
      .delete(`https://www.api.setalkel.co/payment-term/${agentId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin-token"),
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          console.log(response.data.msg);
        } else {
          alert("dashboard.shipment.item deleted");
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  //============================Delete Mutliple Product===================

  const deleteProducts = (e, productId) => {
    if (e.target.checked) {
      if (!deletedProducts.products.includes(productId)) {
        setDeletedProducts({
          ...deletedProducts,
          products: [...deletedProducts.products, productId],
        });
      }
    } else {
      setDeletedProducts({
        products: deletedProducts.products.filter((item) => item !== productId),
      });
    }
  };
  //=======================delete Multiple Products========================
  const deleteMultipleProducts = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(deletedProducts);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // console.log(raw);
    const response = await fetch(
      "https://www.api.setalkel.co/payment-term/",
      requestOptions
    );

    if (!response.ok) {
      alert("dashboard.shipment.There Is An Error ");
      console.log(response);
    } else {
      alert("dashboard.shipment.Products Have Been Deleted !!");
      window.location.reload();
    }
  };
  //=======================Toggle Show Hide Delete Btn==========================
  useEffect(() => {
    if (deletedProducts.products.length > 0) {
      document.getElementById("multipleDelete").style.visibility = "visible";
    } else {
      document.getElementById("multipleDelete").style.visibility = "hidden";
    }
  }, [deletedProducts.products]);
  useEffect(() => {
    getAgents();
    getAllPaymentTerms();
  }, []);
  const {t} = useTranslation()
  const columns = [
    {
      name: t("dashboard.shipment.name"),
      selector: (row) => <span>{row.name}</span>,
    },
    {
      name: t("dashboard.shipment.mobile"),
      selector: (row) => <span>{row.mobilePhone}</span>,
    },
    {
      name: t("dashboard.shipment.phone"),
      selector: (row) => <span>{row.phone}</span>,
    },
    {
      name: t("dashboard.shipment.email"),
      selector: (row) => <span>{row.email}</span>,
    },
    {
      name: t("dashboard.shipment.companyName"),
      selector: (row) => <span>{row.companyName}</span>,
    },
    {
      name: t("dashboard.shipment.paymentTerms"),
      selector: (row) => (
        <Form.Control
          as="textarea"
          disabled
          value={row.paymentTerm && row.paymentTerm.en}
          rows="3"
        />
      ),
    },
    {
      name: t("dashboard.shipment.update"),
      selector: (row) => (
        <Link to={`/dashboard/shippingterms/${row._id}`}>
          <Button variant="dark">{t("dashboard.shipment.update")}</Button>
        </Link>
      ),
    },
    {
      name: t("dashboard.shipment.delete"),
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            onClick={() => {
              handleShow();
              setAgentId(row._id);
            }}
          >
            {t("dashboard.shipment.deleteTermsOnly")}
          </Button>
  
          <Modal show={DeleteModalShow} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{t("dashboard.shipment.deleteTermConfirmation")}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {t("dashboard.shipment.close")}
              </Button>
              <Button variant="danger" onClick={() => onDelete()}>
                {t("dashboard.shipment.deleteTerm")}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
    {
      name: t("dashboard.shipment.mDelete"),
      selector: (row) => (
        <Form.Check
          type="checkbox"
          name="deletedProducts"
          id="deletedProducts"
          onChange={(e) => deleteProducts(e, row._id)}
        />
      ),
    },
  ];
  
  return (
    <section className="shipping-terms">
      <Row>
        <div className="crud-terms">
          <div className="crud-title">
            <h1>{t("dashboard.shipment.helloTerms")}</h1>
          </div>
        </div>
        <div className="terms">
          <div className="">
            <h2>{t("dashboard.shipment.terms")}</h2>
            <Form>
              <Form.Select
                name="agentId"
                value={agentId}
                onChange={(e) => setAgentId(e.target.value)}
              >
                <option>{t("dashboard.shipment.selectAgent")}</option>
                {agents?.map((agent, index) => (
                  <option key={index} value={agent._id}>
                    {agent.name}
                  </option>
                ))}
              </Form.Select>
              <br />
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t("dashboard.shipment.englishTextarea")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="en"
                  value={terms.terms.en}
                  onChange={handleTermsChange}
                  placeholder={t("dashboard.shipment.termsInEnglish")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t("dashboard.shipment.arabicTextarea")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="ar"
                  value={terms.terms.ar}
                  onChange={handleTermsChange}
                  placeholder={t("dashboard.shipment.termsInArabic")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t("dashboard.shipment.frenchTextarea")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="fr"
                  value={terms.terms.fr}
                  onChange={handleTermsChange}
                  placeholder={t("dashboard.shipment.termsInFrench")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t("dashboard.shipment.russianTextarea")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="ru"
                  value={terms.terms.ru}
                  onChange={handleTermsChange}
                  placeholder={t("dashboard.shipment.termsInRussian")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t("dashboard.shipment.germanTextarea")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="de"
                  value={terms.terms.de}
                  onChange={handleTermsChange}
                  placeholder={t("dashboard.shipment.termsInGerman")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t("dashboard.shipment.chineseTextarea")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="cn"
                  value={terms.terms.cn}
                  onChange={handleTermsChange}
                  placeholder={t("dashboard.shipment.termsInChinese")}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>{t("dashboard.shipment.turkishTextarea")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="tur"
                  value={terms.terms.tur}
                  onChange={handleTermsChange}
                  placeholder={t("dashboard.shipment.termsInTurkish")}
                />
              </Form.Group>
              <Button variant="warning" onClick={postAgentTerms}>
                {t("dashboard.shipment.addTerms")}
              </Button>
              <Button id="multipleDelete" variant="outline-danger" onClick={handleMultipleShow}>
                {t("dashboard.shipment.multipleDelete")}
              </Button>
  
              <Modal
                size="lg"
                show={MultipleDeleteModalShow}
                onHide={() => setMultipleDeleteModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    {t("dashboard.shipment.deleteAllTermsConfirmation")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleMultipleClose}>
                    {t("dashboard.shipment.close")}
                  </Button>
                  <Button variant="danger" onClick={deleteMultipleProducts}>
                    {t("dashboard.shipment.deleteTerms")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          </div>
        </div>
      </Row>
      <hr />
      <Row>
        <h2>{t("dashboard.shipment.agentsPaymentTerms")}</h2>
        <DataTable
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          subHeaderWrap
          columns={columns}
          data={paymentTerms}
          customStyles={customStyles}
        />
      </Row>
    </section>
  );
  
};

export default ShippingTerms;
