import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
//bootstrap
import { Button, Form, Row } from "react-bootstrap";
//css
import "./UpdateCategories.css";
import { FaDownload, FaTrashAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const UpdateCategories = () => {
  const params = useParams();
  let navigate = useNavigate();

  const [Imgfile, setImgfile] = useState({
    Imgfile: null,
  });
  const [values, setValues] = useState({
    _id: "",
    en_name: "",
    ar_name: "",
    fr_name: "",
    ru_name: "",
    de_name: "",
    cn_name: "",
    tur_name: "",
    ir_name: "",
    dk_name: "",
  });

  //=======================Input Handeling===============
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  //=======================Image Handeling===============
  const handleImageChange = (e) => {
    setImgfile({
      Imgfile: e.target.files[0],
    });
  };
  //===============================================================Update Product Data==========================================
  //========================getCategoryById=========================
  const getCategoryById = useCallback(async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const response = await fetch(
      `https://www.api.setalkel.co/product/cat/${params.Id}`,
      requestOptions
    );
    const body = await response.json();
    setImgfile({ Imgfile: body.img });
    setValues({
      _id: body._id,
      en_name: body.en ? body.en : "",
      ar_name: body.ar ? body.ar : "",
      fr_name: body.fr ? body.fr : "",
      ru_name: body.ru ? body.ru : "",
      de_name: body.de ? body.de : "",
      cn_name: body.cn ? body.cn : "",
      ir_name: body.ir ? body.ir : "",
      dk_name: body.dk ? body.dk : "",
      tur_name: body.tur ? body.tur : "",
    });
  }, [params.Id]);
  useEffect(() => {
    getCategoryById();
  }, [getCategoryById]);

  //==============Update Product=====================
  const UpdateCategoryImg = async (id) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("admin-token")
      );
      const fd = new FormData();
      fd.append("image", Imgfile.Imgfile, Imgfile.Imgfile.name);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fd,
        redirect: "follow",
      };

      const ImgResponse = await fetch(
        `https://www.api.setalkel.co/product/cat/image/${id}`,
        requestOptions
      );
      const body = await ImgResponse.json();
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };

  //===================================Update Category================================================
  const UpdateCategory = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `bearer ${localStorage.getItem("admin-token")}`
    );
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    if (values.en_name && values.ar_name) {
      var urlencoded = new URLSearchParams();
      urlencoded.append("en", values.en_name);
      urlencoded.append("ar", values.ar_name);
      urlencoded.append("fr", values.fr_name);
      urlencoded.append("ru", values.ru_name);
      urlencoded.append("cn", values.cn_name);
      urlencoded.append("de", values.de_name);
      urlencoded.append("tur", values.tur_name);
      urlencoded.append("ir", values.ir_name);
      urlencoded.append("dk", values.dk_name);

      let keysForDel = [];
      urlencoded.forEach((value, key) => {
        if (value === "") {
          keysForDel.push(key);
        }
      });

      keysForDel.forEach((key) => {
        urlencoded.delete(key);
      });
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      const response = await fetch(
        `https://www.api.setalkel.co/product/cat/${values._id}`,
        requestOptions
      );
      const body = await response.json();

      if (!response.ok) {
        alert("dashboard.category.Category Not Updated");
        console.log(body);
      } else {
        if (Imgfile.Imgfile) {
          await UpdateCategoryImg(body._id);
        }
        alert("dashboard.category.Category Updated");
        navigate("/dashboard/crcategories");
      }
    } else {
      alert("dashboard.category.Category Cannot Be Without Arabic And English Name");
    }
  };
  //======================================Cancel=====================================
  const Cancel = async () => {
    navigate("/dashboard/crcategories");
  };
  const getFile = async (file) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`https://www.api.setalkel.co${file}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("dashboard.category.a");
        link.href = url;

        // Extract the file extension from the file name
        const fileExtension = file.split("dashboard.category..").pop();

        // Define MIME types
        const mimeTypes = {
          jpg: "image/jpeg",
          jpeg: "image/jpeg",
          png: "image/png",
          gif: "image/gif",
          // add more MIME types as needed
        };

        // Set the download attribute with the correct file name and extension
        link.setAttribute("download", `${file}`);

        // If the MIME type is known, set it
        if (mimeTypes[fileExtension]) {
          link.type = mimeTypes[fileExtension];
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.log("error", error));
  };
  const {t} = useTranslation()
  return (
    <section className="cms-update-categories">
      <Row>
        <div className="crud-categories">
          <div className="crud-title">
            <h1>{t("dashboard.category.helloCRUDCategories")}</h1>
          </div>
          <div className="crud-category">
            <Form className="categories-form">
              <div className="category-langs">
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    {t("dashboard.category.productNameInEnglish")}
                  </Form.Label>
                  <Form.Control
                    name="en_name"
                    label="category"
                    value={values.en_name}
                    onChange={handleInputChange}
                    placeholder={t("dashboard.category.nameInEn")}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    {t("dashboard.category.productNameInArabic")}
                  </Form.Label>
                  <Form.Control
                    name="ar_name"
                    label="category"
                    value={values.ar_name}
                    onChange={handleInputChange}
                    placeholder={t("dashboard.category.nameInAr")}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    {t("dashboard.category.productNameInFrench")}
                  </Form.Label>
                  <Form.Control
                    name="fr_name"
                    label="category"
                    value={values.fr_name}
                    onChange={handleInputChange}
                    placeholder={t("dashboard.category.nameInFr")}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    {t("dashboard.category.productNameInRussian")}
                  </Form.Label>
                  <Form.Control
                    name="ru_name"
                    label="category"
                    value={values.ru_name}
                    onChange={handleInputChange}
                    placeholder={t("dashboard.category.nameInRu")}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    {t("dashboard.category.productNameInGerman")}
                  </Form.Label>
                  <Form.Control
                    name="de_name"
                    label="category"
                    value={values.de_name}
                    onChange={handleInputChange}
                    placeholder={t("dashboard.category.nameInDe")}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    {t("dashboard.category.productNameInChinese")}
                  </Form.Label>
                  <Form.Control
                    name="cn_name"
                    label="category"
                    value={values.cn_name}
                    onChange={handleInputChange}
                    placeholder={t("dashboard.category.nameInCn")}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    {t("dashboard.category.productNameInTurkish")}
                  </Form.Label>
                  <Form.Control
                    name="tur_name"
                    label="category"
                    value={values.tur_name}
                    onChange={handleInputChange}
                    placeholder={t("dashboard.category.nameInTur")}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    {t("dashboard.category.productNameInPersian")}
                  </Form.Label>
                  <Form.Control
                    name="ir_name"
                    label="category"
                    value={values.ir_name}
                    onChange={handleInputChange}
                    placeholder={t("dashboard.category.nameInIr")}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductNameInEn">
                    {t("dashboard.category.productNameInDanish")}
                  </Form.Label>
                  <Form.Control
                    name="dk_name"
                    label="category"
                    value={values.dk_name}
                    onChange={handleInputChange}
                    placeholder={t("dashboard.category.nameInDk")}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="ProductImg">
                    {t("dashboard.category.categoryImage")}
                  </Form.Label>
                  <div className="d-flex flex-column">
                    <Form.Control
                      type="file"
                      name="image"
                      id="productImg"
                      onChange={handleImageChange}
                    />
                    {Imgfile.Imgfile && (
                      <div
                        className="position-relative"
                        style={{
                          width: "fit-content",
                        }}
                      >
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                          src={"https://www.api.setalkel.co" + Imgfile.Imgfile}
                        />
                        <FaDownload
                          onClick={() => getFile(Imgfile.Imgfile)}
                          style={{
                            color: "red",
                            fontSize: "15px",
                            position: "absolute",
                            top: "0",
                            right: "0",
                          }}
                        />
                        <FaTrashAlt
                          style={{
                            color: "red",
                            fontSize: "15px",
                            position: "absolute",
                            top: "0",
                            right: "20px",
                            zIndex: "100",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>
            </Form>
            <Button type="submit" onClick={UpdateCategory} variant="warning">
              {t("dashboard.category.updateCategory")}
            </Button>
            <Button variant="outline-dark" type="button" onClick={Cancel}>
              {t("dashboard.category.cancel")}
            </Button>
          </div>
        </div>
      </Row>
    </section>
  );
};

export default UpdateCategories;
